/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h2{margin-top:0;margin-bottom:.5rem;}
p{margin-top:0;margin-bottom:1rem;}
a{color:#007bff;text-decoration:none;background-color:transparent;}
a:hover{color:#0056b3;text-decoration:underline;}
label{display:inline-block;margin-bottom:.5rem;}
button{border-radius:0;}
button:focus{outline:1px dotted;outline:5px auto -webkit-focus-ring-color;}
button,input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button,input{overflow:visible;}
button{text-transform:none;}
[type=submit],button{-webkit-appearance:button;}
[type=submit]::-moz-focus-inner,button::-moz-focus-inner{padding:0;border-style:none;}
input[type=checkbox]{box-sizing:border-box;padding:0;}
h2{margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h2{font-size:2rem;}
.row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
.col-4,.col-8{position:relative;width:100%;padding-right:15px;padding-left:15px;}
.col-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.col-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.offset-4{margin-left:33.333333%;}
.form-control{display:block;width:100%;height:calc(1.5em + .75rem + 2px);padding:.375rem .75rem;font-size:1rem;font-weight:400;line-height:1.5;color:#495057;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;border-radius:.25rem;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.form-control{transition:none;}
}
.form-control::-ms-expand{background-color:transparent;border:0;}
.form-control:-moz-focusring{color:transparent;text-shadow:0 0 0 #495057;}
.form-control:focus{color:#495057;background-color:#fff;border-color:#80bdff;outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);}
.form-control::-webkit-input-placeholder{color:#6c757d;opacity:1;}
.form-control::-moz-placeholder{color:#6c757d;opacity:1;}
.form-control:-ms-input-placeholder{color:#6c757d;opacity:1;}
.form-control::-ms-input-placeholder{color:#6c757d;opacity:1;}
.form-control::placeholder{color:#6c757d;opacity:1;}
.form-control:disabled{background-color:#e9ecef;opacity:1;}
.col-form-label{padding-top:calc(.375rem + 1px);padding-bottom:calc(.375rem + 1px);margin-bottom:0;font-size:inherit;line-height:1.5;}
.form-group{margin-bottom:1rem;}
.form-check-label{margin-bottom:0;}
.btn{display:inline-block;font-weight:400;color:#212529;text-align:center;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:.375rem .75rem;font-size:1rem;line-height:1.5;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.btn{transition:none;}
}
.btn:hover{color:#212529;text-decoration:none;}
.btn:focus{outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);}
.btn:disabled{opacity:.65;}
.btn-primary{color:#fff;background-color:#007bff;border-color:#007bff;}
.btn-primary:hover{color:#fff;background-color:#0069d9;border-color:#0062cc;}
.btn-primary:focus{color:#fff;background-color:#0069d9;border-color:#0062cc;box-shadow:0 0 0 .2rem rgba(38,143,255,.5);}
.btn-primary:disabled{color:#fff;background-color:#007bff;border-color:#007bff;}
.btn-lg{padding:.5rem 1rem;font-size:1.25rem;line-height:1.5;border-radius:.3rem;}
.text-center{text-align:center!important;}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
h2,p{orphans:3;widows:3;}
h2{page-break-after:avoid;}
}
/*! CSS Used from: Embedded */
.form-control{border-color:#eee;min-height:41px;box-shadow:none!important;}
.form-control:focus{border-color:#5cd3b4;}  
.form-control,.btn{border-radius:3px;}
.signup-form{width:500px;margin:0 auto;padding:30px 0;}
.signup-form h2{color:#333;margin:0 0 30px 0;display:inline-block;padding:0 30px 10px 0;border-bottom:3px solid #6751b6;}
.signup-form form{color:#999;border-radius:3px;margin-bottom:15px;background-color: rgb(160, 205, 241);box-shadow:0px 2px 2px rgba(0, 0, 0, 0.3);padding:30px;}
.signup-form label{font-weight:normal;font-size:14px;line-height:2;}
.signup-form input[type="checkbox"]{position:relative;top:1px;}
.signup-form .btn{font-size:16px;font-weight:bold;background:#5cd3b4;border:none;margin-top:0px;min-width:140px;}
.signup-form .btn:hover,.signup-form .btn:focus{background:#41cba9;outline:none!important;}
.signup-form a{color:#2040fa;text-decoration:underline;}
.signup-form a:hover{text-decoration:none;}
.signup-form form a{color:#072bf7;text-decoration:none;}
.signup-form form a:hover{text-decoration:underline;}


.colorofBody {
    background-color: blueviolet;
    border-radius: 15px;
    margin: 10px;
}

.button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    
  }
  
  .button:hover {background-color: white; color: blueviolet;}
  
  .button:active {
    background-color: white;
    box-shadow: 0 5px #666;
    transform: translateY(4px);}