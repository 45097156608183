/* .demo{


    border: 3px solid blue;
    margin: 5px;
    background-color: rgb(192, 176, 157);
    width: 200px;
    
} */

/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/bootstrap.css */
a {
    background-color: transparent;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  img {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  @media print {
    *,
    *:before,
    *:after {
      color: #000 !important;
      text-shadow: none !important;
      background: transparent !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    a,
    a:visited {
      text-decoration: underline;
    }
    a[href]:after {
      content: " (" attr(href) ")";
    }
    a[href^="#"]:after {
      content: "";
    }
    img {
      page-break-inside: avoid;
    }
    img {
      max-width: 100% !important;
    }
    p,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h3 {
      page-break-after: avoid;
    }
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  a {
    color: #337ab7;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    color: #23527c;
    text-decoration: underline;
  }
  a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  img {
    vertical-align: middle;
  }
  h3 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 24px;
  }
  p {
    margin: 0 0 10px;
  }
  ul {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .col-md-6,
  .col-lg-6,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (min-width: 992px) {
    .col-md-6,
    .col-md-12 {
      float: left;
    }
    .col-md-12 {
      width: 100%;
    }
    .col-md-6 {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-6,
    .col-lg-12 {
      float: left;
    }
    .col-lg-12 {
      width: 100%;
    }
    .col-lg-6 {
      width: 50%;
    }
  }
  .row:before,
  .row:after {
    display: table;
    content: " ";
  }
  .row:after {
    clear: both;
  }
  /*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/font-awesome.css */
  .fa-user:before {
    content: "\f007";
  }
  .fa-check:before {
    content: "\f00c";
  }
  .fa-angle-left:before {
    content: "\f104";
  }
  .fa-angle-right:before {
    content: "\f105";
  }
  /*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/style_II.css */
  a {
    color: #797979;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    color: #707070;
    text-decoration: none;
    outline: none;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.1;
    color: #222222;
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  /*! CSS Used from: Embedded */
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 1.75rem;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ul {
    padding-left: 2rem;
  }
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  a {
    color: #0d6efd;
    text-decoration: underline;
  }
  a:hover {
    color: #0a58ca;
  }
  img,
  svg {
    vertical-align: middle;
  }
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  .row {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
  }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
  }
  @media (min-width: 768px) {
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .ms-2 {
    margin-left: 0.5rem !important;
  }
  .fal,
  .far,
  .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
  .fa-angle-left:before {
    content: "\f104";
  }
  .fa-angle-right:before {
    content: "\f105";
  }
  .fa-check:before {
    content: "\f00c";
  }
  .fa-user:before {
    content: "\f007";
  }
  .fal {
    font-weight: 300;
  }
  .fal,
  .far {
    font-family: "Font Awesome 5 Pro";
  }
  .far {
    font-weight: 400;
  }
  .fas {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  a,
  span,
  p,
  i,
  li,
  img,
  svg path,
  *::after,
  *::before,
  h3 {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  a:focus {
    text-decoration: none;
    outline: none;
  }
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
  }
  a {
    color: inherit;
    outline: none;
    border: none;
    background: transparent;
  }
  h3 {
    font-family: "Raleway", sans-serif;
    color: #141517;
    margin-top: 0px;
    font-weight: 700;
    line-height: 1.3;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  h3 {
    font-size: 24px;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
  }
  p {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: #575757;
    margin-bottom: 15px;
    line-height: 26px;
  }
  *::-moz-selection {
    background: #2467ec;
    color: #ffffff;
    text-shadow: none;
  }
  ::-moz-selection {
    background: #2467ec;
    color: #ffffff;
    text-shadow: none;
  }
  ::selection {
    background: #2467ec;
    color: #ffffff;
    text-shadow: none;
  }
  *::-moz-placeholder {
    color: #575757;
    font-size: 14px;
    opacity: 1;
  }
  *::placeholder {
    color: #575757;
    font-size: 14px;
    opacity: 1;
  }
  .view-details-btn {
    height: 45px;
    padding: 0 45px;
    display: inline-block;
    background-color: #2467ec;
    line-height: 45px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    color: #ec0909;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px),
    only screen and (min-width: 992px) and (max-width: 1199px),
    only screen and (min-width: 768px) and (max-width: 991px) {
    .view-details-btn {
      padding: 0 35px;
    }
  }
  .view-details-btn:hover {
    background: #ffb013;
    color: #f4f5f8;
  }
  .course-cart {
    background: #ffffff;
    padding: 30px 40px;
    box-shadow: 0px 0px 70px rgba(15, 38, 71, 0.2);
    min-width: 380px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 0;
    right: 0;
    transition: 0.3s;
    z-index: 11;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px),
    only screen and (min-width: 992px) and (max-width: 1199px),
    only screen and (min-width: 768px) and (max-width: 991px) {
    .course-cart {
      padding: 30px 30px;
      min-width: 340px;
    }
  }
  @media (max-width: 575px) {
    .course-cart {
      display: none;
    }
  }
  .course-info-wrapper h3 {
    font-size: 20px;
    margin-bottom: 5px;
    line-height: 1.3;
  }
  .category-color {
    font-size: 15px;
    font-weight: 600;
    background: #6f19c5;
    padding: 0px 10px;
    color: #ffffff;
    margin-bottom: 15px;
    display: inline-block;
    height: 26px;
    border-radius: 3px;
  }
  .category-color.category-color-1 {
    background: #31bf82;
  }
  .cart-info-body h3:hover {
    color: #2467ec;
  }
  .cart-lavel p {
    margin-bottom: 25px;
    font-size: 15px;
  }
  .info-cart-text ul {
    margin-bottom: 30px;
  }
  .info-cart-text ul li {
    margin-bottom: 5px;
  }
  .info-cart-text ul i {
    margin-right: 15px;
    color: #2467ec;
  }
  .course-action {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .course-action .view-details-btn {
    flex-grow: 1;
  }
  .course-wrapper-2 {
    padding: 0px 0px 0px 0px;
    position: relative;
  }
  .course-wrapper-2:hover .course-cart {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 575px) {
    .course-wrapper-2:hover .course-cart {
      opacity: 0;
      visibility: hidden;
    }
  }
  .course-wrapper-2:hover .student-course-img img {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  .student-course-img {
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 8px;
  }
  .student-course-linkter {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .portfolio-price {
    display: flex;
    align-items: center;
  }
  .portfolio-price span {
    color: #2467ec;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
  }
  .student-course-text h3 {
    font-size: 18px;
    line-height: 28px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .student-course-text h3 {
      font-size: 16px;
    }
  }
  .student-course-text h3 a:hover {
    color: #2467ec;
  }
  .edu-pagination ul {
    display: flex;
    align-items: center;
  }
  .edu-pagination li {
    margin-right: 15px;
  }
  .edu-pagination li:hover a {
    color: #2467ec;
    font-weight: 600;
  }
  .edu-pagination i {
    height: 45px;
    width: 45px;
    background: #ffffff;
    line-height: 43px;
    text-align: center;
    border-radius: 50%;
    color: #575757;
    font-size: 20px;
    border: 1px solid #edeef2;
    font-weight: 400;
    transition: 0.3s;
  }
  .edu-pagination i:hover {
    border-color: #2467ec;
    background: #2467ec;
    color: #ffffff;
  }
  .edu-pagination ul li.active a {
    color: #2467ec;
    font-weight: 600;
  }
  .student-course-img img {
    width: 100%;
  }
  /*! CSS Used fontfaces */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
      url(https://fonts.gstatic.com/s/montserrat/v12/rBHvpRWBkgyW99dXT88n7yEAvth_LlrfE80CYdSH47w.woff2)
        format("woff2");
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
      url(https://fonts.gstatic.com/s/montserrat/v12/NX1NravqaXESu9fFv7KuqiEAvth_LlrfE80CYdSH47w.woff2)
        format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
      url(https://fonts.gstatic.com/s/montserrat/v12/SKK6Nusyv8QPNMtI4j9J2yEAvth_LlrfE80CYdSH47w.woff2)
        format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
      url(https://fonts.gstatic.com/s/montserrat/v12/gFXtEMCp1m_YzxsBpKl68iEAvth_LlrfE80CYdSH47w.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
      U+A720-A7FF;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
      url(https://fonts.gstatic.com/s/montserrat/v12/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }
  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 300;
    font-display: block;
    src: url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-light-300.eot);
    src: url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-light-300.eot#iefix)
        format("embedded-opentype"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-light-300.woff2)
        format("woff2"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-light-300.woff)
        format("woff"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-light-300.ttf)
        format("truetype"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-light-300.svg#fontawesome)
        format("svg");
  }
  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-regular-400.eot);
    src: url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-regular-400.eot#iefix)
        format("embedded-opentype"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-regular-400.woff2)
        format("woff2"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-regular-400.woff)
        format("woff"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-regular-400.ttf)
        format("truetype"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-regular-400.svg#fontawesome)
        format("svg");
  }
  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-solid-900.eot);
    src: url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-solid-900.eot#iefix)
        format("embedded-opentype"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-solid-900.woff2)
        format("woff2"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-solid-900.woff)
        format("woff"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-solid-900.ttf)
        format("truetype"),
      url(https://themepure.net/html/eduman-prv/eduman/assets/fonts/fa-solid-900.svg#fontawesome)
        format("svg");
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCAIT5lu.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCkIT5lu.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCIIT5lu.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCAIT5lu.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCkIT5lu.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCIIT5lu.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCAIT5lu.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCkIT5lu.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCIIT5lu.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCAIT5lu.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCkIT5lu.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCIIT5lu.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCAIT5lu.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCkIT5lu.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCIIT5lu.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8ccewI9tScg.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8cce5I9tScg.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8cceyI9tScg.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8ccezI9tScg.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8cce9I9s.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc9iB85gU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc9iB85pU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc9iB85iU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc9iB85jU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc9iB85tU1E.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5gU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5pU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5iU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5jU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5tU1E.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8-BM5gU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8-BM5pU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8-BM5iU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8-BM5jU1EQVg.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8-BM5tU1E.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF, U+FFFD;
  }
  