
/*! CSS Used from: https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
p{margin-top:0;margin-bottom:1rem;}
a{color:#0d6efd;text-decoration:underline;}
a:hover{color:#0a58ca;}
img{vertical-align:middle;}
::-moz-focus-inner{padding:0;border-style:none;}
/*! CSS Used from: https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
p{margin-top:0;margin-bottom:1rem;}
a{color:#007bff;text-decoration:none;background-color:transparent;}
a:hover{color:#0056b3;text-decoration:underline;}
img{vertical-align:middle;border-style:none;}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
/*! CSS Used from: https://hola9.com/static/css/main.88b788a4.css */
a{background:linear-gradient(270deg,#0f3854,#2b224c);}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{max-width:100%!important;page-break-inside:avoid;}
}
a:focus,a:hover{o-:all .3s ease-out;}
a,a:focus,a:hover{color:hsla(0,0%,96%,.822);}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{max-width:100%!important;page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
:focus{box-shadow:none;outline:0;}
a{color:#000;text-transform:inherit;}
a :focus,a:hover{box-shadow:none;color:#000;text-decoration:none;}
a:focus{outline:0 solid;}
@media only screen and (max-width:767px){
p{font-size:14px;}
}
:focus{box-shadow:none;outline:0;}
p{color:#666;line-height:1.8;margin-bottom:15px;}
@media only screen and (max-width:767px){
p{font-size:14px;}
}

.footer .footer__top__info .footer__top__info__logo img{margin-bottom:40px;}
.footer .footer__top__info .footer__top__info__desc{font-family:Poppins,sans-serif!important;margin-bottom:10px;}
.footer__top__info .footer__top__info__logo img{margin-bottom:20px;max-width:96px;}
.footer__top__info .footer__top__info__desc{color:#5d5d5d;font-size:16px;line-height:1.44;margin-bottom:25px;}
.footer__top__info .footer__top__info__app a{display:inline-block;margin-right:8px;}
.footer__top__info .footer__top__info__app img{max-width:134px;}
@media only screen and (max-width:992px){
.footer__top__info{margin-bottom:40px;}
}
@media only screen and (max-width:375px){
.footer__top__info,.footer__top__info .footer__top__info__app a{margin-bottom:20px;}
}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{max-width:100%!important;page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
p{orphans:3;widows:3;}
}
a{color:#e52d27;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
p{orphans:3;widows:3;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media all{
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
*{margin:0;padding:0;}
}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
*{margin:0;padding:0;}
}
div{background:transparent;}
:-moz-placeholder,::-moz-placeholder{color:#c8c8c8;font-weight:400;opacity:1;}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
div{border:0;font-size:100%;margin:0;padding:0;vertical-align:baseline;}
}
@media all{
@media print{
*{background:none!important;box-shadow:none!important;color:#000!important;-webkit-filter:grayscale(100%);-moz-filter:grayscale(100%);-ms-filter:grayscale(100%);-o-filter:grayscale(100%);text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
*{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;}
}
@media all{
::-webkit-selection{background-color:#5580ff;color:#fff;}
::selection{background-color:#5580ff;color:#fff;}
}
@media all{
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
*{margin:0;padding:0;}
}
div{color:#7c7c7c;font-family:Quicksand;}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
*{margin:0;padding:0;}
}
div{color:#6c6c6c;font-display:swap;font-family:Open Sans;font-size:14px;font-style:normal;font-weight:400;line-height:24px;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
}
div{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
:disabled{pointer-events:none!important;}
p{margin-bottom:0;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
}
a:active,a:hover{text-decoration:none;}
a:focus{outline:0;}
@media all{
p{overflow-wrap:break-word;}
a{background-color:transparent;}
a:active,a:hover{outline:0;}
img{border:0;}
}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{max-width:100%!important;page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
*,:after,:before{box-sizing:border-box;}
a{color:#337ab7;text-decoration:none;}
a:focus,a:hover{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
img{vertical-align:middle;}
p{margin:0 0 10px;}
}
@media all{
*{margin:0;padding:0;}
a{text-decoration:none!important;}
}
a,div,img{font-display:swap;font-size:14px;font-style:normal;font-weight:400;line-height:24px;}
a,div,img{color:#6c6c6c;font-family:Open Sans;}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
}
div{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
a,div{background:transparent;}
a{color:#0078d6;}
a:focus,a:hover{box-shadow:none!important;color:#00539f;}
::-webkit-input-placeholder{color:#c8c8c8;font-weight:400;opacity:1;}
:-moz-placeholder,::-moz-placeholder{color:#c8c8c8;font-weight:400;opacity:1;}
:-ms-input-placeholder{color:#c8c8c8;font-weight:400;opacity:1;}
@media screen and (-webkit-min-device-pixel-ratio:0){
*{-webkit-font-smoothing:antialiased;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
p{orphans:3;widows:3;}
}
a,div{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
@media all{
a{background-color:transparent;}
a:active,a:hover{outline:0;}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
p{orphans:3;widows:3;}
}
*,:after,:before{box-sizing:border-box;}
a{color:#337ab7;text-decoration:none;}
a:focus,a:hover{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
p{margin:0 0 10px;}
a,div,p{border:0;font-size:100%;margin:0;padding:0;vertical-align:baseline;}
a:active,a:hover{-webkit-tap-highlight-color:rgba(0,0,0,0);outline:0;}
}
@media all{
a{color:#777;}
p{font-size:13px;line-height:1.66em;margin-bottom:30px;margin-bottom:20px;}
a{text-decoration:none;}
a,p{-ms-word-wrap:break-word;word-wrap:break-word;}
a:active,a:focus,a:hover,a:link,a:visited{outline:0 none;text-decoration:none;}
@media print{
*{background:none!important;box-shadow:none!important;color:#000!important;-webkit-filter:grayscale(100%);-moz-filter:grayscale(100%);-ms-filter:grayscale(100%);-o-filter:grayscale(100%);text-shadow:none!important;}
a{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
}
*,:after,:before{box-sizing:border-box;}
*{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;}
a,p{text-decoration:none!important;}
}
p{line-height:26px;}
a,p{color:#555f80;}
a:hover{color:#ea4b35;}
::-webkit-selection{background-color:#5580ff;color:#fff;}
::selection{background-color:#5580ff;}
@media all{
p{font-size:14px;line-height:26px;}
a,p{color:#555f80;}
a:hover{color:#ea4b35;}
::-webkit-selection{background-color:#5580ff;color:#fff;}
::selection{background-color:#5580ff;color:#fff;}
}
@media all{
a{background-color:transparent;}
a:active,a:hover{outline:0;}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
}
*,:after,:before{box-sizing:border-box;}
a{color:#337ab7;text-decoration:none;}
a:focus,a:hover{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
}
@media all{
*{margin:0;padding:0;}
a{text-decoration:none!important;}
}
a,div{color:#7c7c7c;font-display:swap;font-family:Quicksand;font-size:14px;font-style:normal;font-weight:400;line-height:24px;}
@media all{
*,:after,:before{box-sizing:border-box;}
a{background-color:transparent;color:#007bff;text-decoration:none;}
a:hover{color:#0056b3;text-decoration:underline;}
}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
}
@media all{
a{background-color:transparent;}
a,a:active,a:focus,a:hover,a:link{outline:none;}
a:link,a:visited{color:#f85c70;text-decoration:none;transition:all .3s ease 0s;}
a:active,a:focus,a:hover{color:#e43d40;text-decoration:none;}
}
a:link,a:visited{color:#2b5876;}
a:active,a:focus,a:hover{color:#e43d40;}
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
p{orphans:3;widows:3;}
}
a{color:#2b2d42;font-weight:500;transition:color .2s;}
a:focus,a:hover{color:#d10024;}
p{margin:10px;}
@media all{
p{overflow-wrap:break-word;}
a{background-color:transparent;}
a:active,a:hover{outline:0;}
}
@media all{
@media print{
*,:after,:before{background:0 0!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
p{orphans:3;widows:3;}
}
*,:after,:before{box-sizing:border-box;}
a{color:#337ab7;text-decoration:none;}
a:focus,a:hover{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
p{margin:0 0 10px;}
*{margin:0;padding:0;}
a{text-decoration:none!important;}
}
a,div{color:#6c6c6c;font-display:swap;font-family:Open Sans;font-size:14px;font-style:normal;font-weight:400;line-height:24px;}
@media all{
a:hover{color:#e72a1a;text-decoration:none;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
p{orphans:3;widows:3;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
p{margin-bottom:0;}
p{margin-bottom:0;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
@media all{
a:hover{color:#e72a1a;text-decoration:none;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
p{orphans:3;widows:3;}
}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
p{orphans:3;widows:3;}
}
a,div{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
a{color:#e52d27;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
@media all{
a:hover{color:#e72a1a;text-decoration:none;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
}
@media all{
a:hover{color:#e72a1a;text-decoration:none;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
p{orphans:3;widows:3;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
p{margin-bottom:0;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
p{margin-bottom:0;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
p{orphans:3;widows:3;}
}
*{margin:0;outline:0;padding:0;}
a{display:inline-block;}
p{margin-bottom:0;}
a{color:#8492a6;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
}
:focus{outline:0!important;}
@media all{
a:hover{color:#e72a1a;text-decoration:none;}
}
*{-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none;}
div{border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline;}
p{line-height:1.44;}
a,p{border:0;font-size:100%;outline:0;padding:0;vertical-align:baseline;}
a{color:#2d2d2d;margin:0;transition:all .3s ease-in-out 0s;-moz-transition:all .3s ease-in-out 0s;-o-transition:all .3s ease-in-out 0s;-webkit-transition:all .3s ease-in-out 0s;-ms-transition:all .3s ease-in-out 0s;}
a:hover{color:#23d3d3;}
a:focus{color:inherit;}
img{border:none;height:auto;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
a{transition:all .2s linear;}
p{font-family:Open Sans,sans-serif;font-size:14px;font-weight:400;}
a:active,a:hover{outline:0;}
@media print{
*,:after,:before{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{max-width:100%!important;page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
:after,:before{box-sizing:border-box;}
a{color:#337ab7;}
a:focus,a:hover{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
p{margin:0 0 10px;}
a{transition:all .25s ease-in-out;}
a:focus,a:hover{o-transition:all .3s ease-out;transition:all .3s ease-out;}
a,div{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-moz-outline-:none;}
p{font-size:16px;}
p{line-height:1.6em;}
a,a:focus,a:hover{color:#e52d27;}
@media all{
*,:after,:before{box-sizing:border-box;}
a{background-color:transparent;color:#007bff;text-decoration:none;}
a:hover{color:#0056b3;text-decoration:underline;}
}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
}
@media all{
a{background-color:transparent;}
a,a:active,a:focus,a:hover,a:link{outline:none;}
}
@media all{
*,:after,:before{box-sizing:border-box;}
}
a{color:#5ea51d;}
a:focus,a:hover{color:#5ea51d;outline:none!important;}
@media all{
*,:after,:before{box-sizing:border-box;}
a{background-color:transparent;color:#007bff;text-decoration:none;}
a:hover{color:#0056b3;text-decoration:underline;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
a{transition:.2s;}
a:active,a:focus,a:hover{box-shadow:none;outline:0;text-decoration:none;}
a:active,a:focus,a:hover{box-shadow:none;outline:0;text-decoration:none;}
a,a:active,a:focus,a:hover{color:#00aeff;}
}
@media all{
p{overflow-wrap:break-word;}
*,:after,:before{box-sizing:border-box;}
p{margin-top:0;}
p{margin-bottom:1rem;}
}
@media all{
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
p{orphans:3;widows:3;}
}
}
p{margin-bottom:1em;}
a{color:#1890ff;outline:none;transition:color .3s;}
a:hover{color:#40a9ff;}
a:active{color:#096dd9;}
a:active,a:focus,a:hover{outline:0;text-decoration:none;}
a{touch-action:manipulation;}
::-moz-selection{background:#1890ff;color:#fff;}
::selection{background:#1890ff;}
@media all{
*,:after,:before{box-sizing:border-box;}
}
@media all{
a{background-color:transparent;color:#007bff;text-decoration:none;}
a:hover{color:#0056b3;text-decoration:underline;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
a{transition:.2s;}
a:active,a:focus,a:hover{box-shadow:none;outline:0;text-decoration:none;}
a:active,a:focus,a:hover{box-shadow:none;outline:0;text-decoration:none;}
a,a:active,a:focus,a:hover{color:#00aeff;}
}
a,a:active,a:focus,a:hover{color:#00aeff;}
@media screen{
::-webkit-input-placeholder{color:inherit;opacity:.54;}
*,:after,:before{box-sizing:border-box;}
p{margin-bottom:.5rem;margin-top:0;}
p{padding:0;}
p:not(.u-text-variant){margin-bottom:20px;}
}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
a{transition:all .3s ease-out 0s;}
a:focus{outline:none;text-decoration:none;}
a{color:#635c5c;}
a,a:hover{color:#fff;}
a{outline:medium none;}
a{color:#0779e4;}
a:hover{color:#05529a;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
::selection{background:#000;color:#fff;}
a{transition:all .3s ease;}
a{-webkit-text-decoration-skip:objects;}
a:hover{color:#f39b00;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
p{orphans:3;widows:3;}
}
a:focus{outline:none!important;}
:focus{outline:none;}
p{margin:0;}
a{color:#151515;transition:.25s;}
a:focus,a:hover{color:#f39b00;outline:none;text-decoration:none;}
*+p{margin-top:14px;}
img{border:0;}
*{box-sizing:border-box;}
img{max-width:100%;}
img{display:inline-block;}
p{margin-bottom:10px;}
p{margin-bottom:28px;}
@media screen and (max-width:767px){
p{margin-bottom:24px;}
}
a{color:#0d6efd;color:var(--bs-link-color);text-decoration:underline;}
a:hover{color:#0a58ca;color:var(--bs-link-hover-color);}
img{vertical-align:middle;}
::-moz-focus-inner{border-style:none;padding:0;}
*,:after,:before{box-sizing:border-box;}
p{margin-bottom:1rem;margin-top:0;}
a{background-color:transparent;}
a:hover{text-decoration:underline;}
img{border-style:none;}
img{vertical-align:middle;}
@media print{
*,:after,:before{box-shadow:none!important;text-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
p{orphans:3;widows:3;}
}
:disabled{pointer-events:none!important;}
a{color:#007bff;cursor:pointer;}
a,a:hover{text-decoration:none;transition:all .2s ease-in-out;}
a:hover{color:#0056b3;}
a:disabled:hover{color:#007bff;}
/*! CSS Used fontfaces */
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Poppins;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Poppins;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Poppins;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format("woff2");unicode-range:U+0900-097f,U+1cd0-1cf6,U+1cf8-1cf9,U+200c-200d,U+20a8,U+20b9,U+25cc,U+a830-a839,U+a8e0-a8fb;}
@font-face{font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Poppins;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58m-wi40.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58i-wi40.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58a-wg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58m-wi40.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58i-wi40.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv58a-wg.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:500;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:600;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Quicksand;font-style:normal;font-weight:700;src:url(https://fonts.gstatic.com/s/quicksand/v29/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0301,U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0301,U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-display:swap;font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format("woff2");unicode-range:U+0460-052f,U+1c80-1c88,U+20b4,U+2de0-2dff,U+a640-a69f,U+fe2e-fe2f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format("woff2");unicode-range:U+0400-045f,U+0490-0491,U+04b0-04b1,U+2116;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format("woff2");unicode-range:U+1f??;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format("woff2");unicode-range:U+0370-03ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format("woff2");unicode-range:U+0590-05ff,U+200c-2010,U+20aa,U+25cc,U+fb1d-fb4f;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format("woff2");unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01a0-01a1,U+01af-01b0,U+1ea0-1ef9,U+20ab;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format("woff2");unicode-range:U+0100-024f,U+0259,U+1e??,U+2020,U+20a0-20ab,U+20ad-20cf,U+2113,U+2c60-2c7f,U+a720-a7ff;}
@font-face{font-family:Open Sans;font-stretch:100%;font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format("woff2");unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd;}