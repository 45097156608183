/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/bootstrap.css */
body {
    margin: 0;
}

section {
    display: block;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

b {
    font-weight: bold;
}

img {
    border: 0;
}

button,
input {
    margin: 0;
    font: inherit;
    color: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
    cursor: pointer;
}

input {
    line-height: normal;
}

@media print {

    *,
    *:before,
    *:after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}

input,
button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #23527c;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

img {
    vertical-align: middle;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

h2,
h3,
h5 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h5 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h5 {
    font-size: 14px;
}

p {
    margin: 0 0 10px;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-2,
.col-lg-2,
.col-md-3,
.col-lg-3,
.col-md-4,
.col-lg-4,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-md-8,
.col-lg-8,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-12 {
    float: left;
}

.col-xs-12 {
    width: 100%;
}

@media (min-width: 768px) {

    .col-sm-6,
    .col-sm-12 {
        float: left;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-6 {
        width: 50%;
    }
}

@media (min-width: 992px) {

    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-6,
    .col-md-8,
    .col-md-12 {
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }
}

@media (min-width: 1200px) {

    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-6,
    .col-lg-8,
    .col-lg-12 {
        float: left;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-8 {
        width: 66.66666667%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-4 {
        width: 33.33333333%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-2 {
        width: 16.66666667%;
    }
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    display: table;
    content: " ";
}

.container:after,
.container-fluid:after,
.row:after {
    clear: both;
}

.visible-xs-block,
.visible-sm-block,
.visible-md-block,
.visible-lg-block {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/font-awesome.css */
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-facebook:before {
    content: "\f09a";
}

.fa-bars:before {
    content: "\f0c9";
}

.fa-caret-right:before {
    content: "\f0da";
}

.fa-linkedin:before {
    content: "\f0e1";
}

.fa-angle-up:before {
    content: "\f106";
}

.fa-instagram:before {
    content: "\f16d";
}

/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/style_II.css */
body {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #797979;
    overflow-x: hidden;
}

a {
    color: #797979;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #707070;
    text-decoration: none;
    outline: none;
}

h2,
h3,
h5 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.1;
    color: #222222;
    margin: 0;
    padding: 0;
}

input,
button {
    outline: none;
}

input:focus,
button:focus {
    outline: none;
    box-shadow: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.gc_header_wrapper {
    float: left;
    width: 100%;
    text-align: center;
}

.gc_logo {
    float: left;
    width: auto;
}

.gc_logo img {
    display: inline-block;
}

.header-area {
    float: left;
    width: 100%;
}

.header-area {
    padding-top: 15px;
    width: 100%;
    z-index: 9999;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.jp_top_header_img_wrapper {
    float: left;
    width: 100%;
    background: #ffffff;
}

.gc_main_menu_wrapper {
    float: left;
    width: 100%;
    padding-top: 30px;
    border-bottom: 1px solid #ffffff21;
    position: relative;
}

.mainmenu {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    padding-right: 15px;
    position: absolute;
    right: -132px;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    visibility: hidden;
}

.mainmenu ul li {
    display: inline-block;
    position: relative;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.mainmenu ul li a {
    color: #000000;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 0px 20px 47px 10px;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    position: relative;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.gc_main_navigation {
    color: #000 !important;
    font-weight: bold;
}

.mainmenu ul li:hover .gc_main_navigation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    color: #23c0e9 !important;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.mainmenu ul li.has-mega {
    position: static;
}

.mainmenu .gc_main_navigation.parent:after,
.mainmenu .gc_main_navigation.parent:before,
.mainmenu ul li.has-mega>a:before,
.mainmenu ul li.has-mega>a:after {
    content: "";
    width: 11px;
    height: 1px;
    background-color: #23c0e9 !important;
    top: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0px auto;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.mainmenu .gc_main_navigation.parent:before,
.mainmenu ul li.has-mega a:before {
    width: 0px;
    top: 50%;
    left: -40px;
}

.mainmenu .gc_main_navigation.parent:hover:before,
.mainmenu ul li.has-mega:hover>a:before {
    width: 10px;
    top: 50%;
}

.mainmenu .gc_main_navigation.parent:after,
.mainmenu ul li.has-mega a:after {
    width: 0px;
    top: 50%;
    left: 5px;
}

.mainmenu .gc_main_navigation.parent:hover:after,
.mainmenu ul li.has-mega:hover>a:after {
    width: 30px;
    top: 50%;
}

.header-area.stick .mainmenu ul li a {
    color: #ffffff;
}

.stick .mainmenu ul li.parent:after,
.stick .mainmenu ul li.parent:before,
.stick .mainmenu ul li.has-mega>a:before,
.stick .mainmenu ul li.has-mega>a:after {
    background-color: #222;
}

.hidden-menu-bar .mainmenu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    padding-right: 0;
    position: relative;
    z-index: 10;
    float: right;
}

.float_left {
    float: left;
}

.jp_newsletter_wrapper {
    float: left;
    width: 100%;
    background: #ffffff14;
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
}

.jp_newsletter_img_overlay_wrapper {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background: #0e1427eb;
}

.jp_newsletter_field {
    float: left;
    width: 100%;
}

.jp_newsletter_field input {
    float: left;
    width: calc(95% - 160px);
    border: 2px solid transparent;
    height: 50px;
    line-height: 50px;
    padding: 0px 15px;
    color: #797979;
    background-color: #ffffff;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    position: relative;
    padding-left: 40px;
}

.jp_newsletter_field button {
    float: right;
    width: 160px;
    height: 50px;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 2px;
    background-color: #f36969;
    color: #ffffff;
    border: 0px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_newsletter_field button:hover {
    background: #ffffff;
    color: #000000;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_main_footer_img_wrapper {
    background: url("http://127.0.0.1:8000/static/jobPortal/images/content/footer_bg.jpg") 50% 0 repeat-y;
    background-position: center 0;
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    border-bottom: 2px solid #23c0e9;
}

.jp_footer_three_sec_main_wrapper {
    float: left;
    width: 100%;
    padding-top: 80px;
}

.jp_footer_main_wrapper {
    float: left;
    width: 100%;
    padding-top: 50px;
}

.jp_footer_first_cont h2 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
}

.jp_footer_first_cont h2:after {
    content: "";
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 11px;
}

.jp_footer_first_cont h2:before {
    content: "";
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: 0;
}

.jp_footer_first_cont p {
    padding-top: 60px;
    color: #ffffffd9;
}

.jp_footer_candidate h2 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
}

.jp_footer_candidate h2:after {
    content: "";
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 11px;
}

.jp_footer_candidate h2:before {
    content: "";
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: 0;
}

.jp_footer_candidate ul {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    margin-top: 60px;
}

.jp_footer_candidate ul li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 0px 0px 15px 0px;
}

.jp_footer_candidate ul li a {
    float: left;
    width: auto;
    text-transform: capitalize;
    font-size: 16px;
    color: #ffffffd9;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_footer_candidate ul li a i {
    padding-right: 10px;
    color: #f36969;
}

.jp_footer_candidate ul li a:hover {
    color: #f36969;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_bottom_footer_Wrapper {
    float: left;
    width: 100%;
    border-top: 1px solid #ffffff1a;
    margin-top: 80px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.jp_bottom_footer_left_cont p {
    color: #ffffff8c;
    margin-top: 12px;
}

.jp_bottom_footer_right_cont ul {
    float: right;
}

.jp_bottom_footer_right_cont li {
    float: left;
    margin-left: 20px;
}

.jp_bottom_footer_right_cont li a {
    float: left;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background: transparent;
    text-align: center;
    line-height: 50px;
    color: #ffffff8c;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_bottom_footer_right_cont li a:hover {
    background: #23c0e9;
    color: #ffffff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_bottom_footer_left_cont {
    position: relative;
}

#return-to-top {
    position: absolute;
    float: left;
    bottom: 20px;
    right: -17px;
    top: 0;
    background: transparent;
    border: 1px solid #ffffff2e;
    width: 52px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 1000;
}

#return-to-top i {
    color: #ffffff8c;
    margin: 0;
    position: relative;
    left: 16px;
    top: 8px;
    font-size: 30px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#return-to-top:hover i {
    top: 5px;
    color: #ffffff;
}

#return-to-top:hover {
    background: #23c0e9;
    border: 1px solid transparent;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/responsive2.css */
@media (max-width: 767px) {
    .jp_footer_candidate_wrapper2 {
        margin-top: 40px;
    }
}

@media (max-width: 991px) {
    .mainmenu {
        display: none;
    }

    .jp_footer_candidate_wrapper4,
    .jp_footer_candidate_wrapper3 {
        margin-top: 40px;
    }

    .jp_bottom_footer_Wrapper {
        text-align: center;
    }

    .jp_bottom_footer_left_cont {
        display: inline-block;
        float: left;
        width: 100%;
    }

    .jp_bottom_footer_right_cont {
        display: inline-block;
        float: left;
        width: 100%;
    }

    .jp_bottom_footer_right_cont ul {
        float: none;
        display: inline-block;
        margin-top: 20px;
    }

    .jp_bottom_top_scrollbar_wrapper {
        display: none;
    }

    .jp_newsletter_field {
        margin-top: 30px;
    }

    .gc_logo {
        position: relative;
        top: -16px;
    }
}

@media (max-width: 1700px) {
    .mainmenu {
        right: -85px;
    }
}

@media (max-width: 1500px) {
    .mainmenu {
        right: -25px;
    }
}

@media (max-width: 1250px) {
    .full_width {
        width: 100%;
    }

    .mainmenu {
        right: 0;
    }

    .hidden-menu-bar .mainmenu,
    .float_left {
        float: none;
    }

    .gc_logo {
        float: none;
        display: inline-block;
    }

    .mainmenu ul li a {
        padding: 0px 14px 47px 10px;
    }

    .mainmenu:after {
        display: none;
    }

    .mainmenu {
        text-align: center;
    }
}

@media (max-width: 1330px) {
    .mainmenu:after {
        right: 2px;
    }
}

@media (max-width: 472px) {
    .jp_newsletter_field input {
        width: 100%;
        float: none;
    }

    .jp_newsletter_field button {
        float: none;
        margin-top: 20px;
    }
}

/*! CSS Used from: Embedded */
.topnav {
    overflow: hidden;
    background-color: white;
}

.topnav a {
    float: left;
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}

.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

/*! CSS Used from: Embedded */
a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

b {
    font-weight: bold;
}

img {
    border: 0;
}

@media print {

    *,
    *:before,
    *:after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    a[href^="#"]:after {
        content: "";
    }

    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #23527c;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

img {
    vertical-align: middle;
}

h2,
h3 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

p {
    margin: 0 0 10px;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.col-md-4,
.col-md-8,
.col-md-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {

    .col-md-4,
    .col-md-8,
    .col-md-12 {
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }
}

.container:before,
.container:after {
    display: table;
    content: " ";
}

.container:after {
    clear: both;
}

a {
    color: #797979;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #707070;
    text-decoration: none;
    outline: none;
}

h2,
h3 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.1;
    color: #222222;
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-overflow-scrolling: touch;
    font-family: "Roboto", sans-serif !important;
    font-weight: normal;
    font-style: normal;
}

h2,
h3 {
    margin-top: 0;
    margin-bottom: 0;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0;
    list-style: none;
}

b {
    font-weight: 500;
}

img {
    vertical-align: middle;
    border-style: none;
}

.container:after,
.container:before {
    content: " ";
    display: table;
}

a {
    text-decoration: none;
}

h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: normal;
    color: #272c37;
}

:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

@media (min-width: 768px) {
    h2 {
        font-size: 24px;
        line-height: 34px;
    }
}

.container {
    width: 100%;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container {
    clear: both;
}

h3 {
    color: #272c37;
}

a {
    color: #1179ef;
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus {
    color: #0a5dc9;
    outline: 0;
}

img {
    max-width: 100%;
    height: auto;
}

img.blend-mode[src] {
    filter: alpha(opacity=0);
    animation: simageScroll 0.3s forwards;
    -webkit-animation: simageScroll 0.3s forwards;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.clear {
    display: block;
    clear: both;
}

h2 {
    font-size: 20px;
    line-height: 1.3;
    color: #272c37;
    font-weight: 400;
    margin-top: 56px;
    letter-spacing: 1px;
}

h2 b {
    font-weight: 500;
}

h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 40px 0 0;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: #272c37;
}

li {
    color: #51565e;
    font-size: 14px;
    line-height: 1.43;
}

p {
    color: #51565e;
    font-size: 14px;
    line-height: 1.57;
}

@media (max-width: 767px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 100%;
        display: inline-block;
        padding: 0 30px;
    }
}

@media (min-width: 992px) {
    p {
        font-size: 16px;
        line-height: 1.5;
    }

    h2 {
        font-size: 32px;
        line-height: 1.33;
        letter-spacing: 1.5px;
    }

    h3 {
        font-size: 20px;
    }
}

@media (min-width: 1200px) {
    h2 {
        margin-top: 64px;
    }
}

@media (max-width: 767px) {
    h3 {
        margin-top: 32px;
    }
}

.overview {
    background: #fff;
    padding-bottom: 56px;
}

.overview p {
    margin-top: 24px;
}

.overview ul {
    margin-top: 24px;
    margin-bottom: 0;
}

.overview ul.icon-list li {
    padding-left: 44px;
    position: relative;
    margin-bottom: 24px;
    display: inline-block;
    width: 100%;
    min-height: 24px;
}

.overview ul.icon-list li:before {
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(https://www.simplilearn.com/ice9/assets/course_sprite_7.svgz) no-repeat;
    background-position: -214px -248px;
    position: absolute;
    left: 0;
    top: 0px;
}

.overview ul.icon-list li:last-child {
    margin-bottom: 0;
}

.overview .skill ul#skill-covered li:before {
    background-position: -447px -130px;
    top: 4px;
}

@media (min-width: 992px) {
    .overview {
        padding-bottom: 64px;
    }

    .overview p {
        margin-top: 32px;
        font-size: 14px;
        line-height: 1.57;
    }

    .overview .col-md-8 {
        width: 660px;
        float: left;
        position: relative;
        padding-right: 40px;
    }

    .overview .col-md-4 {
        width: calc(100% - 660px);
        float: left;
        position: relative;
        padding-left: 30px;
    }

    .overview .col-md-4::before {
        content: "";
        width: 1px;
        height: 56px;
        display: inline-block;
        background: #d2d6de;
        position: absolute;
        left: 0;
        top: 40px;
    }

    .overview .col-md-4.intro {
        padding-top: 20px;
    }

    .overview .col-md-4.skill:before {
        top: 132px;
        height: 99px;
    }

    .overview h3 {
        margin-top: 48px;
    }

    .intro-text {
        font-size: 14px;
        line-height: 1.43;
        margin-left: 20px;
    }

    .key-features {
        padding-right: 0;
    }

    .key-features ul.icon-list {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .key-features ul.icon-list li {
        display: block;
        float: left;
        width: 50%;
        padding-right: 30px;
        margin-bottom: 32px;
    }

    .overview ul.icon-list {
        margin-top: 40px;
    }

    .overview ul#skill-covered {
        position: relative;
        top: -6px;
    }
}

@media (min-width: 1200px) {
    .overview .col-md-8 {
        width: 712px;
        padding-right: 52px;
    }

    .overview .col-md-4 {
        width: calc(100% - 712px);
        padding-left: 70px;
    }

    .key-features ul li {
        padding-right: 42px;
    }
}

.benefits {
    display: inline-block;
    width: 100%;
}

.benefits .benefit-rows li {
    color: #51565e;
}

.tab a {
    display: inline-block;
    color: #1179ef;
    text-align: left;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
    left: 0;
    width: 100%;
    padding: 11px 0;
    line-height: 1.7;
    border-radius: 4px;
    background-color: #ebf1f8;
    padding-left: 20px;
    position: relative;
}

.tab a::after {
    content: "";
    width: 22px;
    height: 22px;
    background: url(https://www.simplilearn.com/ice9/assets/course_sprite_7.svgz) no-repeat;
    background-position: -124px -245px;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.tab li {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.tab li .tabcontent {
    display: none;
}

.tab li.active a {
    background-color: #1179ef;
    color: #fff;
}

.tab li.active a::after {
    background-position: -125px -195px;
}

.tab li.active .tabcontent {
    display: block;
}

.hire,
.salarys {
    padding: 0 15px;
}

.graphic span.heading {
    font-size: 14px;
    font-weight: 500;
    color: #272c37;
    display: inline-block;
    width: 100%;
    margin: 24px 0 8px 5px;
}

.graphic span.heading::after {
    content: "";
    width: 25px;
    height: 2px;
    background-color: #1179ef;
    display: block;
    position: relative;
    left: 0;
    top: 8px;
    margin-bottom: 20px;
}

.hire-companys {
    margin-top: 0;
    text-align: center;
}

.hire-company {
    max-height: 60px;
    margin: 40px 20px 0px 0;
    width: auto;
}

.hire {
    padding-bottom: 24px;
}

.hire-logos-wrapper div {
    display: inline-block;
    vertical-align: middle;
}

@media (max-width: 991px) {
    .benefit-rows {
        display: none;
    }

    .tab ul li {
        border: 1px solid #d2d6de;
        margin-bottom: 8px;
        border-radius: 4px;
    }

    .graphic {
        margin-top: 32px;
    }

    .benefits p {
        margin-top: 24px !important;
    }
}

@media (min-width: 992px) {
    .tab a {
        color: #272c37;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        left: 0;
        width: 280px;
        padding: 18.6px 0;
        line-height: 1.7;
        border-bottom: 1px solid #d2d6de;
    }

    .tab a::after {
        display: none;
    }

    .tab .three a {
        padding: 29px 0;
    }

    .tabcontent {
        padding: 0 12px;
        float: right;
        width: calc(92% - 200px);
        height: 242px;
        position: absolute;
        left: 280px;
        top: -1px;
    }

    .benefits li {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.4px;
        color: #272c37;
        margin: 0;
        display: inline-block;
        padding: 0;
        width: 100%;
    }

    .benefits ul {
        margin-top: 30px;
        display: inline-block;
        margin-bottom: 0;
        width: 100%;
        position: relative;
    }

    .tab ul {
        border: 1px solid #d2d6de;
        min-height: 272px;
        margin-top: 0;
        border-radius: 4px;
        overflow: hidden;
    }

    .tab ul li {
        box-shadow: none;
    }

    .tab ul li a {
        border-radius: 0;
        border-right: 1px solid #d2d6de;
    }

    .tab ul li:first-child a {
        border-radius: 4px 0 0 0;
    }

    .tab ul li:last-child a {
        border-bottom: 0;
        border-radius: 0 0 0 4px;
    }

    .tab ul li.active a {
        border-right: 0;
    }

    .benefit-rows ul {
        display: flex;
        text-align: center;
        margin-top: 0;
    }

    .tabcontent span {
        clear: both;
        position: absolute;
        bottom: 19px;
        left: 92px;
    }

    .tab li.active a {
        background-color: #fff;
        color: #1179ef;
    }

    .hire-company {
        margin: 18px;
    }

    .salarys {
        width: 50%;
        height: 271px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 16px;
    }

    .salary-line {
        margin: 80px 0 0;
    }

    .salarys span {
        display: none;
    }

    .hire-companys span {
        display: none;
    }

    .graphic {
        margin-top: 24px;
    }

    .benefit-rows {
        margin-top: 32px;
    }

    .benefits p {
        margin-top: 24px !important;
        font-size: 14px;
        line-height: 1.57;
    }

    .benefit-rows ul li:first-child {
        max-width: 280px;
    }

    .benefits h3+p {
        max-width: 850px;
    }
}

@media (max-width: 991px) {
    .tab ul {
        margin-top: 0;
    }

    .tab ul .salarys {
        min-height: 150px;
    }

    .tab ul li.active {
        border-color: #1179ef;
    }

    .salarys {
        min-height: 100px;
    }

    .tab li.active a {
        border-radius: 0;
    }
}

.salary-line {
    text-align: center;
    display: inline-block;
    width: 100%;
    margin: 46px 0 0;
}

.salarys .active {
    text-align: center;
    position: relative;
    border-bottom: 1px solid #000;
    width: auto;
    display: inline-block;
    padding: 0 10px;
}

.salarys .dual-bar {
    width: 40px;
    height: 128px;
    background-color: #79bdf6;
    display: inline-block;
    margin-left: 15px;
    vertical-align: bottom;
    position: relative;
}

.salarys .dual-bar .price {
    font-size: 10px;
    font-weight: 500;
    color: #272c37;
    position: absolute;
    top: -16px;
    right: 0;
    left: 0;
    margin: auto;
}

.salarys .dual-bar .price::after {
    display: none;
}

.salarys .dual-bar .percent {
    top: inherit;
    bottom: -20px;
}

.salarys .dual-bar:first-child {
    margin-left: 0;
    height: 20px;
}

.salarys .dual-bar:nth-child(2),
.salarys .dual-bar:nth-child(4) {
    height: 60px;
}

.salarys .dual-bar:nth-child(5) {
    height: 20px;
}

.salarys .salary-sourse {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: block;
    position: relative;
    left: 0;
    top: 16px;
    margin-top: 14px;
    color: #51565e;
}

.salarys .salary-sourse::after {
    display: none;
}

@media (min-width: 768px) {
    .graphic .hire-logos-wrapper {
        max-height: 175px;
        overflow: hidden;
    }

    .graphic .hire-logos-wrapper div {
        height: 86px;
    }

    .graphic .hire-logos-wrapper div::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
    }

    .graphic .hire-logos-wrapper div img {
        margin-top: 0;
        display: inline-block;
        vertical-align: middle;
    }

    .salarys {
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }

    .hire {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
    }

    .hire-companys {
        height: 100%;
        min-height: 189px;
    }

    .tab ul .hire {
        min-height: 292px;
    }

    .hire span {
        font-size: 14px;
        font-weight: 500;
        color: #272c37;
        display: block;
        clear: both;
        margin-left: 5px;
    }

    .hire {
        padding-bottom: 0;
        position: relative;
    }

    .hire .hire-source {
        padding-bottom: 16px;
    }

    .hire-companys {
        min-height: 205px;
    }

    .hire-companys span.hire-source {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 20px;
        z-index: 1;
        height: 20px;
        top: inherit;
    }

    .salary-line {
        margin: 26px 0 0;
    }

    .salarys .salary-sourse {
        padding-bottom: 16px;
    }
}

@media (min-width: 992px) {
    .salarys .dual-bar .price {
        display: block;
    }

    .graphic span.heading {
        display: none;
    }

    .graphic .tab li.active a {
        cursor: default;
    }

    .graphic .tab li.active a::after {
        display: none;
    }

    .tab a:hover {
        background-color: #d2d9e4;
    }

    .graphic .hire-logos-wrapper div {
        height: 74px;
    }

    .graphic .hire-logos-wrapper div img {
        margin-bottom: 0;
    }
}

@media (max-width: 1199px) {
    .salarys .dual-bar {
        width: 34px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .hire-companys {
        max-height: 195px;
        overflow: hidden;
        margin-top: 40px;
    }

    .salary-info .tab li.active a {
        cursor: default;
    }

    .salary-info .tab li.active a::after {
        display: none;
    }

    .salarys {
        padding-bottom: 16px;
    }

    .salary-line {
        margin: 80px 0 0;
    }
}

.salarys .salary-line .active {
    position: relative;
    border-bottom: 1px solid #000;
    width: auto;
    padding: 0 10px;
    text-align: center;
    display: inline-block;
}

.hire .hire-source {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: block;
    position: relative;
    left: 0;
    top: 0px;
    color: #51565e;
}

@media (min-width: 992px) {
    .graphic .salarys {
        padding-bottom: 0;
        height: 242px;
    }

    .graphic span.heading {
        display: inline-block;
        width: 1px;
        overflow: hidden;
        height: 242px;
        vertical-align: middle;
        margin: 0;
        position: static;
        background: #fff;
        color: #fff;
    }

    .graphic span.heading::after {
        display: none;
    }

    .graphic .salary-line {
        margin: auto;
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 1px);
        margin-top: 2px;
    }

    .graphic .hire-companys {
        margin: 0;
        min-height: 10px;
        width: calc(100% - 1px);
        display: inline-block;
        vertical-align: middle;
    }

    .graphic .tab ul {
        min-height: 10px;
        height: 242px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .graphic .tab ul .hire {
        min-height: 242px;
    }

    .graphic .hire .hire-source,
    .graphic .salarys .salary-sourse {
        padding: 0;
        margin: 0;
        margin-top: 16px;
    }

    .graphic .hire-logos-wrapper {
        max-height: 148px;
        overflow: hidden;
    }

    .graphic .hire-company {
        margin: 20px 18px 20px 18px;
    }
}

@media (min-width: 1200px) {
    .hire-companys {
        min-height: 159px;
        margin-top: 80px;
    }

    .salary-line {
        margin: 80px 0 0;
    }

    .graphic .hire-logos-wrapper {
        max-height: 160px;
    }
}

@media (max-width: 767px) {
    .hire .hire-source {
        margin-top: 24px;
    }
}

@media (max-width: 991px) {
    .hire-companys span.hire-source {
        bottom: 12px;
    }
}

.course-tip {
    font-size: 14px;
    margin-top: 16px;
    color: #27ba44;
    font-weight: 500;
    padding: 0;
    position: relative;
    display: inline-block;
    border-radius: 16px;
    border: solid 1px #61cfa2;
    line-height: 29px;
    padding-right: 40px;
    min-width: 271px;
}

.course-tip .q-mark {
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 24px;
    padding: 1px;
    background: url(https://www.simplilearn.com/ice9/assets/course_sprite_7.svgz) no-repeat;
    background-position: -483px -361px;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: 572px;
}

.course-tip .course-tiptext {
    display: none;
    font-size: 14px;
    width: 248px;
    color: #fff;
    text-align: left;
    padding: 16px 15px;
    position: absolute;
    z-index: 3;
    top: 25px;
    right: -78px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #51565e;
}

.course-tip .course-tiptext::after {
    content: "";
    position: absolute;
    top: -8px;
    right: 0;
    left: 152px;
    width: 8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #51565e;
}

.course-tip .q-mark:hover .course-tiptext {
    display: block;
}

.course-tip::before {
    content: "";
    width: 22px;
    height: 22px;
    display: inline-block;
    background: url(https://www.simplilearn.com/ice9/assets/course_sprite_7.svgz) no-repeat;
    background-position: -371px -332px;
    position: relative;
    left: 0;
    top: 5px;
    margin: 0 10px 0 15px;
}

.moneyBackGuarantee .course-tip::before {
    background-position: -406px -332px;
}

@media (min-width: 768px) {
    .course-tip {
        margin: 24px 30px 0 0;
        min-width: auto;
    }

    .course-tip .course-tiptext {
        width: 412px;
        right: -196px;
    }

    .course-tip .course-tiptext::after {
        left: 0;
        margin: 0 auto;
    }

    .key-features h3 {
        width: auto;
        display: inline-block;
        margin-right: 20px;
    }

    .moneyBackGuarantee {
        display: inline-block;
    }
}

@media (min-width: 992px) {
    .course-tip {
        margin-top: 32px;
    }
}

@media (max-width: 1199px) {
    .course-tip:hover .course-tiptext {
        display: block;
    }
}

@media (max-width: 991px) {
    li.active {
        font-weight: 400;
    }
}

a {
    background: transparent;
}

a:active,
a:hover {
    outline: 0;
}

img {
    border: 0;
}

@media print {
    * {
        text-shadow: none !important;
        color: #000 !important;
        background: transparent !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    h2,
    p {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }
}

h2 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h2 {
    font-size: 30px;
}

p {
    margin: 0 0 10px;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
}

p {
    font-size: 14px;
    line-height: 21px;
    font-family: OpenSans, Arial, sans-serif;
}

*,
:after,
:before {
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: "#0062e4";
}

a:focus,
a:hover {
    color: "#004eb6";
}

a:focus {
    outline: thin dotted;
}

p {
    font-size: 14px;
    line-height: 21px;
    font-family: OpenSans, Arial, sans-serif;
}

h2 {
    margin: 0;
    padding: 0;
}

p {
    margin-bottom: 10px;
}

ul {
    font-size: 1em;
    outline: 0;
}

@media print {

    a:link:after,
    a:visited:after {
        content: "" !important;
    }
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
    outline-width: 0;
}

img {
    border-style: none;
}

*,
:after,
:before {
    box-sizing: inherit;
}

h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    margin-bottom: 1rem;
}

ul {
    margin-top: 0;
}

a {
    color: #0062e4;
    text-decoration: none;
}

a:focus,
a:hover {
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

img {
    vertical-align: middle;
}

a {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2 {
    font-family: OpenSans-light, Arial, sans-serif;
}

h2 {
    font-size: 2.125rem;
    line-height: 2.625rem;
}

@media print {
    * {
        overflow: visible !important;
    }
}

h2,
p {
    max-width: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-overflow-scrolling: touch;
    font-family: "Roboto", sans-serif !important;
    font-weight: normal;
    font-style: normal;
}

h2,
h3 {
    margin-top: 0;
    margin-bottom: 0;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0;
    list-style: none;
}

b {
    font-weight: 500;
}

.container:after,
.container:before {
    content: " ";
    display: table;
}

a {
    text-decoration: none;
}

h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: normal;
    color: #272c37;
}

:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

@media (min-width: 768px) {
    h2 {
        font-size: 24px;
        line-height: 34px;
    }
}

.container {
    width: 100%;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container {
    clear: both;
}

h3 {
    color: #272c37;
}

a {
    color: #1179ef;
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus {
    color: #0a5dc9;
    outline: 0;
}

.clear {
    display: block;
    clear: both;
}

h2 {
    font-size: 20px;
    line-height: 1.3;
    color: #272c37;
    font-weight: 400;
    margin-top: 56px;
    letter-spacing: 1px;
}

h2 b {
    font-weight: 500;
}

h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 40px 0 0;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: #272c37;
}

li {
    color: #51565e;
    font-size: 14px;
    line-height: 1.43;
}

p {
    color: #51565e;
    font-size: 14px;
    line-height: 1.57;
}

@media (max-width: 767px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 100%;
        display: inline-block;
        padding: 0 30px;
    }
}

@media (min-width: 992px) {
    p {
        font-size: 16px;
        line-height: 1.5;
    }

    h2 {
        font-size: 32px;
        line-height: 1.33;
        letter-spacing: 1.5px;
    }

    h3 {
        font-size: 20px;
    }
}

@media (min-width: 1200px) {
    h2 {
        margin-top: 64px;
    }
}

@media (max-width: 767px) {
    h3 {
        margin-top: 32px;
    }
}

@media (max-width: 991px) {
    li.active {
        font-weight: 400;
    }
}

/*! CSS Used keyframes */
@-webkit-keyframes simageScroll {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    25% {
        opacity: 0.3;
        filter: alpha(opacity=30);
    }

    50% {
        opacity: 0.5;
        filter: alpha(opacity=50);
    }

    75% {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
}

@-moz-keyframes simageScroll {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    25% {
        opacity: 0.3;
        filter: alpha(opacity=30);
    }

    50% {
        opacity: 0.5;
        filter: alpha(opacity=50);
    }

    75% {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
}

@keyframes simageScroll {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    25% {
        opacity: 0.3;
        filter: alpha(opacity=30);
    }

    50% {
        opacity: 0.5;
        filter: alpha(opacity=50);
    }

    75% {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    src: local("Lato Light"), local("Lato-Light"),
        url(https://fonts.gstatic.com/s/lato/v13/dPJ5r9gl3kK6ijoeP1IRsvY6323mHUZFJMgTvxaG2iE.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
        U+A720-A7FF;
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    src: local("Lato Light"), local("Lato-Light"),
        url(https://fonts.gstatic.com/s/lato/v13/EsvMC5un3kjyUhB9ZEPPwg.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"),
        url(https://fonts.gstatic.com/s/lato/v13/UyBMtLsHKBKXelqf4x7VRQ.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
        U+A720-A7FF;
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"),
        url(https://fonts.gstatic.com/s/lato/v13/1YwB1sO8YE1Lyjf12WNiUA.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Bold"), local("Lato-Bold"),
        url(https://fonts.gstatic.com/s/lato/v13/ObQr5XYcoH0WBoUxiaYK3_Y6323mHUZFJMgTvxaG2iE.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
        U+A720-A7FF;
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Bold"), local("Lato-Bold"),
        url(https://fonts.gstatic.com/s/lato/v13/H2DMvhDLycM56KNuAtbJYA.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"),
        url(https://fonts.gstatic.com/s/lato/v13/R4a6fty3waPci7C44H8AjvY6323mHUZFJMgTvxaG2iE.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
        U+A720-A7FF;
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"),
        url(https://fonts.gstatic.com/s/lato/v13/tI4j516nok_GrVf4dhunkg.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/rBHvpRWBkgyW99dXT88n7yEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/NX1NravqaXESu9fFv7KuqiEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/SKK6Nusyv8QPNMtI4j9J2yEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/gFXtEMCp1m_YzxsBpKl68iEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
        U+A720-A7FF;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/rBHvpRWBkgyW99dXT88n7yEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/NX1NravqaXESu9fFv7KuqiEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/SKK6Nusyv8QPNMtI4j9J2yEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/gFXtEMCp1m_YzxsBpKl68iEAvth_LlrfE80CYdSH47w.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
        U+A720-A7FF;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Roboto";
    src: url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff2) format("woff2"),
        url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff) format("woff");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff2) format("woff2"),
        url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff) format("woff");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff2) format("woff2"),
        url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff) format("woff");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff2) format("woff2"),
        url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff) format("woff");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.eot);
    src: url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.eot?#iefix) format("embedded-opentype"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-regular.woff2) format("woff2"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-regular.woff) format("woff"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.ttf) format("truetype"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.svg#OpenSans-Regular) format("svg");
}

@font-face {
    font-family: "OpenSans";
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot);
    src: url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot?#iefix) format("embedded-opentype"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff2) format("woff2"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff) format("woff"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.ttf) format("truetype"),
        url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.svg#OpenSans-Bold) format("svg");
}