.testimonial-card .card-up {
    height: 120px;
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .testimonial-card .avatar {
    width: 110px;
    margin-top: -60px;
    overflow: hidden;
    border: 3px solid #fff;
    border-radius: 50%;
  }