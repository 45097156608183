body {
	background-color: #f5f7fa;
}

.card.booking-card.v-2 {
  background-color: #ffffff;
}

.card.booking-card.v-2 .fa {
  color: #f7aa00;
}

.card.booking-card.v-2 .card-body .card-text {
  color: #3243da;
}

.card.card.booking-card.v-2 .chip {
  background-color: #87e5da;
}

.card.booking-card.v-2 .card-body hr {
  border-top: 2px solid #ff0000;
}
