.loaders{
       position: absolute;
     margin-left: "50%";
     margin-top: "50%";
   
    z-index: 5;
    }

    .example {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        background: rgba(0, 0, 0, 0.05);
        border-radius:4px}




/* 
 body{
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #248229;
 }       
 .loader{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#14ffe9,#ffeb3b,#ff0be0);
  animation: animate 0.75s linear infinite;

 }
 .loader span{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#14ffe9,#ffeb3b,#ff00e0);

 }
 .loader span:nth-child(1){
  filter: blur(5px);
  }
 

.loader span:nth-child(2){
 filter: blur(10px);
 }

 .loader span:nth-child(3){
  filter: blur(25px);
  }

  .loader span:nth-child(4){
    filter: blur(50px);
    }

    .loader::after{
      content: "";
      position: absolute;
      top: 10px;
      right: 10px;
      left: 10px;
      bottom: 10px;
      background: #240229;
      border-radius: 50%;

    }

    @keyframes animate {
      0% {
        transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
   

    } */
    .loader{
      width:500px ;
      height:4px ;
      border-radius:30px ;
      background:rgba(0, 0, 0, 0.2) ;
      position: relative;
    }
    .loader::before{
      content: "";
      position: absolute;
      background: #0e65ef;
      top: 0;
      left:0 ;
      width:0% ;
      height:100px ;
      border-radius:30px ;
      animation: ani 1s ease-in-out infinite;
    }
    @keyframes ani {
      50%{
      width: 100%;
    } 
    100%{
      width:0;
      right:0;
      left: unset;
    }
  }