.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
.h-custom {
    margin: 10px;
height: calc(100% - 73px);
}
@media (max-width: 450px) {
.h-custom {
height: 100%;
}
}

/* .bodyContainer {
     background-color: rgb(160, 205, 241);
     border-radius: 15px;
     margin: 10px;
} */
.colorofBody {
    background-color: blueviolet;
    border-radius: 15px;
    margin: 10px;
}