/*! CSS Used from: https://trainings.internshala.com/static/libraries/bootstrap/4.5.2/css/bootstrap.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}

section {
    display: block;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    img {
        page-break-inside: avoid;
    }

    p {
        orphans: 3;
        widows: 3;
    }
}

/*! CSS Used from: https://trainings.internshala.com/static/css/signup/compact/signup.1678348615.css */
*,
:focus,
:hover {
    outline: 0 !important;
}

p {
    margin-bottom: 0;
}

.section {
    padding-top: calc(64px - 0px);
    margin: auto;
    max-width: 1224px;
}

@media (max-width: 1351.98px) {
    .section {
        max-width: 991px;
    }
}

@media (max-width: 1071.98px) {
    .section {
        padding-top: calc(48px - 0px);
        max-width: 527px;
    }
}

@media (max-width: 575.98px) {
    .section {
        margin: 0;
        padding-top: calc(40px - 0px);
        padding-left: calc(24px - 0px);
        padding-right: calc(24px - 0px);
        max-width: 100%;
    }
}

.heading {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
    text-align: center;
}

@media (max-width: 1071.98px) {
    .heading {
        margin-bottom: 24px;
    }
}

@media (max-width: 575.98px) {
    .heading {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
    }
}

@media (max-width: 392.98px) {
    .section {
        padding-left: calc(16px - 0px);
        padding-right: calc(16px - 0px);
    }

    .heading {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
    }
}

.sub-heading {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

@media (max-width: 575.98px) {
    .sub-heading {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }
}

.description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #484848;
}

@media (max-width: 575.98px) {
    .description {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
}

.highlights-feature .highlights {
    margin: auto;
    max-width: 1224px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    row-gap: 32px;
    -webkit-column-gap: 64px;
    -moz-column-gap: 64px;
    column-gap: 64px;
}

@media (max-width: 1351.98px) {
    .highlights-feature .highlights {
        max-width: 794px;
        row-gap: 24px;
    }
}

@media (max-width: 1071.98px) {
    .highlights-feature .highlights {
        max-width: 308px;
    }
}

.highlights-feature .highlights .highlight {
    width: 365px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.highlights-feature .highlights .highlight .image-container {
    width: 56px;
    height: 56px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media (max-width: 575.98px) {
    .highlights-feature .highlights {
        max-width: 264px;
    }

    .highlights-feature .highlights .highlight .image-container {
        width: 48px;
        height: 48px;
    }
}

.highlights-feature .highlights .highlight .image-container img {
    width: 100%;
    height: 100%;
}

.highlights-feature .highlights .highlight .details {
    margin-left: 24px;
}

@media (max-width: 1071.98px) {
    .highlights-feature .highlights .highlight .details {
        margin-left: 20px;
    }
}

.highlights-feature .highlights .highlight .details .sub-heading {
    margin-bottom: 4px;
}

@media (max-width: 575.98px) {
    .highlights-feature .highlights .highlight .details {
        margin-left: 12px;
    }

    .highlights-feature .highlights .highlight .details .sub-heading {
        margin-bottom: 6px;
    }
}





/*! CSS Used from: https://trainings.internshala.com/static/libraries/bootstrap/4.5.2/css/bootstrap.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}

section {
    display: block;
}

h2 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

h2 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h2 {
    font-size: 2rem;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    img {
        page-break-inside: avoid;
    }

    h2,
    p {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }
}

/*! CSS Used from: https://trainings.internshala.com/static/css/signup/compact/signup.1678348615.css */
*,
:focus,
:hover {
    outline: 0 !important;
}

h2,
p {
    margin-bottom: 0;
}

h2 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
}

.section {
    padding-top: calc(64px - 0px);
    margin: auto;
    max-width: 1224px;
}

@media (max-width:1351.98px) {
    .section {
        max-width: 991px;
    }
}

@media (max-width:1071.98px) {
    .section {
        padding-top: calc(48px - 0px);
        max-width: 527px;
    }
}

@media (max-width:575.98px) {
    .section {
        margin: 0;
        padding-top: calc(40px - 0px);
        padding-left: calc(24px - 0px);
        padding-right: calc(24px - 0px);
        max-width: 100%;
    }
}

.heading {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
    text-align: center;
}

@media (max-width:1071.98px) {
    .heading {
        margin-bottom: 24px;
    }
}

@media (max-width:575.98px) {
    .heading {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
    }
}

@media (max-width:392.98px) {
    .section {
        padding-left: calc(16px - 0px);
        padding-right: calc(16px - 0px);
    }

    .heading {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
    }
}

.sub-heading {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

@media (max-width:575.98px) {
    .sub-heading {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }
}

.description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #484848;
}

@media (max-width:575.98px) {
    .description {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
}

.benefits .heading {
    margin-bottom: 24px;
    text-align: left;
    max-width: 554px;
}

.benefits .details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width:1351.98px) {
    .benefits .heading {
        max-width: 512px;
    }

    .benefits .details {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (max-width:1071.98px) {
    .benefits .heading {
        max-width: 100%;
        text-align: center;
    }

    .benefits .details {
        background: #eafcff;
        border-radius: 4px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .benefits .details .points {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-bottom: 24px;
        margin-left: 24px;
        margin-right: 24px;
    }
}

.benefits .details .points {
    max-width: 554px;
}

.benefits .details .points .point:not(:last-child) {
    margin-bottom: 20px;
}

.benefits .details .points .point .sub-heading {
    margin-bottom: 12px;
}

@media (max-width:1071.98px) {
    .benefits .details .points .point .sub-heading {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }
}

.benefits .details .points .point .description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.benefits .details .image-container {
    width: 500px;
    height: 363px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: -60px;
}

@media (max-width:1351.98px) {
    .benefits .details .points {
        max-width: 512px;
    }

    .benefits .details .image-container {
        width: 444px;
        height: 322px;
    }
}

@media (max-width:1071.98px) {
    .benefits .details .points .point .description {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }

    .benefits .details .image-container {
        margin: 16px;
        width: 280px;
        height: 202px;
    }
}

.benefits .details .image-container img {
    width: 100%;
    height: 100%;
}