/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/bootstrap.css */
section{display:block;}
a{background-color:transparent;}
a:active,a:hover{outline:0;}
b{font-weight:bold;}
img{border:0;}
button,input{margin:0;font:inherit;color:inherit;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
input{line-height:normal;}
@media print{
*,*:before,*:after{color:#000!important;text-shadow:none!important;background:transparent!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
a[href^="javascript:"]:after{content:"";}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
p,h2,h3{orphans:3;widows:3;}
h2,h3{page-break-after:avoid;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
input,button{font-family:inherit;font-size:inherit;line-height:inherit;}
a{color:#337ab7;text-decoration:none;}
a:hover,a:focus{color:#23527c;text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
img{vertical-align:middle;}
.img-responsive{display:block;max-width:100%;height:auto;}
[role="button"]{cursor:pointer;}
h2,h3,h5{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
h2,h3{margin-top:20px;margin-bottom:10px;}
h5{margin-top:10px;margin-bottom:10px;}
h2{font-size:30px;}
h3{font-size:24px;}
h5{font-size:14px;}
p{margin:0 0 10px;}
ul{margin-top:0;margin-bottom:10px;}
.container{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width: 768px){
.container{width:750px;}
}
@media (min-width: 992px){
.container{width:970px;}
}
@media (min-width: 1200px){
.container{width:1170px;}
}
.container-fluid{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
.row{margin-right:-15px;margin-left:-15px;}
.col-md-2,.col-lg-2,.col-md-3,.col-lg-3,.col-md-4,.col-lg-4,.col-sm-6,.col-md-6,.col-lg-6,.col-md-8,.col-lg-8,.col-xs-12,.col-sm-12,.col-md-12,.col-lg-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
.col-xs-12{float:left;}
.col-xs-12{width:100%;}
@media (min-width: 768px){
.col-sm-6,.col-sm-12{float:left;}
.col-sm-12{width:100%;}
.col-sm-6{width:50%;}
}
@media (min-width: 992px){
.col-md-2,.col-md-3,.col-md-4,.col-md-6,.col-md-8,.col-md-12{float:left;}
.col-md-12{width:100%;}
.col-md-8{width:66.66666667%;}
.col-md-6{width:50%;}
.col-md-4{width:33.33333333%;}
.col-md-3{width:25%;}
.col-md-2{width:16.66666667%;}
}
@media (min-width: 1200px){
.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-6,.col-lg-8,.col-lg-12{float:left;}
.col-lg-12{width:100%;}
.col-lg-8{width:66.66666667%;}
.col-lg-6{width:50%;}
.col-lg-4{width:33.33333333%;}
.col-lg-3{width:25%;}
.col-lg-2{width:16.66666667%;}
}
.btn{display:inline-block;padding:6px 12px;margin-bottom:0;font-size:14px;font-weight:normal;line-height:1.42857143;text-align:center;white-space:nowrap;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-image:none;border:1px solid transparent;border-radius:4px;}
.btn:focus,.btn:active:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
.btn:hover,.btn:focus{color:#333;text-decoration:none;}
.btn:active{background-image:none;outline:0;-webkit-box-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);box-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);}
.container:before,.container:after,.container-fluid:before,.container-fluid:after,.row:before,.row:after{display:table;content:" ";}
.container:after,.container-fluid:after,.row:after{clear:both;}
.visible-xs-block,.visible-sm-block,.visible-md-block,.visible-lg-block{display:none!important;}
@media (max-width: 767px){
.visible-xs-block{display:block!important;}
}
@media (min-width: 768px) and (max-width: 991px){
.visible-sm-block{display:block!important;}
}
@media (min-width: 992px) and (max-width: 1199px){
.visible-md-block{display:block!important;}
}
@media (min-width: 1200px){
.visible-lg-block{display:block!important;}
}
@media (max-width: 767px){
.hidden-xs{display:none!important;}
}
@media (min-width: 768px) and (max-width: 991px){
.hidden-sm{display:none!important;}
}
@media (min-width: 992px) and (max-width: 1199px){
.hidden-md{display:none!important;}
}
@media (min-width: 1200px){
.hidden-lg{display:none!important;}
}
/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/font-awesome.css */
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.fa-facebook:before{content:"\f09a";}
.fa-caret-right:before{content:"\f0da";}
.fa-linkedin:before{content:"\f0e1";}
.fa-angle-right:before{content:"\f105";}
.fa-angle-up:before{content:"\f106";}
.fa-instagram:before{content:"\f16d";}
/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/style_II.css */
a{color:#797979;text-decoration:none;}
a:hover,a:focus{color:#707070;text-decoration:none;outline:none;}
h2,h3,h5{font-family:'Montserrat', sans-serif;line-height:1.1;color:#222222;margin:0;padding:0;}
input,button{outline:none;}
input:focus,button:focus{outline:none;box-shadow:none;}
ul{list-style:none;margin:0;padding:0;}
.gc_header_wrapper{float:left;width:100%;text-align:center;}
.gc_logo{float:left;width:auto;}
.gc_logo img{display:inline-block;}
.header-area{float:left;width:100%;}
.header-area{padding-top:15px;width:100%;z-index:9999;-webkit-transition:all 0.3s ease 0s;transition:all 0.3s ease 0s;}
.gc_main_menu_wrapper{float:left;width:100%;padding-top:30px;border-bottom:1px solid #ffffff21;position:relative;}
.mainmenu{opacity:0;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";padding-right:15px;position:absolute;right:-132px;-webkit-transform:scaleX(1);transform:scaleX(1);-webkit-transition:all 0.3s ease 0s;transition:all 0.3s ease 0s;visibility:hidden;}
.mainmenu ul li{display:inline-block;position:relative;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.mainmenu ul li a{color:#000000;display:block;font-size:14px;letter-spacing:1px;padding:0px 20px 47px 10px;text-transform:capitalize;font-family:'Montserrat', sans-serif;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";position:relative;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.gc_main_navigation{color:#000!important;font-weight:bold;}
.mainmenu ul li:hover .gc_main_navigation{opacity:1;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";color:#23c0e9!important;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.mainmenu ul li.has-mega{position:static;}
.mainmenu .gc_main_navigation.parent:after,.mainmenu .gc_main_navigation.parent:before,.mainmenu ul li.has-mega > a:before,.mainmenu ul li.has-mega > a:after{content:"";width:11px;height:1px;background-color:#23c0e9!important;top:20px;position:absolute;left:0;right:0;margin:0px auto;-webkit-transition:0.3s;transition:0.3s;}
.mainmenu .gc_main_navigation.parent:before,.mainmenu ul li.has-mega a:before{width:0px;top:50%;left:-40px;}
.mainmenu .gc_main_navigation.parent:hover:before,.mainmenu ul li.has-mega:hover > a:before{width:10px;top:50%;}
.mainmenu .gc_main_navigation.parent:after,.mainmenu ul li.has-mega a:after{width:0px;top:50%;left:5px;}
.mainmenu .gc_main_navigation.parent:hover:after,.mainmenu ul li.has-mega:hover > a:after{width:30px;top:50%;}
.header-area.stick .mainmenu ul li a{color:#ffffff;}
.stick .mainmenu ul li.parent:after,.stick .mainmenu ul li.parent:before,.stick .mainmenu ul li.has-mega > a:before,.stick .mainmenu ul li.has-mega > a:after{background-color:#222;}
.hidden-menu-bar .mainmenu{opacity:1;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";visibility:visible;padding-right:0;position:relative;z-index:10;float:right;}
.float_left{float:left;}
.jp_newsletter_wrapper{float:left;width:100%;background:#ffffff14;padding-top:60px;padding-bottom:60px;position:relative;}
.jp_newsletter_img_overlay_wrapper{position:absolute;top:0%;left:0%;right:0%;bottom:0%;background:#0e1427eb;}
.jp_newsletter_field{float:left;width:100%;}
.jp_newsletter_field input{float:left;width:calc(95% - 160px);border:2px solid transparent;height:50px;line-height:50px;padding:0px 15px;color:#797979;background-color:#ffffff;-webkit-border-radius:15px;-moz-border-radius:15px;border-radius:15px;position:relative;padding-left:40px;}
.jp_newsletter_field button{float:right;width:160px;height:50px;font-size:14px;text-transform:uppercase;border-radius:2px;background-color:#f36969;color:#ffffff;border:0px;-webkit-border-radius:15px;-moz-border-radius:15px;border-radius:15px;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_newsletter_field button:hover{background:#ffffff;color:#000000;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_main_footer_img_wrapper{background:url('http://127.0.0.1:8000/static/jobPortal/images/content/footer_bg.jpg') 50% 0 repeat-y;background-position:center 0;background-size:cover;background-repeat:no-repeat;float:left;width:100%;height:100%;position:relative;border-bottom:2px solid #23c0e9;}
.jp_footer_three_sec_main_wrapper{float:left;width:100%;padding-top:80px;}
.jp_footer_main_wrapper{float:left;width:100%;padding-top:50px;}
.jp_footer_first_cont h2{font-size:20px;color:#ffffff;font-weight:900;text-transform:uppercase;position:relative;}
.jp_footer_first_cont h2:after{content:'';border:1px solid #23c0e9;width:30px;position:absolute;bottom:-15px;left:11px;}
.jp_footer_first_cont h2:before{content:'';border:1px solid #23c0e9;width:8px;position:absolute;bottom:-15px;left:0;}
.jp_footer_first_cont p{padding-top:60px;color:#ffffffd9;}
.jp_footer_candidate h2{font-size:20px;color:#ffffff;font-weight:900;text-transform:uppercase;position:relative;}
.jp_footer_candidate h2:after{content:'';border:1px solid #23c0e9;width:30px;position:absolute;bottom:-15px;left:11px;}
.jp_footer_candidate h2:before{content:'';border:1px solid #23c0e9;width:8px;position:absolute;bottom:-15px;left:0;}
.jp_footer_candidate ul{float:left;width:100%;margin:0px;padding:0px;margin-top:60px;}
.jp_footer_candidate ul li{list-style:none;float:left;width:100%;padding:0px 0px 15px 0px;}
.jp_footer_candidate ul li a{float:left;width:auto;text-transform:capitalize;font-size:16px;color:#ffffffd9;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_footer_candidate ul li a i{padding-right:10px;color:#f36969;}
.jp_footer_candidate ul li a:hover{color:#f36969;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_bottom_footer_Wrapper{float:left;width:100%;border-top:1px solid #ffffff1a;margin-top:80px;padding-top:60px;padding-bottom:60px;}
.jp_bottom_footer_left_cont p{color:#ffffff8c;margin-top:12px;}
.jp_bottom_footer_right_cont ul{float:right;}
.jp_bottom_footer_right_cont li{float:left;margin-left:20px;}
.jp_bottom_footer_right_cont li a{float:left;width:50px;height:50px;-webkit-border-radius:15px;-moz-border-radius:15px;border-radius:15px;background:transparent;text-align:center;line-height:50px;color:#ffffff8c;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_bottom_footer_right_cont li a:hover{background:#23c0e9;color:#ffffff;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_bottom_footer_left_cont{position:relative;}
#return-to-top{position:absolute;float:left;bottom:20px;right:-17px;top:0;background:transparent;border:1px solid #ffffff2e;width:52px;height:50px;display:block;text-decoration:none;-webkit-border-radius:15px;-moz-border-radius:15px;border-radius:15px;display:none;-webkit-transition:all 0.3s linear;-moz-transition:all 0.3s ease;-ms-transition:all 0.3s ease;-o-transition:all 0.3s ease;transition:all 0.3s ease;z-index:1000;}
#return-to-top i{color:#ffffff8c;margin:0;position:relative;left:16px;top:8px;font-size:30px;-webkit-transition:all 0.3s ease;-moz-transition:all 0.3s ease;-ms-transition:all 0.3s ease;-o-transition:all 0.3s ease;transition:all 0.3s ease;}
#return-to-top:hover i{top:5px;color:#ffffff;}
#return-to-top:hover{background:#23c0e9;border:1px solid transparent;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_tittle_main_wrapper{background:url('http://127.0.0.1:8000/static/jobPortal/images/content/pricing_tittle_bg.jpg') 50% 0 repeat-y;background-repeat:no-repeat;background-size:cover;background-position:center 0;position:relative;float:left;width:100%;padding-top:100px;padding-bottom:100px;}
.jp_tittle_img_overlay{position:absolute;top:0%;right:0%;left:0%;bottom:0%;background:#0e1427d9;}
.jp_tittle_heading{float:left;width:100%;}
.jp_tittle_heading h2{font-size:36px;color:#ffffff;font-weight:bold;}
.jp_tittle_breadcrumb_main_wrapper{float:left;width:100%;padding-top:25px;}
.jp_tittle_breadcrumb_wrapper li{margin-left:20px;float:left;}
.jp_tittle_breadcrumb_wrapper li a{color:#ffffffa6;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_tittle_breadcrumb_wrapper li a:hover{color:#23c0e9;-webkit-transition:all 0.5s;-o-transition:all 0.5s;-ms-transition:all 0.5s;-moz-transition:all 0.5s;transition:all 0.5s;}
.jp_tittle_breadcrumb_wrapper li:first-child{margin-left:0;}
.jp_tittle_breadcrumb_wrapper li i{color:#23c0e9;position:relative;left:10px;}
.jp_tittle_breadcrumb_wrapper li:last-child{color:#23c0e9;}
/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/responsive2.css */
@media (max-width: 767px){
.jp_footer_candidate_wrapper2{margin-top:40px;}
.jp_tittle_main_wrapper{text-align:center;}
.jp_tittle_heading,.jp_tittle_breadcrumb_main_wrapper ul{display:inline-block;}
}
@media (max-width: 991px){
.mainmenu{display:none;}
.jp_footer_candidate_wrapper4,.jp_footer_candidate_wrapper3{margin-top:40px;}
.jp_bottom_footer_Wrapper{text-align:center;}
.jp_bottom_footer_left_cont{display:inline-block;float:left;width:100%;}
.jp_bottom_footer_right_cont{display:inline-block;float:left;width:100%;}
.jp_bottom_footer_right_cont ul{float:none;display:inline-block;margin-top:20px;}
.jp_bottom_top_scrollbar_wrapper{display:none;}
.jp_newsletter_field{margin-top:30px;}
.gc_logo{position:relative;top:-16px;}
}
@media (max-width: 1700px){
.mainmenu{right:-85px;}
}
@media (max-width: 1500px){
.mainmenu{right:-25px;}
}
@media (max-width: 1250px){
.full_width{width:100%;}
.mainmenu{right:0;}
.hidden-menu-bar .mainmenu,.float_left{float:none;}
.gc_logo{float:none;display:inline-block;}
.mainmenu ul li a{padding:0px 14px 47px 10px;}
.mainmenu:after{display:none;}
.mainmenu{text-align:center;}
}
@media (max-width: 1330px){
.mainmenu:after{right:2px;}
}
@media (max-width: 472px){
.jp_newsletter_field input{width:100%;float:none;}
.jp_newsletter_field button{float:none;margin-top:20px;}
}
/*! CSS Used from: Embedded */
*,*::before,*::after{box-sizing:border-box;}
*{-webkit-overflow-scrolling:touch;font-family:"Roboto", sans-serif!important;font-weight:normal;font-style:normal;}
h2,h3{margin-top:0;margin-bottom:0;}
p{margin-top:0;margin-bottom:0;}
ul{margin-top:0;margin-bottom:1rem;padding:0;list-style:none;}
b{font-weight:500;}
img{vertical-align:middle;border-style:none;}
.container:after,.container:before{content:" ";display:table;}
a{text-decoration:none;}
h2{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:#272c37;}
:focus{outline:-webkit-focus-ring-color auto 5px;}
@media (min-width: 768px){
h2{font-size:24px;line-height:34px;}
}
.container{width:100%;display:inline-block;padding-left:15px;padding-right:15px;}
@media (min-width: 768px){
.container{width:750px;display:block;margin-left:auto;margin-right:auto;}
}
@media (min-width: 992px){
.container{width:970px;}
}
@media (min-width: 1200px){
.container{width:1170px;}
}
.container{clear:both;}
h3{color:#272c37;}
a{color:#1179ef;text-decoration:none;cursor:pointer;}
a:hover,a:focus{color:#0a5dc9;outline:0;}
img{max-width:100%;height:auto;}
h2{font-size:20px;line-height:1.3;color:#272c37;font-weight:400;margin-top:56px;letter-spacing:1px;}
h2 b{font-weight:500;}
h3{font-size:16px;font-weight:500;margin:40px 0 0;line-height:1.5;letter-spacing:0.5px;color:#272c37;}
li{color:#51565e;font-size:14px;line-height:1.43;}
p{color:#51565e;font-size:14px;line-height:1.57;}
@media (max-width: 767px){
.container{padding-left:15px;padding-right:15px;}
}
@media (min-width: 992px) and (max-width: 1199px){
.container{width:100%;display:inline-block;padding:0 30px;}
}
@media (min-width: 992px){
p{font-size:16px;line-height:1.5;}
h2{font-size:32px;line-height:1.33;letter-spacing:1.5px;}
h3{font-size:20px;}
}
@media (min-width: 1200px){
h2{margin-top:64px;}
}
@media (max-width: 767px){
h3{margin-top:32px;}
}
/*! CSS Used from: Embedded */
.cds-7{margin:0;}
.cds-9{font-size:1rem;font-family:"Source Sans Pro", Arial, sans-serif;font-weight:400;line-height:1.5rem;letter-spacing:0;}
.cds-66{color:inherit;border:0;cursor:pointer;margin:0;display:inline-flex;outline:0;padding:0;position:relative;align-items:center;user-select:none;border-radius:0;vertical-align:middle;-moz-appearance:none;justify-content:center;text-decoration:none;background-color:transparent;-webkit-appearance:none;-webkit-tap-highlight-color:transparent;}
@media print{
.cds-66{-webkit-print-color-adjust:exact;}
}
.cds-37{color:rgba(0, 0, 0, 0.87);padding:6px 16px;min-width:64px;box-sizing:border-box;transition:background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,       box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,       border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;border-radius:4px;}
.cds-37:hover{text-decoration:none;background-color:rgba(0, 0, 0, 0.04);}
@media (hover: none){
.cds-37:hover{background-color:transparent;}
}
.cds-38{width:100%;display:inherit;align-items:inherit;justify-content:inherit;}
.cds-39{padding:6px 8px;}
.cds-48{box-shadow:none;}
.cds-48:hover{box-shadow:none;}
.cds-48:active{box-shadow:none;}
section{display:block;}
a{background:transparent;}
a:active,a:hover{outline:0;}
img{border:0;}
@media print{
*{text-shadow:none!important;color:#000!important;background:transparent!important;box-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
h2,p{orphans:3;widows:3;}
h2{page-break-after:avoid;}
}
h2{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
h2{margin-top:20px;margin-bottom:10px;}
h2{font-size:30px;}
p{margin:0 0 10px;}
ul{margin-top:0;margin-bottom:10px;}
p{font-size:14px;line-height:21px;font-family:OpenSans, Arial, sans-serif;}
*,:after,:before{-ms-box-sizing:border-box;-o-box-sizing:border-box;box-sizing:border-box;}
a{color:"#0062e4";}
a:focus,a:hover{color:"#004eb6";}
a:focus{outline:thin dotted;}
p{font-size:14px;line-height:21px;font-family:OpenSans, Arial, sans-serif;}
h2{margin:0;padding:0;}
p{margin-bottom:10px;}
ul{font-size:1em;outline:0;}
@media print{
a:link:after,a:visited:after{content:""!important;}
}
section{display:block;}
a{background-color:transparent;-webkit-text-decoration-skip:objects;}
a:active,a:hover{outline-width:0;}
img{border-style:none;}
*,:after,:before{box-sizing:inherit;}
h2{margin-top:0;margin-bottom:0.5rem;}
p{margin-top:0;margin-bottom:1rem;}
ul{margin-bottom:1rem;}
ul{margin-top:0;}
a{color:#0062e4;text-decoration:none;}
a:focus,a:hover{text-decoration:underline;}
a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
img{vertical-align:middle;}
[role="button"]{cursor:pointer;}
[role="button"],a{-ms-touch-action:manipulation;touch-action:manipulation;}
*{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
h2{font-family:OpenSans-light, Arial, sans-serif;}
h2{font-size:2.125rem;line-height:2.625rem;}
@media print{
*{overflow:visible!important;}
}
h2,p{max-width:100%;}
._cdrzxu{position:relative;width:90px;padding-bottom:33.33333333333333%;height:0px;}
@media (max-width: 824px){
._cdrzxu{max-width:95vw;}
}
@media (max-width: 608px){
._cdrzxu{max-width:90vw;}
}
.css-1wg4got{background-color:#f9f7ef;}
.css-4c78qm{color:#1f1f1f;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;margin:0 auto;padding-top:6rem;padding-bottom:6rem;max-width:975px;width:100%;}
@media screen and (max-width: 992px){
.css-4c78qm{-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;padding-left:1rem;padding-right:1rem;padding-top:4rem;padding-bottom:4rem;}
}
.css-17wkacp{width:50%;margin-left:35px;margin-bottom:35px;line-height:44px;color:#1f1f1f;}
@media screen and (max-width: 992px){
.css-17wkacp{margin:0 auto;max-width:575px;width:100%;}
}
.css-102hgme{color:#1f1f1f;letter-spacing:0;font-size:1rem;line-height:1.5rem;font-family:"Source Sans Pro", Arial, sans-serif;font-weight:400;font-family:Source Sans Pro, Arial, sans-serif;font-weight:600;font-size:44px;margin-bottom:35px;line-height:52px;color:#1f1f1f;}
@media screen and (max-width: 767px){
.css-102hgme{font-weight:400;font-size:36px;line-height:48px;}
}
.css-745l8r{color:#1f1f1f;letter-spacing:0;font-size:1rem;line-height:1.5rem;font-family:"Source Sans Pro", Arial, sans-serif;font-weight:400;font-family:Source Sans Pro, Arial, sans-serif;line-height:28px;font-size:20px;color:#1f1f1f;margin-bottom:1rem;max-width:90%;}
@media screen and (max-width: 479px){
.css-745l8r{max-width:100%;font-size:16px;line-height:24px;margin-bottom:30px;}
}
.css-d29qp5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}
@media screen and (max-width: 479px){
.css-d29qp5{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
}
.css-l8m146{border-radius:4px;text-transform:none;text-align:center;max-width:320px;min-width:unset;-webkit-transition:none;transition:none;color:#0056d2;background-color:#fff;box-shadow:inset 0 0 0 1px #0056d2;font-size:1rem;line-height:1.5rem;font-family:"Source Sans Pro", Arial, sans-serif;font-weight:700;letter-spacing:-0.1px;padding:12px 32px;padding:10px 20px;margin-right:15px;background-color:transparent;}
.css-l8m146:hover{color:#00419e;box-shadow:inset 0 0 0 1px #00419e;background-color:#f3f8ff;}
.css-l8m146:active{color:#382d8b;box-shadow:inset 0 0 0 1px #382d8b;background-color:#f7f6fe;}
.css-l8m146:active.cds-button-disableElevation::after{content:unset;}
.css-l8m146 .cds-button-label{text-transform:none;}
.css-l8m146:focus{outline:none;}
@media screen and (max-width: 479px){
.css-l8m146{max-width:none;margin-right:0;margin-bottom:25px;}
}
.css-bjriq3{width:50%;padding-left:40px;padding-right:40px;}
@media screen and (max-width: 992px){
.css-bjriq3{margin:0 auto;margin-bottom:30px;max-width:486px;width:100%;padding-left:0;padding-right:0;}
}
.css-tttaxl{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:20px;padding-top:6px;text-align:center;}
@media screen and (max-width: 767px){
.css-tttaxl{display:block;margin:0 auto 20px;max-width:486px;width:100%;}
}
.css-1wpet1o{color:#1f1f1f;letter-spacing:0;font-size:1rem;line-height:1.5rem;font-family:"Source Sans Pro", Arial, sans-serif;font-weight:400;font-family:Source Sans Pro, Arial, sans-serif;font-weight:600;font-size:20px;line-height:28px;color:#382d8b;}
@media screen and (max-width: 480px){
.css-1wpet1o{font-size:20;display:inline;line-height:28px;}
}
.css-18zzja8{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex-flow:row wrap;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap;padding:0;width:100%;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;-webkit-justify-content:space-evenly;justify-content:space-evenly;}
@media screen and (max-width: 767px){
.css-18zzja8{max-width:486px;width:100%;}
}
.css-9c3b03{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;margin-right:0.5rem;margin-bottom:25px;}
@media (max-width: 480px){
.css-9c3b03{margin-right:0.8rem;}
}
.css-jbpof6{display:block;margin:0px auto;}
/*! CSS Used from: Embedded */
*,*::before,*::after{box-sizing:border-box;}
*{-webkit-overflow-scrolling:touch;font-family:"Roboto", sans-serif!important;font-weight:normal;font-style:normal;}
h2,h3{margin-top:0;margin-bottom:0;}
p{margin-top:0;margin-bottom:0;}
ul{margin-top:0;margin-bottom:1rem;padding:0;list-style:none;}
b{font-weight:500;}
.container:after,.container:before{content:" ";display:table;}
a{text-decoration:none;}
h2{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:#272c37;}
:focus{outline:-webkit-focus-ring-color auto 5px;}
.btn{outline:0;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
button{font:inherit;margin:0;}
button{text-rendering:auto;color:initial;letter-spacing:normal;word-spacing:normal;text-transform:none;text-indent:0px;text-shadow:none;display:inline-block;text-align:start;margin:0em;}
button{-webkit-writing-mode:horizontal-tb;}
@media (min-width: 768px){
h2{font-size:24px;line-height:34px;}
}
.container{width:100%;display:inline-block;padding-left:15px;padding-right:15px;}
@media (min-width: 768px){
.container{width:750px;display:block;margin-left:auto;margin-right:auto;}
}
@media (min-width: 992px){
.container{width:970px;}
}
@media (min-width: 1200px){
.container{width:1170px;}
}
.strike-line{text-decoration:line-through!important;}
.container{clear:both;}
h3{color:#272c37;}
a{color:#1179ef;text-decoration:none;cursor:pointer;}
a:hover,a:focus{color:#0a5dc9;outline:0;}
h2{font-size:20px;line-height:1.3;color:#272c37;font-weight:400;margin-top:56px;letter-spacing:1px;}
h2 b{font-weight:500;}
h3{font-size:16px;font-weight:500;margin:40px 0 0;line-height:1.5;letter-spacing:0.5px;color:#272c37;}
li{color:#51565e;font-size:14px;line-height:1.43;}
.btn{display:inline-block;width:100%;line-height:38px;border-radius:2px;font-size:14px;font-weight:500;margin-top:48px;color:#fff;text-align:center;background:#ff8000;max-width:246px;display:block;margin-left:auto;margin-right:auto;text-transform:uppercase;}
.btn:hover{background:#ea6400;}
.btn:focus{color:#fff;}
p{color:#51565e;font-size:14px;line-height:1.57;}
@media (max-width: 767px){
.container{padding-left:15px;padding-right:15px;}
}
@media (min-width: 992px) and (max-width: 1199px){
.container{width:100%;display:inline-block;padding:0 30px;}
}
@media (min-width: 992px){
.btn{width:100%;}
p{font-size:16px;line-height:1.5;}
h2{font-size:32px;line-height:1.33;letter-spacing:1.5px;}
h3{font-size:20px;}
}
@media (min-width: 1200px){
h2{margin-top:64px;}
}
@media (max-width: 767px){
h3{margin-top:32px;}
}
.trining-options{background:#ebf1f8;padding-bottom:56px;display:inline-block;width:100%;}
.trining-options h2{padding-left:0px;}
.trining-options .col-md-4{background:#fff;box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.1);border-radius:4px;padding:0 15px;display:inline-block;width:100%;margin-top:24px;padding-bottom:32px;}
.trining-options .col-md-4 p{position:relative;}
.trining-options h3{font-size:18px;}
.trining-options h3.cp-type{text-align:center;text-transform:uppercase;font-size:20px;}
.trining-options .strike-line,.trining-options .strike-line + span{font-size:12px;color:#6c7480;font-weight:500;}
.trining-options .strike-line + span{color:#fda954;}
.trining-options .tr-head{border-bottom:1px solid #dde6ed;margin-top:24px;min-height:50px;padding-bottom:0px;display:table;width:100%;position:relative;}
.trining-options .tr-head .type-info{display:table-cell;width:50%;text-align:left;padding-bottom:10px;}
.trining-options .tr-head .price{font-size:18px;font-weight:400;color:#272c37;margin-bottom:10px;display:block;line-height:normal;}
.trining-options .tr-head.no-emi p.type-info{text-align:center;}
.trining-options .list-info{margin-top:0px;padding-top:16px;margin-bottom:0;word-break:break-word;}
.trining-options .list-info li{padding-left:34px;font-size:14px;color:#51565e;margin-bottom:20px;line-height:1.43;position:relative;}
.trining-options .list-info li:last-child{margin-bottom:0;margin-top:10px;}
.trining-options .list-info li:before{content:"";width:26px;height:26px;display:inline-block;background:url(https://www.simplilearn.com/ice9/assets/course_sprite_7.svgz)       no-repeat;background-position:-362px -81px;position:absolute;left:0;}
.trining-options .btn{background:#ff8000;margin-top:32px;}
.trining-options .btn:hover,.trining-options .btn:active,.trining-options .btn:focus{color:#fff;}
.trining-options .btn:hover{background:#ea6400;}
.trining-options .no-bar .tr-head{min-height:50px;}
.trining-options .no-bar .tr-head p.type-info .strike-line,.trining-options .no-bar .tr-head p.type-info .strike-line + span{display:none;}
.trining-options .no-bar .tr-head p.type-info .price{margin-bottom:0;}
@media (min-width: 992px){
.trining-options{padding-bottom:64px;}
.trining-options h2{padding-left:0;}
.trining-options .option-list{display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-webkit-justify-content:center;-moz-box-pack:center;-ms-flex-pack:center;justify-content:center;}
.trining-options .col-md-4{width:calc(33.333% - 20px);display:inline-block;padding-bottom:88px;position:relative;margin-right:30px;margin-top:40px;}
.trining-options .col-md-4 .btn{line-height:40px;max-width:200px;}
.trining-options .col-md-4 .opt-btn{position:absolute;bottom:32px;left:0;right:0;}
.trining-options h3.cp-type{margin-top:32px;}
.trining-options .tr-head{margin-top:24px;min-height:72px;}
.trining-options .strike-line + span{color:#27ba44;}
.trining-options .list-info li{margin-bottom:20px;}
}
@media (min-width: 1200px){
.trining-options .col-md-4{padding-left:20px;padding-right:20px;}
}
@media (min-width: 768px) and (max-width: 991px){
.trining-options .list-info{margin-left:135px;}
}
/*! CSS Used fontfaces */
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/rBHvpRWBkgyW99dXT88n7yEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/NX1NravqaXESu9fFv7KuqiEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/SKK6Nusyv8QPNMtI4j9J2yEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/gFXtEMCp1m_YzxsBpKl68iEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;}
@font-face{font-family:"Roboto";src:url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff2)         format("woff2"),       url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff)         format("woff");font-weight:400;font-display:swap;}
@font-face{font-family:"Roboto";src:url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff2)         format("woff2"),       url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff)         format("woff");font-weight:500;font-display:swap;}
@font-face{font-family:"Roboto";src:url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff2)         format("woff2"),       url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Regular_v1.woff)         format("woff");font-weight:400;font-display:swap;}
@font-face{font-family:"Roboto";src:url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff2)         format("woff2"),       url(https://www.simplilearn.com/ice9/assets/fonts/Roboto-Medium_v1.woff)         format("woff");font-weight:500;font-display:swap;}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:300;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.svg#SourceSansPro)         format("svg");}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:400;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.svg#SourceSansPro)         format("svg");}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:600;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.svg#SourceSansPro)         format("svg");}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:bold;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.svg#SourceSansPro)         format("svg");}
@font-face{font-family:"OpenSans";font-weight:normal;font-style:normal;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-regular.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-regular.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.svg#OpenSans-Regular)         format("svg");}
@font-face{font-family:"OpenSans";font-weight:bold;font-style:normal;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.svg#OpenSans-Bold)         format("svg");}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:300;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.svg#SourceSansPro)         format("svg");}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:400;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.svg#SourceSansPro)         format("svg");}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:600;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.svg#SourceSansPro)         format("svg");}
@font-face{font-family:"Source Sans Pro";font-style:normal;font-weight:bold;font-display:swap;src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.eot);src:url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.eot?#iefix)         format("embedded-opentype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.woff2)         format("woff2"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.woff)         format("woff"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.ttf)         format("truetype"),       url(https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.svg#SourceSansPro)         format("svg");}





/*! CSS Used from: Embedded */
div,h3,p{margin:0;padding:0;border:0;outline:0;}
*{box-sizing:border-box;}
h3{font-weight:500;}
.container{width:90%;margin:0 auto;}
@media (min-width: 576px){
.container{max-width:540px;padding-right:15px;padding-left:15px;}
}
@media (min-width: 768px){
.container{max-width:720px;padding-right:15px;padding-left:15px;}
}
@media (min-width: 992px){
.container{max-width:960px;padding-right:15px;padding-left:15px;}
}
@media (min-width: 1200px){
.container{max-width:1220px;padding-right:15px;padding-left:15px;}
}
button{background:none;color:inherit;border:none;padding:0;font:inherit;cursor:pointer;outline:inherit;}
.hide{display:none;}
/*! CSS Used from: https://d1vwxdpzbgdqj.cloudfront.net/packs/css/Main_CSS-bfd703fb.chunk.css */
button{-webkit-appearance:none;-moz-appearance:none;appearance:none;}
/*! CSS Used from: https://d1vwxdpzbgdqj.cloudfront.net/packs/css/Program_Page_CSS276-451beec8.chunk.css */
.hide{display:none;}
::-webkit-scrollbar{display:none;}
.gl-accordion .acc-card{border:1px solid rgba(0,0,0,.16)!important;}
.gl-accordion-new{margin-top:0;padding-top:0;}
.gl-accordion-new .acc-body{border:none;}
.gl-accordion{margin-top:0!important;}
.acc-card{border:none;box-shadow:0 8px 40px 0 rgba(0,0,0,.1)!important;margin:16px 4px 8px;border-radius:2px;background-color:#fff;overflow:hidden;}
.acc-card .acc-title{padding:16px 40px 16px 16px;color:#000;font-weight:500;border-radius:4px;border:none;}
.acc-card .acc-title,.acc-card .acc-title:hover{background-color:#fff;}
.acc-card .acc-title:after{content:"+"!important;transform:none!important;border-style:none!important;top:20px!important;right:30px!important;width:30px;height:30px;background-color:#f8f8f8;border-radius:50%;text-align:center;display:flex;align-items:center;justify-content:center;font-size:20px;border:none;}
@media (max-width:557px){
.acc-card .acc-title:after{right:16px!important;}
}
@media (min-width:991px){
.acc-card .acc-title{font-size:20px;padding:20px;}
.gl-accordion{width:80%;margin-left:auto;margin-right:auto;}
}
@media (min-width:1350px){
.gl-accordion{width:60%;}
}
.acc-view-more-btn{border-radius:4px;border:1px solid #196ae5;margin:0 4px;display:flex;justify-content:center;align-items:center;padding:1rem;font-size:16px;font-weight:500;line-height:1;letter-spacing:.5px;color:#196ae5;width:97%;}
@media (min-width:768px){
.acc-view-more-btn{width:99%;}
}
.course-outline-wrapper{background-color:rgba(25,106,229,.04);}
@media (min-width:768px){
.course-outline-wrapper .gl-accordion{width:100%;}
}
.course-outline{padding:1rem 0;}
.course-outline__heading{font-size:18px;font-weight:600;line-height:1.5;color:rgba(0,0,0,.87);}
@media (min-width:769px){
.course-outline__heading{padding:2rem 0 0;font-size:34px;font-weight:600;line-height:1.41;}
}
@media (max-width:767px){
.course-outline .gl-accordion .acc-card .acc-title{padding:22px 16px;}
}
.course-outline .acc-content{font-size:14px;color:rgba(0,0,0,.6);padding:1rem;}
.course-outline .acc-view-more-btn{border-radius:4px;border:1px solid #196ae5;margin:0 4px;display:flex;justify-content:center;align-items:center;padding:1rem;font-size:16px;font-weight:500;line-height:1;letter-spacing:.5px;color:#196ae5;width:97%;}
@media (min-width:768px){
.course-outline .acc-view-more-btn{width:99%;}
}
/*! CSS Used from: https://d1vwxdpzbgdqj.cloudfront.net/packs/css/Layout_CSS-fd8c9033.chunk.css */
.fadeIn{-webkit-animation-name:fadeIn;animation-name:fadeIn;}
.fadeIn{-webkit-animation-duration:.7s;animation-duration:.7s;}
.gl-accordion{margin-top:1rem;padding-top:1rem;}
.gl-accordion .acc-card{box-sizing:border-box;background-color:#fff;position:relative;border-left:1px solid #ccc;border-right:1px solid #ccc;}
.gl-accordion .acc-card:first-child{border-top:1px solid #ccc;}
.gl-accordion .acc-card .acc-title{cursor:pointer;margin-bottom:0;border-bottom:1px solid #ccc;padding:22px 32px;display:block;font-size:15px;position:relative;transition:background .3s ease;}
.gl-accordion .acc-card .acc-title:hover{background:hsla(0,0%,93.7%,.53);}
.gl-accordion .acc-card .acc-title:after{content:"";position:absolute;top:27px;right:16px;opacity:.8;border-style:solid;border-width:4px 4px 0 0;display:inline-block;height:12px;width:12px;transform:rotate(135deg);transition:all .5s;}
.gl-accordion .acc-card .acc-body{height:0;overflow:hidden;transition:height .3s ease;}
.gl-accordion .acc-card .acc-body .acc-content{overflow:visible;display:block;}
.gl-accordion .acc-card .acc-content{overflow:hidden;font-weight:400;display:none;}
/*! CSS Used keyframes */
@-webkit-keyframes fadeIn{0%{opacity:.001;}to{opacity:.999;}}
@keyframes fadeIn{0%{opacity:.001;}to{opacity:.999;}}





/*! CSS Used from: Embedded */
div,h3{margin:0;padding:0;border:0;outline:0;}
*{box-sizing:border-box;}
h3{font-weight:500;}
.container{width:90%;margin:0 auto;}
@media (min-width: 576px){
.container{max-width:540px;padding-right:15px;padding-left:15px;}
}
@media (min-width: 768px){
.container{max-width:720px;padding-right:15px;padding-left:15px;}
}
@media (min-width: 992px){
.container{max-width:960px;padding-right:15px;padding-left:15px;}
}
@media (min-width: 1200px){
.container{max-width:1220px;padding-right:15px;padding-left:15px;}
}
@media (min-width: 769px){
.skills-section-wrapper{background-color:#fafafa;}
}
@media (min-width: 769px){
.skills-section{padding-top:4rem;padding-bottom:4rem;}
}
.skills-section__heading{font-size:18px;font-weight:600;line-height:1.5;color:rgba(0,0,0,0.87);}
@media (min-width: 769px){
.skills-section__heading{font-size:34px;line-height:1.41;}
}
.skills-concepts{display:flex;flex-wrap:wrap;flex-direction:column;}
@media (min-width: 769px){
.skills-concepts{flex-direction:row;}
}
.skills-concepts__item{display:flex;align-items:flex-start;padding-top:10px;}
.skills-concepts__item:nth-child(2n){padding-right:0;}
@media (min-width: 768px){
.skills-concepts__item:nth-child(2n){padding-right:1rem;}
}
.skills-concepts__item:nth-child(2n + 1){padding-right:1rem;}
.skills-concepts__item:last-child{padding-right:0;}
.skills-concepts__img{width:24px;height:24px;}
.skills-concepts__concept{padding-left:5px;font-size:14px;line-height:1.71;letter-spacing:0.15px;color:#616161;}
@media (min-width: 769px){
.skills-concepts__concept{font-size:16px;line-height:1.5;letter-spacing:0.5px;color:rgba(0,0,0,0.6);}
}
/*! CSS Used from: https://d1vwxdpzbgdqj.cloudfront.net/packs/css/Program_Page_CSS276-451beec8.chunk.css */
::-webkit-scrollbar{display:none;}






