
.row{
  justify-content: space-around;
}

.image1{
    display: flex;
    flex-direction: column;
    padding:5px;
    border:1px solid lightgray;
    background-color: whitesmoke;
    height: fit-content;
    align-items: center;
    border-radius: 7px;
    margin-left: 50px;
    margin-right: 20px;
    padding-left: 0px;
    padding-right: 0px;
    width: 292px!important;
}

.image1 > img{
    height: 200px;
    border-radius: 5px;
    /* object-fit: contain; */
    width: 100%!important;
    margin-bottom: 3px;

}

.image2{
     display: flex;
    flex-direction: column;
    padding: 17px;
    border:1px solid lightgray;
    background-color: whitesmoke;
    height: fit-content;
    align-items: center;
    border-radius: 7px;
    padding-left: 0px;
    padding-right: 0px;
    width: 292px!important;
     margin-left: 20px;
    margin-right: 30px;
    padding-bottom: 0;
    padding-top: 10px;
}

.image2 > img {
height: 200px;
border-radius: 5px;
/* object-fit: contain; */
width: 100%!important;
margin-bottom: 3px;


}

.image3{
     display: flex;
    flex-direction: column;
    padding: 5px;
    border:1px solid lightgray;
    background-color: whitesmoke;
    height: fit-content;
    align-items: center;
    border-radius: 7px;
    padding-left: 0px;
    padding-right: 0px;
    width: 292px!important;
     margin-left: 10px;
    margin-right: 10px;
}

.image3 > img {
height: 200px;
border-radius: 5px;
/* object-fit: contain; */
width: 100%!important;
margin-bottom: 3px;


}






























/* .slick1{
    background-color: whitesmoke;
    border: 1px solid lightgray;
   
    margin-left: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-left: 20px;


   
}

.slick2{
    background-color: whitesmoke;
    border: 1px solid lightgray;
    
    margin-left: 20px;
    padding: 10px;

   
}

.slick3{
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    border: 1px solid lightgray;
   
    margin-left: 20px;
    padding: 10px;

   
}

.slick-prev:before, .slick-next:before {
    color: transparent !important;
    font-size: 0 !important;
    line-height: 0 !important;
  }
  
  .slick-prev:before {
    content: "\2190" !important;
    font-size: 20px !important;
    line-height: 1 !important;
    color: #fff !important;
  }
  
  .slick-next:before {
    content: "\2192" !important;
    font-size: 20px !important;
    line-height: 1 !important;
    color: #fff !important;
  }
  
  /* position the arrows on top of the images */
  /* .slick-prev, .slick-next {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .slick-prev {
    left: 20px;
  }
  
  .slick-next {
    right: 20px;
  } 
   */