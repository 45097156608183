/*! CSS Used from: Embedded */
.cds-33 {
    margin: 0;
}

.cds-35 {
    font-size: 1rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0;
}

.cds-61 {
    display: inline;
}

/*! CSS Used from: Embedded */
.cds-30 {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

@media print {
    .cds-30 {
        -webkit-print-color-adjust: exact;
    }
}

/*! CSS Used from: Embedded */
.cds-1 {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
}

.cds-1:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
    .cds-1:hover {
        background-color: transparent;
    }
}

.cds-2 {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.cds-3 {
    padding: 6px 8px;
}

.cds-12 {
    box-shadow: none;
}

.cds-12:hover {
    box-shadow: none;
}

.cds-12:active {
    box-shadow: none;
}

/*! CSS Used from: Embedded */
.cds-70 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.cds-71 {
    margin: 0;
    box-sizing: border-box;
}

.cds-78 {
    align-items: center;
}

/*! CSS Used from: Embedded */
.rc-A11yScreenReaderOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    text-transform: none;
}

.horizontal-box {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    text-align: center;
}

.headline-2-text,
.headline-3-text,
.headline-4-text {
    font-weight: 400;
}

.headline-2-text,
.headline-3-text,
.headline-4-text {
    margin-bottom: 10px;
}

.headline-2-text {
    font-family: OpenSans-Light, Arial, sans-serif;
}

.headline-3-text,
.headline-4-text {
    font-family: OpenSans, Arial, sans-serif;
}

.headline-4-text {
    font-size: 24px;
    line-height: 30px;
}

.headline-3-text {
    font-size: 22px;
    line-height: 24px;
}

.headline-2-text {
    font-size: 20px;
    line-height: 24px;
}

p {
    font-size: 14px;
    line-height: 21px;
    font-family: OpenSans, Arial, sans-serif;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.83);
}

[class*="card-"] {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

[class*="card-"]:hover {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.horizontal-box.align-items-spacebetween {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

button.button-link {
    background: transparent none repeat 0 0 scroll;
    color: rgba(0, 0, 0, 0.87);
    border: 0;
    font-size: 1em;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
    min-width: 0;
}

button.button-link:disabled {
    background: transparent none repeat 0 0 scroll;
    color: rgba(0, 0, 0, 0.26);
}

button.button-link {
    color: #0062e4;
    text-decoration: none;
    cursor: pointer;
}

button.button-link:hover {
    text-decoration: underline;
    color: #004eb6;
}

h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

.caption-text,
.label-text {
    font-size: 12px;
    line-height: 18px;
    font-family: OpenSans, Arial, sans-serif;
}

.label-text {
    font-weight: 700;
    text-transform: uppercase;
}

p {
    margin-bottom: 10px;
}

ul {
    font-size: 1em;
    outline: 0;
}

@media print {

    a:link:after,
    a:visited:after {
        content: "" !important;
    }
}

.screenreader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

main,
nav {
    display: block;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
    outline-width: 0;
}

strong {
    font-weight: inherit;
    font-weight: bolder;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

hr {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

button,
input {
    font: inherit;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

[type="submit"],
button,
html [type="button"] {
    -webkit-appearance: button;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

h1,
h2,
h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    margin-bottom: 1rem;
}

ul {
    margin-top: 0;
}

a {
    color: #0062e4;
    text-decoration: none;
}

a:focus,
a:hover {
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: none;
}

img {
    vertical-align: middle;
}

[role="button"] {
    cursor: pointer;
}

[role="button"],
a,
button,
input {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input {
    line-height: inherit;
}

@media print {
    * {
        overflow: visible !important;
    }
}

.max-text-width {
    max-width: 720px;
}

.text-primary {
    color: #363b42;
}

.text-primary-dark {
    color: #fff;
}

.text-secondary {
    color: #6e767f;
}

.bg-white {
    background-color: #fff;
}

.bg-light-blue {
    background-color: #f5f7fa;
}

.color-white {
    color: #fff;
}

.color-black {
    color: #000;
}

.border-a {
    border: 1px solid #e3e4e5;
}

.border-bottom {
    border-bottom: 1px solid #e3e4e5;
}

.border-top {
    border-top: 1px solid #e3e4e5;
}

.list-style-none {
    list-style: none;
}

.pos-relative {
    position: relative;
}

h1,
h2,
h3,
p {
    max-width: 100%;
}

.font-md {
    font-size: 1rem;
}

.font-sm {
    font-size: 0.9rem;
}

.font-xs {
    font-size: 0.8rem;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.overflow-hidden {
    overflow: hidden;
}

.w-100 {
    width: 100% !important;
}

.m-x-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.m-a-0 {
    margin: 0 !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-x-0 {
    margin-left: 0 !important;
}

.m-x-0 {
    margin-right: 0 !important;
}

.m-r-1s {
    margin-right: 0.5rem !important;
}

.m-b-1s {
    margin-bottom: 0.5rem !important;
}

.m-l-1s,
.m-x-1s {
    margin-left: 0.5rem !important;
}

.m-x-1s {
    margin-right: 0.5rem !important;
}

.m-t-1 {
    margin-top: 1rem !important;
}

.m-r-1 {
    margin-right: 1rem !important;
}

.m-b-1 {
    margin-bottom: 1rem !important;
}

.m-l-1 {
    margin-left: 1rem !important;
}

.m-y-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.m-t-2 {
    margin-top: 1.5rem !important;
}

.m-r-2 {
    margin-right: 1.5rem !important;
}

.m-b-2 {
    margin-bottom: 1.5rem !important;
}

.m-t-3 {
    margin-top: 3rem !important;
}

.m-b-3 {
    margin-bottom: 3rem !important;
}

.p-a-0 {
    padding: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-x-0 {
    padding-left: 0 !important;
}

.p-x-0 {
    padding-right: 0 !important;
}

.p-t-1s {
    padding-top: 0.5rem !important;
}

.p-b-1s {
    padding-bottom: 0.5rem !important;
}

.p-l-1s {
    padding-left: 0.5rem !important;
}

.p-y-1s {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.p-a-1 {
    padding: 1rem !important;
}

.p-t-1 {
    padding-top: 1rem !important;
}

.p-r-1 {
    padding-right: 1rem !important;
}

.p-b-1 {
    padding-bottom: 1rem !important;
}

.p-l-1 {
    padding-left: 1rem !important;
}

.p-y-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-t-2 {
    padding-top: 1.5rem !important;
}

.p-r-2 {
    padding-right: 1.5rem !important;
}

.p-b-2 {
    padding-bottom: 1.5rem !important;
}

.p-l-2,
.p-x-2 {
    padding-left: 1.5rem !important;
}

.p-x-2 {
    padding-right: 1.5rem !important;
}

.p-y-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.p-t-3 {
    padding-top: 3rem !important;
}

.p-b-3 {
    padding-bottom: 3rem !important;
}

.text-xs-left {
    text-align: left !important;
}

.text-xs-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.font-weight-bold {
    font-weight: 700;
}

@media (min-width: 576px) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
    font-family: OpenSans-light, Arial, sans-serif;
}

h1 {
    font-size: 3rem;
    line-height: 3.75rem;
}

h2 {
    font-size: 2.125rem;
    line-height: 2.625rem;
}

h3 {
    font-size: 1.5rem;
    line-height: 1.875rem;
}

.bold {
    font-family: OpenSans, Arial, sans-serif;
    font-weight: 700;
}

.slick-slider {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list,
.slick-slider {
    position: relative;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 4px;
}

.slick-list:focus {
    outline: none;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-slide img {
    display: block;
}

.slick-initialized .slick-slide {
    display: block;
}

p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
}

.carousel-dots {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}

.carousel-dots li {
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
}

.carousel-dots button {
    position: relative;
    visibility: hidden;
    width: 100%;
}

.carousel-dots button:before {
    color: #9b9b9b;
    content: "○";
    visibility: visible;
    position: absolute;
    left: 2px;
    display: block;
    font-size: 16px;
    width: 14px;
}

.carousel-dots .slick-active button:before {
    content: "●";
}

.toggle-button-wrapper button {
    color: #2a73cc;
    background: transparent;
    border: none;
    padding: 0;
}

.toggle-button-wrapper button :focus,
.toggle-button-wrapper button :hover {
    color: #014c8c;
    text-decoration: underline;
    background: transparent;
    border: none;
}

@media (max-width: 767px) {
    .carousel-dots {
        display: none !important;
    }
}

p {
    line-height: 21px;
}

.rc-TogglableContent.collapsed .content {
    overflow: hidden;
}

.rc-TogglableContent .content {
    position: relative;
}

.rc-TogglableContent .toggle-button-wrapper {
    margin-top: 5px;
}

.rc-TogglableContent .toggle-button-wrapper button {
    border: none;
    color: #2a73cc;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.rc-UserTestimonial .normal-content-container {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    background-color: #fff;
    min-height: 120px;
    -webkit-box-shadow: 1px 1px 4px #bdbdbd;
    box-shadow: 1px 1px 4px #bdbdbd;
}

.rc-UserTestimonial .normal-content-container .profile-image {
    position: relative;
    clip-path: inset(0 -5px 0 0);
    overflow: hidden;
}

.rc-UserTestimonial .normal-content-container .profile-image:after {
    position: absolute;
    content: "";
    top: 0;
    right: -40px;
    border-top: 380px solid #fff;
    border-left: 80px solid transparent;
    width: 0;
}

.rc-UserTestimonial .normal-content-container .profile-image img {
    position: absolute;
}

.rc-UserTestimonial .normal-content-container .caption-container {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 20px 8px;
    z-index: 1;
}

.rc-UserTestimonial .normal-content-container .caption-container .user-name {
    width: 100%;
    text-align: right;
    font-style: italic;
    margin: 0 12px 0 0;
}

.rc-BannerInstructorInfo {
    padding-top: 8px;
    height: auto;
}

@media (max-width: 607px) {
    .rc-BannerInstructorInfo__seo-experiment {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.rc-BannerInstructorInfo a {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
}

.rc-BannerInstructorInfo a:hover {
    color: #fff;
    text-decoration: none;
}

.banner-title {
    font-family: OpenSans-Bold, Arial, sans-serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 24px;
}

@media (max-width: 607px) {
    .banner-title {
        font-size: 24px;
        line-height: 32px;
    }
}

.XDPRating {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-flow: wrap;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
}

.XDPRating .number-rating-expertise {
    font-size: 14px;
    line-height: 19px;
    margin-left: 6px;
    margin-right: 6px;
}

.XDPRating .number-rating:hover {
    text-decoration: none;
}

.XDPRating .ratings-count-expertise-style {
    font-size: 14px;
    line-height: 19px;
    margin-right: 6px;
}

.sdpRatingLink {
    margin-bottom: 0.5rem;
}

.rc-InstructorList .instructor-wrapper {
    font-size: 14px;
    line-height: 19px;
    -webkit-box-align: start;
    -moz-box-align: start;
    -o-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: start;
    align-items: start;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media (min-width: 824px) {
    .rc-InstructorList .instructor-wrapper {
        -webkit-box-align: center;
        -moz-box-align: center;
        -o-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -o-box-orient: horizontal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.rc-InstructorList a:focus,
.rc-InstructorList a:hover {
    text-decoration: none;
    color: inherit;
}

.rc-InstructorList a:focus h3,
.rc-InstructorList a:hover h3 {
    text-decoration: underline;
    color: #014c8c;
}

.rc-InstructorList .avatar-container {
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-box;
    display: inline-flex;
    height: 73px;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 73px;
}

@media (min-width: 824px) {
    .rc-InstructorList .avatar-container {
        height: 112px;
        margin-bottom: 0;
        margin-right: 2rem;
        width: 112px;
    }
}

.rc-InstructorList .avatar-container img {
    width: 100%;
}

.rc-InstructorList .instructor-expertise {
    font-size: 12px;
    line-height: 17px;
    margin-top: 10px;
}

.rc-InstructorList .instructor-expertise img {
    margin-right: 8px;
}

.rc-InstructorList .instructor-name {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
}

.rc-InstructorList .instructor-title {
    display: block;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
}

.rc-InstructorList .instructor-department {
    color: #936d48;
    display: block;
    font-size: 12px;
    line-height: 17px;
}

.rc-Partner {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: start;
    -moz-box-align: start;
    -o-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: start;
    align-items: start;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.rc-Partner a:focus,
.rc-Partner a:hover {
    text-decoration: none;
    color: inherit;
}

.rc-Partner a:focus h3,
.rc-Partner a:hover h3 {
    text-decoration: underline;
    color: #014c8c;
}

@media (min-width: 824px) {
    .rc-Partner {
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -o-box-orient: horizontal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: start;
        -moz-box-align: start;
        -o-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: start;
        align-items: start;
    }
}

.rc-Partner__title {
    font-size: 18px;
}

.rc-Partner .avatar-container {
    margin-bottom: 1rem;
    margin-top: 5px;
}

@media (min-width: 824px) {
    .rc-Partner .avatar-container {
        margin-right: 2rem;
    }
}

.rc-Carousel .slick-slider {
    margin: auto;
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.rc-Carousel .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0;
}

.rc-Carousel .slick-slider .slick-list,
.rc-Carousel .slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.rc-Carousel .slick-track {
    width: 5000px;
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.rc-Carousel .slick-track:after,
.rc-Carousel .slick-track:before {
    display: table;
    content: "";
}

.rc-Carousel .slick-track:after {
    clear: both;
}

.rc-Carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.rc-Carousel .slick-slide img {
    display: block;
}

.rc-Carousel .slick-initialized .slick-slide {
    display: block;
}

/*! CSS Used from: https://d3njjcbhbojbot.cloudfront.net/webapps/r2-builds/br/xdp/allStyles.e84f1b633aa6cf53f9f9.css ; media=all */
@media all {
    ul {
        margin-bottom: 1rem;
    }

    a:not([href]):not([tabindex]),
    a:not([href]):not([tabindex]):focus,
    a:not([href]):not([tabindex]):hover {
        color: inherit;
        text-decoration: none;
    }

    h1,
    h2,
    h3 {
        font-family: OpenSans-Light, OpenSans, Arial, sans-serif;
    }

    .bold,
    strong {
        font-family: OpenSans-Bold, OpenSans, Arial, sans-serif;
    }

    ::placeholder {
        color: #666;
    }

    .max-text-width-xl {
        max-width: 1040px;
    }

    .link-no-style,
    .link-no-style:hover {
        text-decoration: none;
        color: inherit;
    }

    .border-a {
        border: 1px solid #e1e1e1;
    }

    .border-bottom {
        border-bottom: 1px solid #e1e1e1;
    }

    .border-top {
        border-top: 1px solid #e1e1e1;
    }

    .pos-absolute {
        position: absolute;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .m-x-0 {
        margin-left: 0 !important;
    }

    .m-l-1s,
    .m-x-1s {
        margin-left: 0.5rem !important;
    }

    .m-l-1 {
        margin-left: 1rem !important;
    }

    .m-b-5 {
        margin-bottom: 5rem !important;
    }

    .p-x-0 {
        padding-left: 0 !important;
    }

    .p-l-1s {
        padding-left: 0.5rem !important;
    }

    .p-l-1 {
        padding-left: 1rem !important;
    }

    .p-l-2,
    .p-x-2 {
        padding-left: 1.5rem !important;
    }

    .p-l-4 {
        padding-left: 4rem !important;
    }

    .p-t-5 {
        padding-top: 5rem !important;
    }

    .p-y-5 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .cursor-pointer,
    .cursor-pointer:hover {
        cursor: pointer;
    }

    main,
    nav {
        display: block;
    }

    a {
        background: transparent;
    }

    a:active,
    a:hover {
        outline: 0;
    }

    strong {
        font-weight: 700;
    }

    img {
        border: 0;
    }

    button,
    input {
        color: inherit;
    }

    button {
        overflow: visible;
    }

    button {
        -webkit-appearance: button;
        cursor: pointer;
    }

    input {
        line-height: normal;
    }

    @media print {
        * {
            text-shadow: none !important;
            color: #000 !important;
            background: transparent !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        a,
        a:visited {
            text-decoration: underline;
        }

        a[href]:after {
            content: " (" attr(href) ")";
        }

        a[href^="#"]:after {
            content: "";
        }

        img {
            page-break-inside: avoid;
        }

        img {
            max-width: 100% !important;
        }

        h2,
        h3,
        p {
            orphans: 3;
            widows: 3;
        }

        h2,
        h3 {
            page-break-after: avoid;
        }
    }

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    :after,
    :before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    button,
    input {
        font-family: inherit;
        font-size: inherit;
    }

    a {
        color: "#0062E4";
    }

    a:focus,
    a:hover {
        color: "#004EB6";
    }

    a:focus {
        outline: thin dotted;
    }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
    }

    h1,
    h2,
    h3 {
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
    }

    h1,
    h2,
    h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 24px;
    }

    p {
        margin: 0 0 10px;
    }

    ul {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .horizontal-box {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: box;
        display: flex;
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -o-box-orient: horizontal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .headline-2-text,
    .headline-3-text,
    .headline-4-text {
        font-weight: 400;
    }

    .headline-2-text,
    .headline-3-text,
    .headline-4-text {
        margin-bottom: 10px;
    }

    .headline-3-text,
    .headline-4-text {
        font-family: OpenSans, Arial, sans-serif;
    }

    .headline-3-text {
        font-size: 22px;
        line-height: 24px;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        font-family: OpenSans, Arial, sans-serif;
    }

    .caption-text {
        font-size: 12px;
        line-height: 18px;
        font-family: OpenSans, Arial, sans-serif;
    }

    main,
    nav {
        display: block;
    }

    a {
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
    }

    a:active,
    a:hover {
        outline-width: 0;
    }

    strong {
        font-weight: inherit;
        font-weight: bolder;
    }

    h1 {
        font-size: 2em;
        margin: 0.67em 0;
    }

    img {
        border-style: none;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    hr {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    button,
    input {
        font: inherit;
        margin: 0;
    }

    button,
    input {
        overflow: visible;
    }

    button {
        text-transform: none;
    }

    [type="submit"],
    button,
    html [type="button"] {
        -webkit-appearance: button;
    }

    *,
    :after,
    :before {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }

    h1,
    h2,
    h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ul {
        margin-bottom: 1rem;
    }

    ul {
        margin-top: 0;
    }

    a {
        color: #0062e4;
        text-decoration: none;
    }

    a:focus,
    a:hover {
        text-decoration: underline;
    }

    a:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
    }

    a:not([href]):not([tabindex]):focus,
    a:not([href]):not([tabindex]):hover {
        color: inherit;
        text-decoration: none;
    }

    a:not([href]):not([tabindex]):focus {
        outline: none;
    }

    img {
        vertical-align: middle;
    }

    [role="button"] {
        cursor: pointer;
    }

    [role="button"],
    a,
    button,
    input {
        -ms-touch-action: manipulation;
        touch-action: manipulation;
    }

    button:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }

    button,
    input {
        line-height: inherit;
    }

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1,
    h2,
    h3 {
        font-family: OpenSans-light, Arial, sans-serif;
    }

    h1 {
        font-size: 3rem;
        line-height: 3.75rem;
    }

    h2 {
        font-size: 2.125rem;
        line-height: 2.625rem;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 1.875rem;
    }

    .bold {
        font-family: OpenSans, Arial, sans-serif;
        font-weight: 700;
    }

    .horizontal-box {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: box;
        display: flex;
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -o-box-orient: horizontal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .headline-2-text,
    .headline-3-text,
    .headline-4-text {
        font-weight: 400;
    }

    .headline-2-text,
    .headline-3-text,
    .headline-4-text {
        margin-bottom: 10px;
    }

    .headline-2-text {
        font-family: OpenSans-Light, Arial, sans-serif;
    }

    .headline-3-text,
    .headline-4-text {
        font-family: OpenSans, Arial, sans-serif;
    }

    .headline-4-text {
        font-size: 24px;
        line-height: 30px;
    }

    .headline-3-text {
        font-size: 22px;
        line-height: 24px;
    }

    .headline-2-text {
        font-size: 20px;
        line-height: 24px;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        font-family: OpenSans, Arial, sans-serif;
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.83);
    }

    [class*="card-"] {
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
    }

    [class*="card-"]:hover {
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
    }

    .horizontal-box.align-items-spacebetween {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -o-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -o-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    button.button-link {
        background: transparent none repeat 0 0 scroll;
        color: rgba(0, 0, 0, 0.87);
        border: 0;
        font-size: 1em;
        margin: 0;
        padding: 0;
        text-align: left;
        text-decoration: none;
        text-indent: 0;
        min-width: 0;
    }

    button.button-link:disabled {
        background: transparent none repeat 0 0 scroll;
        color: rgba(0, 0, 0, 0.26);
    }

    button.button-link {
        color: #0062e4;
        text-decoration: none;
        cursor: pointer;
    }

    button.button-link:hover {
        text-decoration: underline;
        color: #004eb6;
    }

    h1,
    h2,
    h3 {
        margin: 0;
        padding: 0;
    }

    .caption-text,
    .label-text {
        font-size: 12px;
        line-height: 18px;
        font-family: OpenSans, Arial, sans-serif;
    }

    .label-text {
        font-weight: 700;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 10px;
    }

    ul {
        font-size: 1em;
        outline: 0;
    }

    @media print {

        a:link:after,
        a:visited:after {
            content: "" !important;
        }
    }

    .screenreader-only {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    .rc-FinaidLink .caption-text {
        font-size: 12px;
    }

    .rc-FinaidLink.reset .caption-text {
        font-size: inherit;
        font-weight: inherit;
        margin-bottom: 0;
    }

    .rc-FinaidLink.reset .finaid-link {
        color: inherit;
    }

    @media print {
        * {
            overflow: visible !important;
        }
    }

    .max-text-width {
        max-width: 720px;
    }

    .text-primary {
        color: #363b42;
    }

    .text-primary-dark {
        color: #fff;
    }

    .text-secondary {
        color: #6e767f;
    }

    .bg-white {
        background-color: #fff;
    }

    .bg-light-blue {
        background-color: #f5f7fa;
    }

    .color-white {
        color: #fff;
    }

    .color-black {
        color: #000;
    }

    .border-a {
        border: 1px solid #e3e4e5;
    }

    .border-bottom {
        border-bottom: 1px solid #e3e4e5;
    }

    .border-top {
        border-top: 1px solid #e3e4e5;
    }

    .list-style-none {
        list-style: none;
    }

    .pos-relative {
        position: relative;
    }

    h1,
    h2,
    h3,
    p {
        max-width: 100%;
    }

    .font-md {
        font-size: 1rem;
    }

    .font-sm {
        font-size: 0.9rem;
    }

    .font-xs {
        font-size: 0.8rem;
    }

    .d-block {
        display: block !important;
    }

    .d-inline-block {
        display: inline-block !important;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .w-100 {
        width: 100% !important;
    }

    .m-x-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .m-a-0 {
        margin: 0 !important;
    }

    .m-b-0 {
        margin-bottom: 0 !important;
    }

    .m-x-0 {
        margin-left: 0 !important;
    }

    .m-x-0 {
        margin-right: 0 !important;
    }

    .m-r-1s {
        margin-right: 0.5rem !important;
    }

    .m-b-1s {
        margin-bottom: 0.5rem !important;
    }

    .m-l-1s,
    .m-x-1s {
        margin-left: 0.5rem !important;
    }

    .m-x-1s {
        margin-right: 0.5rem !important;
    }

    .m-t-1 {
        margin-top: 1rem !important;
    }

    .m-r-1 {
        margin-right: 1rem !important;
    }

    .m-b-1 {
        margin-bottom: 1rem !important;
    }

    .m-l-1 {
        margin-left: 1rem !important;
    }

    .m-y-1 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .m-t-2 {
        margin-top: 1.5rem !important;
    }

    .m-r-2 {
        margin-right: 1.5rem !important;
    }

    .m-b-2 {
        margin-bottom: 1.5rem !important;
    }

    .m-t-3 {
        margin-top: 3rem !important;
    }

    .m-b-3 {
        margin-bottom: 3rem !important;
    }

    .p-a-0 {
        padding: 0 !important;
    }

    .p-r-0 {
        padding-right: 0 !important;
    }

    .p-x-0 {
        padding-left: 0 !important;
    }

    .p-x-0 {
        padding-right: 0 !important;
    }

    .p-t-1s {
        padding-top: 0.5rem !important;
    }

    .p-b-1s {
        padding-bottom: 0.5rem !important;
    }

    .p-l-1s {
        padding-left: 0.5rem !important;
    }

    .p-y-1s {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .p-a-1 {
        padding: 1rem !important;
    }

    .p-t-1 {
        padding-top: 1rem !important;
    }

    .p-r-1 {
        padding-right: 1rem !important;
    }

    .p-b-1 {
        padding-bottom: 1rem !important;
    }

    .p-l-1 {
        padding-left: 1rem !important;
    }

    .p-y-1 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .p-t-2 {
        padding-top: 1.5rem !important;
    }

    .p-r-2 {
        padding-right: 1.5rem !important;
    }

    .p-b-2 {
        padding-bottom: 1.5rem !important;
    }

    .p-l-2,
    .p-x-2 {
        padding-left: 1.5rem !important;
    }

    .p-x-2 {
        padding-right: 1.5rem !important;
    }

    .p-y-2 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .p-t-3 {
        padding-top: 3rem !important;
    }

    .p-b-3 {
        padding-bottom: 3rem !important;
    }

    .text-xs-left {
        text-align: left !important;
    }

    .text-xs-center {
        text-align: center !important;
    }

    .text-uppercase {
        text-transform: uppercase !important;
    }

    .font-weight-bold {
        font-weight: 700;
    }

    @media (min-width: 576px) {
        .hidden-sm-up {
            display: none !important;
        }
    }

    @media (max-width: 767px) {
        .hidden-sm-down {
            display: none !important;
        }
    }

    .rc-LearningObjectivesAndSkills {
        margin-bottom: 24px;
    }

    .rc-LearningObjectivesAndSkills .skills-combined-with-learning-objs {
        border: none;
    }

    .rc-LearningObjectivesAndSkills .skills-combined-with-learning-objs {
        margin-top: 13px;
    }

    .rc-LearningObjectivesAndSkills .separator {
        margin: 0 24px;
    }

    .rc-Carousel.stepped {
        width: auto;
        margin: 0 auto;
    }

    .rc-Carousel.stepped .slick-slider {
        width: auto;
    }

    @media (min-width: 608px) {
        .rc-Carousel.stepped .slick-slider {
            width: 456px;
        }
    }

    @media (min-width: 824px) {
        .rc-Carousel.stepped .slick-slider {
            width: 684px;
        }
    }

    @media (min-width: 1040px) {
        .rc-Carousel.stepped .slick-slider {
            width: 912px;
        }
    }

    @media (min-width: 1472px) {
        .rc-Carousel.stepped .slick-slider {
            width: 1368px;
        }
    }

    .rc-Carousel .carousel-dots {
        cursor: pointer;
        position: absolute;
        display: block;
        width: 100%;
        padding: 0;
        margin: 10px 0 0;
        list-style: none;
        line-height: 0;
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: box !important;
        display: flex !important;
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -o-box-orient: horizontal;
        -webkit-box-lines: multiple;
        -moz-box-lines: multiple;
        -o-box-lines: multiple;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -o-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
    }

    .rc-Carousel .carousel-dots li {
        width: 20px;
        height: 20px;
        padding: 0;
    }

    .rc-Carousel .carousel-dots button {
        position: relative;
        visibility: visible;
        width: 20px;
        background: none;
        border: none;
        color: #f4f6f9;
        height: 20px;
        padding: 2px;
    }

    .rc-Carousel .carousel-dots button:before {
        color: #5e5e5e;
        content: "○";
        visibility: visible;
        position: absolute;
        left: 2px;
        display: block;
        font-size: 16px;
        width: 16px;
        line-height: 16px;
        top: 2px;
    }

    .rc-Carousel .carousel-dots .slick-active button:before {
        content: "●";
    }

    .rc-Carousel .slick-slider {
        margin: 0 auto;
        position: relative;
        display: block;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .rc-Carousel .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        padding: 0;
    }

    .rc-Carousel .slick-track {
        width: 5000px;
        position: relative;
        top: 0;
        left: 0;
        display: block;
    }

    .rc-Carousel .slick-track:after {
        clear: both;
    }

    .rc-Carousel .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }

    .rc-Carousel .slick-slide img {
        display: block;
    }

    .rc-Carousel .slick-initialized .slick-slide {
        display: block;
    }

    .rc-Carousel .slick-slider .slick-list,
    .rc-Carousel .slick-slider .slick-track {
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    .rc-Carousel .slick-track:after,
    .rc-Carousel .slick-track:before {
        display: table;
        content: "";
    }

    .rc-Carousel .slider-button {
        position: absolute;
        top: 0;
        padding: 0;
        z-index: 1;
        height: 100%;
    }

    .rc-Carousel .slider-button.slider-left {
        left: -48px;
    }

    .rc-Carousel .slider-button.slider-right {
        right: -48px;
    }

    .rc-Carousel .slider-button:focus {
        outline: 0 !important;
    }

    .rc-Carousel .slider-button:focus svg {
        outline: 2px auto #0069d9;
    }

    .rc-UserTestimonials .rc-Carousel,
    .rc-UserTestimonials .rc-Carousel .slick-slider {
        width: 100%;
    }

    .rc-UserTestimonials .rc-Carousel .slider-button.slider-right {
        right: -50px;
    }

    .rc-UserTestimonials .rc-Carousel .slider-button.slider-left {
        left: -50px;
    }

    .rc-UserTestimonials .rc-Carousel .slick-track {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: box !important;
        display: flex !important;
        -webkit-box-lines: single;
        -moz-box-lines: single;
        -o-box-lines: single;
        -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
        overflow: hidden !important;
    }

    .rc-UserTestimonials .rc-Carousel .slick-slide {
        width: 540px;
    }

    .rc-UserTestimonials .rc-Carousel .card-container {
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: box !important;
        display: flex !important;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -o-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -moz-box-align: center;
        -o-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        height: 180px;
    }

    .rc-UserTestimonials .rc-Carousel .card-container .overlay {
        position: absolute;
        top: 5px;
        height: 170px;
        width: 488px;
        background-color: hsla(0, 0%, 84.7%, 0.65);
        display: none;
    }

    .rc-UserTestimonials .rc-Carousel .card-container.not-active .overlay {
        display: block;
    }

    .rc-UserTestimonials .rc-Carousel .rc-UserTestimonial .normal-content-container {
        height: 170px;
        width: 488px;
        -webkit-box-shadow: 2px 2px 2px #bdbdbd;
        box-shadow: 2px 2px 2px #bdbdbd;
    }

    @media (max-width: 991px) {
        .rc-UserTestimonials .rc-Carousel .slider-button.slider-right {
            right: -50px;
        }

        .rc-UserTestimonials .rc-Carousel .slider-button.slider-left {
            left: -50px;
        }

        .rc-UserTestimonials .rc-Carousel .card-container {
            margin-left: 4px;
            margin-right: 4px;
            width: 400px;
        }

        .rc-UserTestimonials .rc-Carousel .card-container .overlay {
            width: 300px;
        }

        .rc-UserTestimonials .rc-Carousel .slick-slide {
            width: 400px;
        }

        .rc-UserTestimonials .rc-Carousel .rc-UserTestimonial .normal-content-container {
            width: 300px;
        }

        .rc-UserTestimonials .rc-Carousel .rc-UserTestimonial .quote {
            padding-top: 10px;
            margin-bottom: 0;
        }

        .rc-UserTestimonials .rc-Carousel .rc-UserTestimonial .user-name {
            padding-bottom: 10px;
        }
    }

    @media (max-width: 767px) {
        .rc-UserTestimonials .rc-Carousel .slider-button.slider-right {
            display: none !important;
            right: 0;
        }

        .rc-UserTestimonials .rc-Carousel .slider-button.slider-left {
            display: none !important;
            left: 0;
        }

        .rc-UserTestimonials .rc-Carousel .slick-slide {
            margin: 0 4px;
        }

        .rc-UserTestimonials .rc-Carousel .card-container {
            margin: 0;
        }

        .rc-UserTestimonials .rc-Carousel .card-container.not-active .overlay,
        .rc-UserTestimonials .rc-Carousel .card-container .overlay {
            display: none;
        }

        .rc-UserTestimonials .rc-Carousel .rc-UserTestimonial .caption-container {
            padding-left: 0;
            padding-right: 12px;
        }
    }

    @media (max-width: 479px) {
        .rc-UserTestimonials .rc-Carousel .slick-slide {
            margin: 0 4px;
            width: 300px;
        }
    }

    .rc-UserTestimonialsSection {
        display: block;
        background-color: #f4f6f9;
    }

    .XdpApp .rc-ProductMetrics {
        display: inline-block;
    }

    .XdpApp .rc-ProductMetrics {
        margin-right: 30px;
    }

    @media (max-width: 767px) {
        .XdpApp .rc-ProductMetrics {
            margin-right: 0;
        }
    }

    .XDPRating {
        -webkit-box-lines: multiple;
        -moz-box-lines: multiple;
        -o-box-lines: multiple;
        -webkit-flex-flow: wrap;
        -ms-flex-flow: wrap;
        flex-flow: wrap;
    }

    .rc-NavItem a:hover {
        cursor: pointer;
    }

    .help-link {
        color: #2668b8;
        font-weight: 700;
    }

    .degreeContainer {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-position: 100%;
    }

    .leftUnderlay {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    @media (min-width: 824px) {
        .leftUnderlay {
            -webkit-transform: skewX(7deg) translate(-45%);
            -moz-transform: skewX(7deg) translate(-45%);
            -o-transform: skewX(7deg) translate(-45%);
            -ms-transform: skewX(7deg) translate(-45%);
            transform: skewX(7deg) translate(-45%);
        }
    }
}

/*! CSS Used from: Embedded */
._ufjrdd {
    display: inline-block;
    vertical-align: middle;
    color: #363b42;
    -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

._1qfi0x77 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

._1jug6qr {
    min-height: 100vh;
}

._1t452v5 {
    align-items: center;
}

@media (max-width: 608px) {
    ._1t452v5 {
        display: block;
        margin-bottom: 1rem;
    }
}

._bxbqi1 {
    height: 120px;
    margin-top: 2.5rem;
}

@media (max-width: 824px) {
    ._bxbqi1 {
        display: none;
    }
}

._1uoqnqiz {
    height: 71px;
}

@media (max-width: 608px) {
    ._1uoqnqiz {
        height: 120px;
    }
}

@media (max-width: 824px) {
    ._1uoqnqiz {
        height: 120px;
    }
}

@media (max-width: 768px) {
    ._1uoqnqiz {
        height: 170px;
    }
}

._giqd {
    background-image: -webkit-linear-gradient(90deg, #012e61, #024794);
    background-image: -moz-linear-gradient(90deg, #012e61, #024794);
    background-image: linear-gradient(90deg, #012e61, #024794);
}

._1tu07i3a {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

._1wb6qi0n {
    height: 100%;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    min-height: 250px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
}

@media (max-width: 607px) {
    ._1wb6qi0n {
        width: 100%;
    }
}

._1wb6qi0n:after {
    display: table;
    clear: both;
}

@media (min-width: 608px) {
    ._1wb6qi0n {
        width: 576px;
    }
}

@media (min-width: 824px) {
    ._1wb6qi0n {
        width: 792px;
    }
}

@media (min-width: 1040px) {
    ._1wb6qi0n {
        width: 1008px;
    }
}

@media (min-width: 1184px) {
    ._1wb6qi0n {
        width: 1152px;
    }
}

@media (min-width: 1472px) {
    ._1wb6qi0n {
        width: 1440px;
    }
}

._jyhj5r {
    -webkit-box-lines: multiple;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
}

._jyhj5r:after {
    display: table;
    clear: both;
}

._pu0m129 {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
}

._pu0m129:after {
    display: table;
    clear: both;
    text-align: center;
}

@media (min-width: 824px) {
    ._pu0m129 {
        width: 100%;
        text-align: center;
    }
}

._172v19u6 {
    font-size: 14px;
}

._172v19u6:hover {
    color: #ffffff;
}

._172v19u6:focus {
    color: #ffffff;
}

._exc94g9 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-lines: multiple;
    margin-bottom: 32px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

._1ruggxy {
    -webkit-box-align: center;
    -ms-flex-align: center;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
}

._1bjlpa11 {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

._1srkxe1s {
    -webkit-box-lines: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 5px;
}

._1mzojlvw {
    display: inline-block;
    position: relative;
    cursor: inherit;
}

._13xsef79 {
    cursor: inherit;
    margin-bottom: 0.5rem;
}

._16ni8zai {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold;
    font-family: "OpenSans-Bold", OpenSans, Arial, sans-serif;
}

._wmgtrl9 {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: normal;
    font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif;
}

._12gv18d9 {
    -webkit-box-lines: multiple;
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (max-width: 608px) {
    ._12gv18d9 {
        display: inline-block;
    }
}

._2lnij6 {
    list-style-type: none;
    padding-left: 0px;
}

._y1d9czk {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

._1c0nwk {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    color: #fff;
    background-color: #2a73cc;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}

._1fpiay2 {
    font-size: 16px;
    padding-top: 24px;
}

._1s1bh7u9 {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._1s1bh7u9:after {
    display: table;
    clear: both;
}

@media (min-width: 824px) {
    ._1s1bh7u9 {
        padding-left: 8%;
        width: 50%;
    }
}

._4dxc70n {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@media (max-width: 824px) {
    ._4dxc70n {
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

._1g3eaodg {
    max-width: 100%;
    max-height: 70px;
}

@media (max-width: 608px) {
    ._1g3eaodg {
        max-height: 45px;
    }
}

._z05si {
    border-radius: 24px;
    width: 48px;
    height: 48px;
    background-color: #fff;
}

._12xo4wd {
    color: #ffffff;
}

._12xo4wd:hover {
    color: #ffffff;
}

._12xo4wd:focus {
    color: #ffffff;
}

._1lutnh9y {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

._1dhzrmy {
    height: 73px;
}

._1l3xkq5 {
    line-height: 40px;
    width: 100%;
}

._9iwmma {
    list-style-type: none;
    overflow-x: auto;
    white-space: nowrap;
    padding-left: 0px;
}

._1d3lkver {
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
}

._1d3lkver:after {
    display: table;
    clear: both;
}

@media (min-width: 608px) {
    ._1d3lkver {
        width: 576px;
    }
}

@media (min-width: 824px) {
    ._1d3lkver {
        width: 792px;
    }
}

@media (min-width: 1040px) {
    ._1d3lkver {
        width: 1008px;
    }
}

@media (min-width: 1184px) {
    ._1d3lkver {
        width: 1152px;
    }
}

@media (min-width: 1472px) {
    ._1d3lkver {
        width: 1440px;
    }
}

._36rlri {
    display: inline-block;
}

._1rzw47j {
    color: #1f1f1f;
}

._1rzw47j:hover {
    color: #2a73cc;
}

._1u9fru1 {
    display: none;
}

._naqw41 {
    padding-bottom: 10px;
    padding-top: 10px;
    height: 76px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@media (max-width: 824px) {
    ._naqw41 {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -ms-flex-item-align: start;
        height: inherit;
        -webkit-align-self: flex-start;
        align-self: flex-start;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

._11ufgi5 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    line-height: 0;
    margin-right: 10px;
}

@media (max-width: 824px) {
    ._dimcub {
        display: none;
    }
}

._1mudbwj {
    color: #ffffff;
    line-height: 14px;
}

._1mudbwj:hover {
    color: #ffffff;
}

@media (max-width: 824px) {
    ._1mudbwj {
        line-height: 1rem;
    }
}

._15omh82 {
    display: none;
}

@media (max-width: 824px) {
    ._15omh82 {
        display: block;
        width: 100%;
        line-height: 1.2rem;
        margin-top: 5px;
        font-size: 0.875rem;
    }
}

._9f36os {
    -webkit-box-align: end;
    -ms-flex-align: end;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

._1rws3bm {
    margin-left: 24px;
    margin-bottom: 12px;
}

@media (max-width: 824px) {
    ._1rws3bm {
        display: none;
    }
}

._errb565 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@media (min-width: 1041px) {
    ._9cam1z {
        display: none;
    }
}

._1pfe6xlx {
    margin-top: 24px;
}

@media (max-width: 1040px) {
    ._1pfe6xlx {
        display: none;
    }
}

._1b7vhsnq {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._1b7vhsnq:after {
    display: table;
    clear: both;
}

@media (min-width: 320px) {
    ._1b7vhsnq {
        width: 100%;
    }
}

@media (min-width: 824px) {
    ._1b7vhsnq {
        width: 66.666667%;
    }
}

._1fe1gic7 {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: bold;
    font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif;
}

._1yuv87zj {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._1yuv87zj:after {
    display: table;
    clear: both;
}

@media (min-width: 320px) {
    ._1yuv87zj {
        width: 100%;
    }
}

@media (min-width: 824px) {
    ._1yuv87zj {
        width: 50%;
    }
}

._rsc0bd {
    -webkit-box-align: center;
    -ms-flex-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.1875rem 1.125rem;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    color: #1f1f1f;
}

._x4x75x {
    -webkit-box-align: center;
    -ms-flex-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
}

._1q9sh65 {
    overflow: hidden;
    text-overflow: ellipsis;
}

._ontdeqt {
    -webkit-box-align: center;
    -ms-flex-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
    margin-left: 0.375rem;
}

._bd90rg {
    font-size: 14px;
    color: #666;
    font-weight: bold;
}

._1dfpihp {
    -ms-flex-negative: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid #e1e1e1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

._xliqh9g {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._xliqh9g:after {
    display: table;
    clear: both;
}

@media (min-width: 824px) {
    ._xliqh9g {
        width: 33.333333%;
    }
}

._cs3pjta {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._cs3pjta:after {
    display: table;
    clear: both;
}

@media (min-width: 320px) {
    ._cs3pjta {
        width: 100%;
    }
}

._1utv7ymp {
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    line-height: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    background-image: none;
    cursor: pointer;
    -webkit-filter: none;
    filter: none;
    text-decoration: none;
    font-weight: 600;
    color: #1f1f1f;
    background-color: transparent;
    border-color: transparent;
    padding: 0.9rem 2rem;
    font-size: 1rem;
    min-height: 48px;
}

._1utv7ymp:hover {
    text-decoration: none !important;
    color: #2a73cc;
    border-color: transparent;
}

._1utv7ymp:focus {
    outline: auto 2px rgb(0, 105, 217);
    text-decoration: none !important;
    color: #2a73cc;
    border-color: transparent;
}

._l3lqkfz {
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    line-height: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    background-image: none;
    cursor: pointer;
    -webkit-filter: none;
    filter: none;
    text-decoration: none;
    font-weight: 600;
    color: #1f1f1f;
    background-color: #fff;
    border: 1px solid #666666;
    padding: 0.9rem 2rem;
    font-size: 1rem;
    min-height: 48px;
}

._l3lqkfz:hover {
    text-decoration: none !important;
    color: #fff;
    background-color: #0156b8;
    border: 1px solid #2a73cc;
}

._l3lqkfz:focus {
    outline: auto 2px rgb(0, 105, 217);
    text-decoration: none !important;
    color: #fff;
    background-color: #0156b8;
    border: 1px solid #2a73cc;
}

._8ekd2y {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
}

._8ekd2y:after {
    display: table;
    clear: both;
}

._1qyz2ibx {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

._g9ovuw {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._g9ovuw:after {
    display: table;
    clear: both;
}

._1ozdigc {
    display: inline-block;
    text-align: center;
}

@media (max-width: 824px) {
    ._1ozdigc {
        display: block;
    }
}

._11oyobo {
    white-space: pre-wrap;
}

._kzwqs27 {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._kzwqs27:after {
    display: table;
    clear: both;
}

@media (min-width: 320px) {
    ._kzwqs27 {
        width: 100%;
    }
}

@media (min-width: 824px) {
    ._kzwqs27 {
        width: 16.666667%;
    }
}

@media (min-width: 1040px) {
    ._kzwqs27 {
        width: 16.666667%;
    }
}

._1nc68rjl {
    font-size: 3.75rem;
    line-height: 4.5rem;
    font-weight: normal;
    font-family: "OpenSans-Light", OpenSans, Arial, sans-serif;
}

._1tqo7r77 {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._1tqo7r77:after {
    display: table;
    clear: both;
}

@media (min-width: 320px) {
    ._1tqo7r77 {
        width: 100%;
    }
}

@media (min-width: 824px) {
    ._1tqo7r77 {
        width: 83.333333%;
    }
}

@media (min-width: 1040px) {
    ._1tqo7r77 {
        width: 83.333333%;
    }
}

._16xoka1 {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

._msg5sa {
    overflow: hidden;
    -webkit-transition: height 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: height 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: height 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

._1i2ljk4 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-right: 8px;
}

._1vl6vh3k {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._1vl6vh3k:after {
    display: table;
    clear: both;
}

@media (min-width: 320px) {
    ._1vl6vh3k {
        width: 100%;
    }
}

@media (min-width: 1040px) {
    ._1vl6vh3k {
        width: 50%;
    }
}

._1dnm41i {
    position: relative;
    width: 112px;
    padding-bottom: 100%;
    height: 0px;
}

@media (max-width: 824px) {
    ._1dnm41i {
        max-width: 95vw;
    }
}

@media (max-width: 608px) {
    ._1dnm41i {
        max-width: 90vw;
    }
}

._wtdnuob {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

._sdyadj {
    position: relative;
    width: 12px;
    padding-bottom: 100%;
    height: 0px;
}

@media (max-width: 824px) {
    ._sdyadj {
        max-width: 95vw;
    }
}

@media (max-width: 608px) {
    ._sdyadj {
        max-width: 90vw;
    }
}

._12eg9yv {
    position: relative;
    width: 120px;
    padding-bottom: 100%;
    height: 0px;
}

@media (max-width: 824px) {
    ._12eg9yv {
        max-width: 95vw;
    }
}

@media (max-width: 608px) {
    ._12eg9yv {
        max-width: 90vw;
    }
}

._y7h87h1 {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

._eo0j7w {
    background-image: -webkit-linear-gradient(90deg, #fc6e51, #f6bb42);
    background-image: -moz-linear-gradient(90deg, #fc6e51, #f6bb42);
    background-image: linear-gradient(90deg, #fc6e51, #f6bb42);
}

._473mf9o {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

._1l2q8kho {
    font-size: 2.125rem;
    line-height: 2.625rem;
    font-weight: normal;
    font-family: "OpenSans-Light", OpenSans, Arial, sans-serif;
}

._o4kklvw {
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    line-height: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    background-image: none;
    cursor: pointer;
    -webkit-filter: none;
    filter: none;
    text-decoration: none;
    font-weight: 600;
    color: #2a73cc;
    padding: 0.9rem 2rem;
    font-size: 1rem;
    min-height: 48px;
    background-color: #fff;
    border: 1px solid #2a73cc;
}

._o4kklvw:hover {
    text-decoration: none !important;
    color: #fff;
    background-color: #0156b8;
}

._o4kklvw:focus {
    outline: auto 2px rgb(0, 105, 217);
    text-decoration: none !important;
    color: #fff;
    background-color: #0156b8;
}

._3xcvfrd {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

._3xcvfrd:after {
    display: table;
    clear: both;
}

@media (min-width: 320px) {
    ._3xcvfrd {
        width: 100%;
    }
}

@media (min-width: 824px) {
    ._3xcvfrd {
        width: 83.333333%;
        margin-left: 8.333333%;
    }
}

@media (min-width: 1040px) {
    ._3xcvfrd {
        width: 66.666667%;
        margin-left: 16.666667%;
    }
}

/*! CSS Used from: Embedded */
.css-yw2k7b:focus {
    outline: none;
}

.css-1fsi0lu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 1.5rem;
    border-top: 1px solid #e3e4e5;
    padding-top: 40px;
}

@media (max-width: 1040px) {
    .css-1fsi0lu {
        padding-left: 0;
        padding-top: 32px;
    }
}

@media (min-width: 1041px) {
    .css-1fsi0lu {
        display: none;
    }
}

.css-1tzsij3 {
    margin: 0 1rem 0.5rem 0.5rem;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid rgb(225, 225, 225);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.css-cmlqkw {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.css-lng0nv {
    color: #1f1f1f;
    letter-spacing: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin-bottom: 32px;
}

.css-wqz90x {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: bold;
    color: #0056d2;
    border: 1px solid #0056d2;
    padding: 12px 32px;
    border-radius: 4px;
    width: 274px;
}

.css-wqz90x:hover {
    background: #eeeeee;
    -webkit-text-decoration: none;
    text-decoration: none;
}

.css-wqz90x svg {
    margin-left: 8px;
}

.css-aua34p {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 1.5rem;
    border-top: 1px solid #e3e4e5;
    padding-top: 40px;
}

@media (max-width: 1040px) {
    .css-aua34p {
        padding-left: 0;
        padding-top: 32px;
    }
}

@media (max-width: 1040px) {
    .css-aua34p {
        display: none;
    }
}

.css-mwu97z {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border: 1px solid #c3c5c6;
    border-radius: 4px;
    margin: 32px 0;
    padding: 24px;
}

.css-mwu97z:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
}

@media (max-width: 1023.95px) {
    .css-mwu97z {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.css-8c0k8a {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-right: 1px solid #c3c5c6;
    padding-right: 32px;
}

@media (max-width: 1023.95px) {
    .css-8c0k8a {
        border-bottom: 1px solid #c3c5c6;
        border-right: 0;
        padding-right: 0;
        padding-bottom: 24px;
    }
}

.css-1q6egms {
    color: #1f1f1f;
    letter-spacing: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin-bottom: 16px;
    margin-top: 8px;
}

.css-m1juqa {
    font-weight: bold;
    color: #0056d2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 32px;
}

.css-m1juqa svg {
    margin-left: 8px;
}

.css-1ryj8il {
    padding-left: 40px;
    padding-right: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media (max-width: 1023.95px) {
    .css-1ryj8il {
        padding-left: 0;
        padding-right: 0;
        padding-top: 32px;
        margin-left: -24px;
    }
}

.css-b69uzl {
    width: 100%;
}

.css-b1j1et {
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.css-b1j1et p {
    letter-spacing: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
    margin-bottom: 16px;
    min-height: 24px;
}

.css-b1j1et a {
    color: #0056d2;
    -webkit-text-decoration: underline;
    text-decoration: underline;
}

.css-b1j1et a:hover {
    color: #00419e;
}

.css-1f490rz {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: 1px solid #c3c5c6;
    border-radius: 4px;
    margin: 32px 0;
    padding: 24px;
}

.css-1f490rz:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
}

@media (max-width: 1023.95px) {
    .css-1f490rz {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
    }
}

.css-172dkhp {
    color: #1f1f1f;
    letter-spacing: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media (max-width: 1023.95px) {
    .css-172dkhp {
        padding-bottom: 24px;
    }
}

.css-2sn2n6 {
    font-weight: bold;
    color: #0056d2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.css-2sn2n6 svg {
    margin-left: 8px;
}

.css-1rj0z6b {
    border-radius: 4px;
}

.css-uqope5 {
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.css-trl2be {
    color: #012e61;
}

.css-on79oc {
    border-radius: 4px;
    text-transform: none;
    text-align: center;
    max-width: 320px;
    min-width: unset;
    -webkit-transition: none;
    transition: none;
    color: #0056d2;
    background-color: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 700;
    letter-spacing: -0.1px;
    padding: 12px 32px;
    min-width: 180px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.css-on79oc:hover {
    color: #00419e;
    background-color: #f3f8ff;
}

.css-on79oc:active {
    color: #382d8b;
    background-color: #f7f6fe;
}

.css-on79oc:active.cds-button-disableElevation::after {
    content: unset;
}

.css-on79oc .cds-button-label {
    text-transform: none;
}

.css-on79oc:focus {
    outline: none;
}

.css-on79oc:hover {
    background-color: rgba(255, 203, 5, 0.7) !important;
}

.css-on79oc:focus {
    outline: auto 3px black;
}

.css-on79oc:disabled {
    background-color: rgba(255, 203, 5, 0.7) !important;
}

.css-14dgt3v {
    border-radius: 4px;
    text-transform: none;
    text-align: center;
    max-width: 320px;
    min-width: unset;
    -webkit-transition: none;
    transition: none;
    color: #0056d2;
    background-color: #fff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 700;
    letter-spacing: -0.1px;
    padding: 8px 16px;
    min-width: 180px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.css-14dgt3v:hover {
    color: #00419e;
    background-color: #f3f8ff;
}

.css-14dgt3v:active {
    color: #382d8b;
    background-color: #f7f6fe;
}

.css-14dgt3v:active.cds-button-disableElevation::after {
    content: unset;
}

.css-14dgt3v .cds-button-label {
    text-transform: none;
}

.css-14dgt3v:focus {
    outline: none;
}

.css-14dgt3v:hover {
    background-color: rgba(255, 203, 5, 0.7) !important;
}

.css-14dgt3v:focus {
    outline: auto 3px black;
}

.css-14dgt3v:disabled {
    background-color: rgba(255, 203, 5, 0.7) !important;
}

/*! CSS Used from: Embedded */
.css-lm98yl {
    margin: -2px;
    width: calc(100% + 4px);
}

.css-lm98yl>.cds-grid-item {
    padding: 2px;
}

.css-14bi18n {
    color: rgb(99, 99, 99);
}

.css-1j071wf {
    color: rgb(31, 31, 31);
    letter-spacing: 0px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
}

.css-28pmus {
    border-radius: 4px;
    text-transform: none;
    text-align: center;
    max-width: 320px;
    transition: none 0s ease 0s;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 86, 210);
    box-shadow: rgb(0, 86, 210) 0px 0px 0px 1px inset;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 700;
    letter-spacing: -0.1px;
    padding: 12px 32px;
    min-width: 180px;
    width: max-content;
}

.css-28pmus:hover {
    border-color: rgb(0, 65, 158);
    background-color: rgb(0, 65, 158);
}

.css-28pmus:active {
    border-color: rgb(56, 45, 139);
    background-color: rgb(56, 45, 139);
}

.css-28pmus:active.cds-button-disableElevation::after {
    content: unset;
}

.css-28pmus .cds-button-label {
    text-transform: none;
}

.css-28pmus:focus {
    outline: none;
}

.css-28pmus:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.css-28pmus:focus {
    outline: black auto 3px;
}

.css-28pmus:disabled {
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.css-1q8qpn9 {
    color: rgb(31, 31, 31);
    letter-spacing: 0px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
    max-width: 430px;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-300.svg#SourceSansPro") format("svg");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-regular.svg#SourceSansPro") format("svg");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-600.svg#SourceSansPro") format("svg");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/source-sans-pro-v14-latin/source-sans-pro-v14-latin-700.svg#SourceSansPro") format("svg");
}

@font-face {
    font-family: "OpenSans-Light";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-300.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-300.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.svg#OpenSans-Light") format("svg");
}

@font-face {
    font-family: "OpenSans";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-regular.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-regular.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Regular.svg#OpenSans-Regular") format("svg");
}

@font-face {
    font-family: "OpenSans";
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
}

@font-face {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
}

@font-face {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-700.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
}

@font-face {
    font-family: "OpenSans-Light";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.eot");
    src: url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-300.woff2") format("woff2"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans-v17-latin-latinext-cyrillic/opensans-300.woff") format("woff"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.ttf") format("truetype"),
        url("https://d3njjcbhbojbot.cloudfront.net/web/type/opensans/OpenSans-Light.svg#OpenSans-Light") format("svg");
}