/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/bootstrap.css */
a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

@media print {

    *,
    *:before,
    *:after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    p,
    h2 {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #23527c;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

h2 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h2 {
    font-size: 30px;
}

p {
    margin: 0 0 10px;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-12 {
    float: left;
}

.col-xs-12 {
    width: 100%;
}

@media (min-width: 768px) {
    .col-sm-12 {
        float: left;
    }

    .col-sm-12 {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .col-md-12 {
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .col-lg-12 {
        float: left;
    }

    .col-lg-12 {
        width: 100%;
    }
}

.container:before,
.container:after,
.row:before,
.row:after {
    display: table;
    content: " ";
}

.container:after,
.row:after {
    clear: both;
}

/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/font-awesome.css */
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-check-square:before {
    content: "\f14a";
}

/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/style_II.css */
a {
    color: #797979;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #707070;
    text-decoration: none;
    outline: none;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.1;
    color: #222222;
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.aboutus_section {
    float: left;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 50px;
}

.about_text_wrapper {
    float: left;
    width: 100%;
}

.about_text_wrapper h2 {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    position: relative;
}

.about_text_wrapper h2:before {
    content: '';
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: 0;
}

.about_text_wrapper h2:after {
    content: '';
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 12px;
}

.about_text_wrapper p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
    padding-top: 30px;
}

.about_text_wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.abt_2_para {
    padding-top: 60px;
}

.about_text_wrapper ul li:first-child {
    padding-top: 0px;
}

.about_text_wrapper ul li {
    padding-top: 20px;
    font-size: 16px;
}

.about_text_wrapper ul li a {
    color: #999999;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.about_text_wrapper a:hover {
    color: #9a558f;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.about_text_wrapper ul li i {
    padding-right: 10px;
    color: #23c0e9;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: 'FontAwesome';
    src: url('http://127.0.0.1:8000/static/jobPortal/fonts/fontawesome-webfont3e6e.eot?v=4.7.0');
    src: url('http://127.0.0.1:8000/static/jobPortal/fonts/fontawesome-webfontd41d.eot#iefix&v=4.7.0') format('embedded-opentype'), url('http://127.0.0.1:8000/static/jobPortal/fonts/fontawesome-webfont3e6e.woff2?v=4.7.0') format('woff2'), url('http://127.0.0.1:8000/static/jobPortal/fonts/fontawesome-webfont3e6e.woff?v=4.7.0') format('woff'), url('http://127.0.0.1:8000/static/jobPortal/fonts/fontawesome-webfont3e6e.ttf?v=4.7.0') format('truetype'), url('http://127.0.0.1:8000/static/jobPortal/fonts/fontawesome-webfont3e6e.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/rBHvpRWBkgyW99dXT88n7yEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/NX1NravqaXESu9fFv7KuqiEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/SKK6Nusyv8QPNMtI4j9J2yEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/gFXtEMCp1m_YzxsBpKl68iEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}