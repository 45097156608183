/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/bootstrap.css */
[hidden]{display:none;}
hr{height:0;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;}
button,input,select,textarea{margin:0;font:inherit;color:inherit;}
button{overflow:visible;}
button,select{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
input{line-height:normal;}
input[type="checkbox"]{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0;}
textarea{overflow:auto;}
@media print{
*,*:before,*:after{color:#000!important;text-shadow:none!important;background:transparent!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
p,h3{orphans:3;widows:3;}
h3{page-break-after:avoid;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
input,button,select,textarea{font-family:inherit;font-size:inherit;line-height:inherit;}
hr{margin-top:20px;margin-bottom:20px;border:0;border-top:1px solid #eee;}
h3,h4,h5{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
h3{margin-top:20px;margin-bottom:10px;}
h4,h5{margin-top:10px;margin-bottom:10px;}
h3{font-size:24px;}
h4{font-size:18px;}
h5{font-size:14px;}
p{margin:0 0 10px;}
.text-center{text-align:center;}
ul{margin-top:0;margin-bottom:10px;}
.col-md-4,.col-lg-4,.col-md-6,.col-lg-6,.col-sm-12,.col-md-12,.col-lg-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
@media (min-width: 768px){
.col-sm-12{float:left;}
.col-sm-12{width:100%;}
}
@media (min-width: 992px){
.col-md-4,.col-md-6,.col-md-12{float:left;}
.col-md-12{width:100%;}
.col-md-6{width:50%;}
.col-md-4{width:33.33333333%;}
}
@media (min-width: 1200px){
.col-lg-4,.col-lg-6,.col-lg-12{float:left;}
.col-lg-12{width:100%;}
.col-lg-6{width:50%;}
.col-lg-4{width:33.33333333%;}
}
label{display:inline-block;max-width:100%;margin-bottom:5px;font-weight:bold;}
input[type="checkbox"]{margin:4px 0 0;margin-top:1px \9;line-height:normal;}
input[type="checkbox"]:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
.list-group{padding-left:0;margin-bottom:20px;}
.list-group-item{position:relative;display:block;padding:10px 15px;margin-bottom:-1px;background-color:#fff;border:1px solid #ddd;}
.list-group-item:first-child{border-top-left-radius:4px;border-top-right-radius:4px;}
.list-group-item:last-child{margin-bottom:0;border-bottom-right-radius:4px;border-bottom-left-radius:4px;}
/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/font-awesome.css */
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.fa-institution:before{content:"\f19c";}
/*! CSS Used from: http://127.0.0.1:8000/static/jobPortal/css/style_II.css */
h3,h4,h5{font-family:'Montserrat', sans-serif;line-height:1.1;color:#222222;margin:0;padding:0;}
input,select,button,textarea{outline:none;}
input:focus,select:focus,button:focus,textarea:focus{outline:none;box-shadow:none;}
ul{list-style:none;margin:0;padding:0;}
input[type="checkbox"] + label:before{border:1px solid #ff5353;content:"\00a0";display:inline-block;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;font:16px/1em sans-serif;height:16px;margin:.20em .56em .0 .0;padding:0;vertical-align:top;width:16px;}
input[type="checkbox"]:checked + label:before{background:#ff5353;color:#ffffff;content:"\2713";}
input[type="checkbox"]:checked + label:after{font-weight:bold;}
/*! CSS Used fontfaces */
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/rBHvpRWBkgyW99dXT88n7yEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/NX1NravqaXESu9fFv7KuqiEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/SKK6Nusyv8QPNMtI4j9J2yEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/gFXtEMCp1m_YzxsBpKl68iEAvth_LlrfE80CYdSH47w.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;src:local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;}