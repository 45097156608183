/*! CSS Used from: Embedded */
.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.anticon>* {
    line-height: 1;
}

.anticon svg {
    display: inline-block;
}

.anticon::before {
    display: none;
}

.anticon-spin::before,
.anticon-spin {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
    animation: loadingCircle 1s infinite linear;
}

/*! CSS Used from: https://demos.creative-tim.com/muse-ant-design-dashboard/static/css/2.5f5f9133.chunk.css */
*,
:after,
:before {
    box-sizing: border-box;
}

[tabindex="-1"]:focus {
    outline: none !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
}

p {
    margin-top: 0;
    margin-bottom: 1em;
}

input[type=text] {
    -webkit-appearance: none;
}

ul {
    margin-top: 0;
    margin-bottom: 1em;
}

small {
    font-size: 80%;
}

sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

a {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    color: #40a9ff;
}

a:active {
    color: #096dd9;
}

a:active,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

[role=button],
a,
button,
input:not([type=range]),
label {
    touch-action: manipulation;
}

table {
    border-collapse: collapse;
}

button,
input {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

button,
html [type=button] {
    -webkit-appearance: button;
}

input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

::selection {
    color: #fff;
    background: #1890ff;
}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.anticon>* {
    line-height: 1;
}

.anticon svg {
    display: inline-block;
}

.anticon:before {
    display: none;
}

.anticon-spin,
.anticon-spin:before {
    display: inline-block;
    -webkit-animation: loadingCircle 1s linear infinite;
    animation: loadingCircle 1s linear infinite;
}

.ant-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    line-height: 1;
}

.ant-badge-count {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
}

.ant-badge-count-sm {
    min-width: 14px;
    height: 14px;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    border-radius: 7px;
}

.ant-badge-count {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
}

.ant-scroll-number {
    overflow: hidden;
}

.ant-scroll-number-only {
    position: relative;
    display: inline-block;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-scroll-number-only {
    height: 20px;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
}

.ant-breadcrumb {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
}

.ant-breadcrumb a {
    color: rgba(0, 0, 0, .45);
    transition: color .3s;
}

.ant-breadcrumb a:hover {
    color: #40a9ff;
}

.ant-breadcrumb>span:last-child {
    color: rgba(0, 0, 0, .85);
}

.ant-breadcrumb>span:last-child .ant-breadcrumb-separator {
    display: none;
}

.ant-breadcrumb-separator {
    margin: 0 8px;
    color: rgba(0, 0, 0, .45);
}

.ant-menu {
    box-sizing: border-box;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum", "tnum";
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: #fff;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
    transition: background .3s, width .3s cubic-bezier(.2, 0, 0, 1) 0s;
}

.ant-menu:after,
.ant-menu:before {
    display: table;
    content: "";
}

.ant-menu:after {
    clear: both;
}

.ant-menu-item:active {
    background: #e6f7ff;
}

.ant-menu-title-content {
    transition: color .3s;
}

.ant-menu-item a {
    color: rgba(0, 0, 0, .85);
}

.ant-menu-item a:hover {
    color: #1890ff;
}

.ant-menu-item a:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: "";
}

.ant-menu-inline {
    border-right: 1px solid #f0f0f0;
}

.ant-menu-item {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: border-color .3s, background .3s, padding .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-inline .ant-menu-item {
    position: relative;
}

.ant-menu-inline .ant-menu-item:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
    transform: scaleY(.0001);
    opacity: 0;
    transition: transform .15s cubic-bezier(.215, .61, .355, 1), opacity .15s cubic-bezier(.215, .61, .355, 1);
    content: "";
}

.ant-menu-inline .ant-menu-item {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 8px;
}

.ant-menu-inline>.ant-menu-item {
    height: 40px;
    line-height: 40px;
}

.ant-menu-inline {
    width: 100%;
}

.ant-menu-inline .ant-menu-item {
    width: calc(100% + 1px);
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
    display: flex;
    align-items: center;
    transition: border-color .3s, background .3s, padding .1s cubic-bezier(.215, .61, .355, 1);
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>* {
    flex: none;
}

.ant-menu-root.ant-menu-inline {
    box-shadow: none;
}

.ant-menu-light .ant-menu-item:hover {
    color: #1890ff;
}

.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, .85);
    border: 1px solid #d9d9d9;
    background: #fff;
}

.ant-btn>.anticon {
    line-height: 1;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
    outline: 0;
}

.ant-btn:not([disabled]):hover {
    text-decoration: none;
}

.ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none;
}

.ant-btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 2px;
}

.ant-btn:focus,
.ant-btn:hover {
    color: #40a9ff;
    border-color: #40a9ff;
    background: #fff;
}

.ant-btn:active {
    color: #096dd9;
    border-color: #096dd9;
    background: #fff;
}

.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
    text-decoration: none;
    background: #fff;
}

.ant-btn>span {
    display: inline-block;
}

.ant-btn-primary {
    color: #fff;
    border-color: #1890ff;
    background: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    color: #fff;
    border-color: #40a9ff;
    background: #40a9ff;
}

.ant-btn-primary:active {
    color: #fff;
    border-color: #096dd9;
    background: #096dd9;
}

.ant-btn-dashed {
    color: rgba(0, 0, 0, .85);
    border-color: #d9d9d9;
    background: #fff;
    border-style: dashed;
}

.ant-btn-dashed:focus,
.ant-btn-dashed:hover {
    color: #40a9ff;
    border-color: #40a9ff;
    background: #fff;
}

.ant-btn-dashed:active {
    color: #096dd9;
    border-color: #096dd9;
    background: #fff;
}

.ant-btn-link {
    color: #1890ff;
    border-color: transparent;
    background: 0 0;
    box-shadow: none;
}

.ant-btn-link:focus,
.ant-btn-link:hover {
    color: #40a9ff;
    border-color: #40a9ff;
    background: 0 0;
}

.ant-btn-link:active {
    color: #096dd9;
    border-color: #096dd9;
    background: 0 0;
}

.ant-btn-link:hover {
    background: 0 0;
}

.ant-btn-link:active,
.ant-btn-link:focus,
.ant-btn-link:hover {
    border-color: transparent;
}

.ant-btn:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: .35;
    transition: opacity .2s;
    content: "";
    pointer-events: none;
}

.ant-btn .anticon {
    transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-btn:active>span,
.ant-btn:focus>span {
    position: relative;
}

.ant-btn>.anticon+span {
    margin-left: 8px;
}

.ant-btn-block {
    width: 100%;
}

.ant-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: "\a0";
}

.ant-radio-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    display: inline-block;
    font-size: 0;
}

.ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 30px;
    background: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1.02px 1px 1px 0;
    cursor: pointer;
    transition: color .3s, background .3s, border-color .3s, box-shadow .3s;
}

.ant-radio-button-wrapper>.ant-radio-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.ant-radio-button-wrapper:not(:first-child):before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    box-sizing: content-box;
    width: 1px;
    height: 100%;
    padding: 1px 0;
    background-color: #d9d9d9;
    transition: background-color .3s;
    content: "";
}

.ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper:hover {
    position: relative;
    color: #1890ff;
}

.ant-radio-button-wrapper input[type=radio] {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #fff;
    border-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #40a9ff;
    border-color: #40a9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
    background-color: #40a9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #096dd9;
    border-color: #096dd9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active:before {
    background-color: #096dd9;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: #40a9ff;
}

.ant-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
}

.ant-card-body {
    padding: 24px;
}

.ant-card-body:after,
.ant-card-body:before {
    display: table;
    content: "";
}

.ant-card-body:after {
    clear: both;
}

.ant-row {
    flex-flow: row wrap;
}

.ant-row,
.ant-row:after,
.ant-row:before {
    display: flex;
}

.ant-row-middle {
    align-items: center;
}

.ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
}

.ant-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
}

.ant-col-xs-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
}

.ant-col-xs-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
}

.ant-col-xs-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
}

@media (min-width:576px) {
    .ant-col-sm-24 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .ant-col-sm-6 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (min-width:768px) {
    .ant-col-md-18 {
        display: block;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .ant-col-md-12 {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .ant-col-md-6 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (min-width:992px) {
    .ant-col-lg-12 {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .ant-col-lg-6 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (min-width:1200px) {
    .ant-col-xl-16 {
        display: block;
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
    }

    .ant-col-xl-14 {
        display: block;
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
    }

    .ant-col-xl-10 {
        display: block;
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
    }

    .ant-col-xl-8 {
        display: block;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }

    .ant-col-xl-6 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
    display: inline-flex;
}

.ant-input-affix-wrapper::placeholder {
    color: #bfbfbf;
}

.ant-input-affix-wrapper:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.ant-input-affix-wrapper:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    z-index: 1;
}

.ant-input-affix-wrapper:focus {
    z-index: 1;
}

.ant-input-affix-wrapper>input.ant-input {
    padding: 0;
    border: none;
    outline: none;
}

.ant-input-affix-wrapper>input.ant-input:focus {
    box-shadow: none !important;
}

.ant-input-affix-wrapper:before {
    width: 0;
    visibility: hidden;
    content: "\a0";
}

.ant-input-prefix {
    display: flex;
    flex: none;
    align-items: center;
}

.ant-input-prefix {
    margin-right: 4px;
}

.ant-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
}

.ant-input::placeholder {
    color: #bfbfbf;
}

.ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.ant-input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .ant-input {
        height: 32px;
    }

    .ant-input-affix-wrapper>input.ant-input {
        height: auto;
    }
}

.ant-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #f0f2f5;
}

.ant-layout,
.ant-layout * {
    box-sizing: border-box;
}

.ant-layout.ant-layout-has-sider {
    flex-direction: row;
}

.ant-layout.ant-layout-has-sider>.ant-layout {
    width: 0;
}

.ant-layout-footer,
.ant-layout-header {
    flex: 0 0 auto;
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, .85);
    line-height: 64px;
    background: #001529;
}

.ant-layout-footer {
    padding: 24px 50px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    background: #f0f2f5;
}

.ant-layout-content {
    flex: auto;
    min-height: 0;
}

.ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #001529;
    transition: all .2s;
}

.ant-layout-sider-children {
    height: 100%;
    margin-top: -.1px;
    padding-top: .1px;
}

.ant-layout-sider-light {
    background: #fff;
}

.ant-breadcrumb+.ant-page-header-heading {
    margin-top: 8px;
}

.ant-page-header-heading {
    display: flex;
    justify-content: space-between;
}

.ant-page-header-heading-title {
    margin-right: 12px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-progress {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    display: inline-block;
}

.ant-progress-line {
    position: relative;
    width: 100%;
    font-size: 14px;
}

.ant-progress-small.ant-progress-line {
    font-size: 12px;
}

.ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
}

.ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em + 8px);
}

.ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 100px;
}

.ant-progress-bg {
    position: relative;
    background-color: #1890ff;
    border-radius: 100px;
    transition: all .4s cubic-bezier(.08, .82, .17, 1) 0s;
}

.ant-progress-text {
    display: inline-block;
    width: 2em;
    margin-left: 8px;
    color: rgba(0, 0, 0, .85);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal;
}

.ant-progress-status-active .ant-progress-bg:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 10px;
    opacity: 0;
    -webkit-animation: ant-progress-active 2.4s cubic-bezier(.23, 1, .32, 1) infinite;
    animation: ant-progress-active 2.4s cubic-bezier(.23, 1, .32, 1) infinite;
    content: "";
}

.ant-progress-status-exception .ant-progress-bg {
    background-color: #ff4d4f;
}

.ant-progress-status-exception .ant-progress-text {
    color: #ff4d4f;
}

.ant-timeline {
    box-sizing: border-box;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum", "tnum";
    margin: 0;
    padding: 0;
    list-style: none;
}

.ant-timeline-item {
    position: relative;
    margin: 0;
    padding-bottom: 20px;
    font-size: 14px;
    list-style: none;
}

.ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px solid #f0f0f0;
}

.ant-timeline-item-pending .ant-timeline-item-head {
    font-size: 12px;
    background-color: transparent;
}

.ant-timeline-item-pending .ant-timeline-item-tail {
    display: none;
}

.ant-timeline-item-head {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 100px;
}

.ant-timeline-item-head-blue {
    color: #1890ff;
    border-color: #1890ff;
}

.ant-timeline-item-head-green {
    color: #52c41a;
    border-color: #52c41a;
}

.ant-timeline-item-head-gray {
    color: rgba(0, 0, 0, .25);
    border-color: rgba(0, 0, 0, .25);
}

.ant-timeline-item-head-custom {
    position: absolute;
    top: 5.5px;
    left: 5px;
    width: auto;
    height: auto;
    margin-top: 0;
    padding: 3px 1px;
    line-height: 1;
    text-align: center;
    border: 0;
    border-radius: 0;
    transform: translate(-50%, -50%);
}

.ant-timeline-item-content {
    position: relative;
    top: -7.001px;
    margin: 0 0 0 26px;
    word-break: break-word;
}

.ant-timeline-item-last>.ant-timeline-item-tail {
    display: none;
}

.ant-timeline-item-last>.ant-timeline-item-content {
    min-height: 48px;
}

.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
    display: block;
    height: calc(100% - 14px);
    border-left: 2px dotted #f0f0f0;
}

.ant-typography {
    color: rgba(0, 0, 0, .85);
    overflow-wrap: break-word;
}

div.ant-typography {
    margin-bottom: 1em;
}

h3.ant-typography {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
}

h4.ant-typography {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
}

h5.ant-typography {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
}

.ant-typography+h5.ant-typography {
    margin-top: 1.2em;
}

.ant-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    outline: 0;
}

.ant-upload input[type=file] {
    cursor: pointer;
}

.ant-upload.ant-upload-select {
    display: inline-block;
}

.ant-upload-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    line-height: 1.5715;
}

.ant-upload-list:after,
.ant-upload-list:before {
    display: table;
    content: "";
}

.ant-upload-list:after {
    clear: both;
}

/*! CSS Used from: https://demos.creative-tim.com/muse-ant-design-dashboard/static/css/main.ffe8258a.chunk.css */
.ant-layout.ant-layout-has-sider {
    flex-direction: row;
}

.layout-dashboard {
    background: 0 0;
    text-align: left;
    position: relative;
    overflow-x: hidden;
}

@media (min-width:992px) {
    .layout-dashboard {
        overflow: auto;
    }
}

.layout-dashboard .ant-layout-sider.sider-primary {
    width: 250px;
    position: fixed;
    left: 0;
    z-index: 99;
    height: 100vh;
    overflow: auto;
    margin: 0;
    padding: 33px 20px;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand img {
    height: 30px;
}

.layout-dashboard .ant-layout-sider.sider-primary hr {
    margin: 18px 0;
    border: none;
    height: 1px;
    background-color: #f5f5f5;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand {
    font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand span {
    vertical-align: middle;
    margin-left: 3px;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
    width: auto;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline {
    border: none;
}

.layout-dashboard .ant-menu {
    background: 0 0;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item {
    margin: 0;
    overflow: visible;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item {
    padding: 0 !important;
    height: auto;
    line-height: normal;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item:after {
    display: none;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item:active {
    background-color: transparent;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active {
    background-color: #fff;
    box-shadow: 0 20px 27px rgb(0 0 0/5%);
    font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a {
    padding: 10px 16px;
    color: #141414;
    border-radius: 8px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .ant-menu-item .active .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active .icon {
    background-color: #1890ff;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .icon {
    display: inline-flex;
    width: 32px;
    height: 32px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgb(0 0 0/12%);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
    vertical-align: middle;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active svg path {
    fill: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item svg path {
    fill: #bfbfbf;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover {
    color: #141414;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item.menu-item-header {
    padding: 10px 16px !important;
    color: #8c8c8c;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    display: block;
}

.menu-item-header:hover {
    color: inherit;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box {
    background-color: #1890ff;
    color: #fff;
    box-shadow: none;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box {
    background-color: #fff;
    color: #141414;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 20px 27px rgb(0 0 0/5%);
    margin-bottom: 8px;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box .icon {
    background-color: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box .icon {
    display: inline-flex;
    width: 32px;
    height: 32px;
    box-shadow: 0 4px 6px rgb(0 0 0/12%);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    background-color: #1890ff;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box h6 {
    color: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box h6 {
    font-weight: 600;
    font-size: 16px;
    color: #141414;
    margin-bottom: 0;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box p {
    color: #f5f5f5;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box p {
    color: #8c8c8c;
    font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box button {
    background-color: #fff;
    border-color: #f0f0f0;
    color: #141414;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box button {
    margin: 0;
}

.ant-btn-sm {
    height: 34px;
    font-size: 12px;
    padding: 0 15px;
}

.ant-btn-sm {
    border-radius: 4px;
}

.layout-dashboard .ant-layout-sider.sider-primary .aside-footer {
    padding-top: 100px;
    padding-bottom: 33px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
    display: block;
}

.ant-layout.ant-layout-has-sider>.ant-layout {
    overflow-x: hidden;
}

.layout-dashboard .ant-layout {
    background: 0 0;
    position: relative;
    min-height: 100vh;
    width: 100%;
    flex-shrink: 0;
}

.layout-dashboard .ant-layout-header {
    background: 0 0;
    height: auto;
    padding: 16px;
    margin: 10px 4px;
    line-height: inherit;
    border-radius: 12px;
    transition: .2s;
}

.layout-dashboard .ant-breadcrumb>span .ant-breadcrumb-link a {
    color: #8c8c8c;
}

.layout-dashboard .ant-breadcrumb>span:last-child .ant-breadcrumb-link {
    color: #141414;
}

.layout-dashboard .ant-page-header-heading {
    margin-top: 0;
}

.layout-dashboard .ant-page-header-heading .ant-page-header-heading-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 4px;
}

.layout-dashboard .header-control {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 10px;
}

h3,
h4 {
    font-weight: 700;
    color: #141414;
}

.layout-dashboard .header-control .ant-btn-link {
    height: auto;
    padding: 0 7px;
    margin: 0;
    box-shadow: none;
    color: #8c8c8c;
    line-height: normal;
}

.ant-btn svg {
    margin-right: 5px;
}

.layout-dashboard .header-control svg {
    width: 20px;
    height: 20px;
}

.layout-dashboard .header-control svg path {
    fill: #141414;
}

.layout-dashboard .header-control .btn-sign-in {
    height: auto;
    padding: 0 7px;
    margin: 0;
    box-shadow: none;
    color: #8c8c8c;
}

.layout-dashboard .header-control .btn-sign-in svg {
    margin-right: 4px;
}

.layout-dashboard .header-control svg {
    vertical-align: middle;
}

.layout-dashboard .header-control .header-search {
    width: 213px;
    margin: 0 7px 0 0;
    border-radius: 6px;
    height: 40px;
    border: 1px solid #d9d9d9;
}

.layout-dashboard .header-control .anticon.anticon-search svg {
    width: 16px;
    height: 16px;
}

.layout-dashboard .header-control .header-search svg path {
    fill: #8c8c8c;
}

p {
    font-size: 14px;
}

h6 {
    font-size: 16px;
}

.layout-dashboard .header-control .sidebar-toggler {
    display: block;
}

.layout-dashboard .header-control .btn-sign-in span {
    font-weight: 600;
    vertical-align: middle;
    display: none;
}

hr:not([size]) {
    height: 1px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: visible;
    text-overflow: ellipsis;
}

.ant-layout {
    background: #fafafa;
}

.ant-menu-inline {
    border: 0;
}

.bnb2 {
    color: #52c41a;
    font-weight: 700;
}

.ant-card.criclebox {
    box-shadow: 0 20px 27px rgba(0, 0, 0, .050980392156862744);
    border-radius: 12px;
}

.number span {
    font-weight: 600;
    color: #8c8c8c;
    font-size: 14px;
}

.number h3 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 30px;
}

.number h3 small {
    font-weight: 600;
    font-size: 14px;
}

.icon-box {
    width: 48px;
    height: 48px;
    text-align: center;
    background: #1890ff;
    color: #fff;
    border-radius: .5rem;
    margin-left: auto;
    line-height: 55px;
}

.bar-chart {
    background: transparent linear-gradient(62deg, #00369e, #005cfd 53%, #a18dff) 0 0 no-repeat padding-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .12156862745098039);
    border-radius: 8px;
}

.chart-vistior {
    margin-top: 30px;
}

.chart-vistior h5 {
    margin: 0;
    font-weight: 700;
}

.ant-typography.lastweek {
    color: #8c8c8c;
    font-weight: 600;
}

.chart-visitor-count h4 {
    margin: 0;
    font-weight: 700;
}

.chart-visitor-count span {
    color: rgba(0, 0, 0, .65);
    font-weight: 600;
}

.linechart h5 {
    font-weight: 700;
    margin: 0;
}

.linechart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sales ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sales ul li:first-child span.anticon {
    color: #b37feb;
}

.sales ul li:last-child span.anticon {
    color: #1890ff;
}

.sales ul li {
    color: #8c8c8c;
    margin-bottom: 3px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.project-ant h5 {
    margin: 0;
    font-weight: 700;
    color: #141414;
}

span.blue {
    margin-left: 5px;
    color: #1890ff;
    font-weight: 600;
}

.project-ant {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-list-box th {
    padding: 8px 0;
    color: #8c8c8c;
}

.ant-list-box td,
.ant-list-box th {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, .050980392156862744);
    font-size: 12px;
    font-weight: 600;
}

.ant-list-box td {
    padding: 16px 25px;
    white-space: nowrap;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.tootip-img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 1px solid #fff;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.avatar-group .tootip-img+.tootip-img {
    margin-left: -10px;
}

.tootip-img:hover {
    z-index: 3;
    cursor: pointer;
}

.text-xs {
    color: #8c8c8c;
}

.percent-progress span.ant-progress-text {
    display: flex;
    margin-top: -27px;
    color: #8c8c8c;
    position: relative;
    margin-left: 0;
}

.percent-progress .ant-progress-status-exception .ant-progress-text {
    color: #ff4d4f;
}

.ant-full-box {
    width: 100%;
    border: 1px dashed #d9d9d9;
    padding: 10px;
    height: auto;
    color: #141414;
    border-radius: 10px;
}

.uploadfile {
    margin-top: 15px;
}

.uploadfile .ant-upload.ant-upload-select {
    display: block;
}

.ant-timeline-item-tail {
    left: 7px;
}

.timeline-box h5 {
    margin-bottom: 0;
    font-weight: 700;
}

.timelinelist h5 {
    font-size: 14px;
    font-weight: 600;
}

.timelinelist span {
    color: #8c8c8c;
    font-size: 12px;
}

.ant-muse span {
    color: #8c8c8c;
    font-weight: 600;
}

.ant-muse h5 {
    margin: 0 0 15px !important;
    font-size: 20px;
}

img {
    max-width: 100%;
}

.border10 {
    border-radius: 10px;
}

.icon-move-right .anticon-right {
    color: #40a9ff;
    margin-left: 2px;
}

.icon-move-right {
    color: #1890ff;
    display: block;
}

.gradent {
    background: url(https://demos.creative-tim.com/muse-ant-design-dashboard/static/media/info-card-2.9a5119d1.jpg) no-repeat top;
    background-size: cover;
    padding: 15px;
    border-radius: 15px;
}

.gradent,
.gradent h5 {
    color: #fff;
}

.gradent .ant-typography {
    color: #fff;
    font-size: 20px;
}

.gradent .icon-move-right,
.gradent .icon-move-right .anticon-right {
    color: #fff;
}

.copyright {
    color: #8c8c8c;
    margin-top: 7px;
}

.copyright .anticon-heart {
    color: #f5222d;
    margin: 0 5px;
}

.copyright a {
    margin: 0 5px;
    color: #141414;
    font-weight: 700;
}

.footer-menu ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footer-menu ul li {
    list-style: none;
}

.footer-menu ul li a {
    padding: .5rem 1rem;
    display: block;
    color: #8c8c8c;
}

@media (max-width:992px) {
    .footer-menu ul {
        justify-content: center;
    }

    footer.ant-layout-footer {
        padding: 0 15px;
        text-align: center;
    }

    .project-ant {
        display: block;
    }

    .ant-filtertabs {
        text-align: right;
    }

    main.ant-layout-content.content-ant {
        padding-left: 0 !important;
    }

    .ant-row {
        margin-left: -12px;
        margin-right: -12px;
    }

    .bar-chart {
        max-width: 100%;
    }

    .full-width {
        max-width: 100% !important;
    }

    .bar-chart {
        width: 100% !important;
    }

    .icon-move-right {
        margin-bottom: 15px;
    }

    .ant-cret img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

.setting-drwer {
    background: #fff;
    border-radius: 50%;
    bottom: 42px;
    right: 30px;
    font-size: 1.25rem;
    z-index: 990;
    box-shadow: 0 2px 12px 0 rgb(0 0 0/16%);
    width: 52px;
    height: 52px;
    text-align: center;
    line-height: 60px;
    position: fixed;
    cursor: pointer;
}

.aside-footer {
    padding-top: 100px;
}

.ant-card-body {
    padding: 16px;
}

.cardbody .ant-card-body {
    padding-left: 0;
    padding-right: 0;
}

.ant-card.criclebox .project-ant {
    padding-left: 24px;
    padding-right: 24px;
}

.ant-card.criclebox table th {
    padding: 14px 24px;
}

.percent-progress .ant-progress-bg {
    height: 3px !important;
}

.uploadfile {
    padding-left: 24px;
    padding-right: 24px;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
}

.mb-24 {
    margin-bottom: 24px;
}

.avatar-sm {
    width: 25px;
}

.h-full {
    height: 100%;
}

@media (min-width:1200px) {
    .ant-col.col-img img {
        height: 196px;
    }
}

@media (max-width:1024px) {

    .bar-chart,
    .full-width {
        max-width: 100%;
    }
}

.card-info-2 .ant-card-body {
    height: 100%;
}

.col-content {
    flex-grow: 1;
    padding: 24px;
    margin-right: 0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-content p {
    font-size: 16px;
}

.ant-layout-footer {
    padding: 0 50px;
}

.text-muted {
    color: #8c8c8c;
}

.ant-input {
    border-radius: 6px;
    font-weight: 600;
    color: #8c8c8c;
    height: 40px;
    border: 1px solid #d9d9d9;
}

.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
    font-weight: 600;
    font-size: 12px;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
}

.ant-btn {
    box-shadow: 0 2px 4px rgb(0 0 0/7%);
    border-radius: 6px;
}

.text-dark {
    color: #141414;
}

.layout-dashboard .ant-layout-footer {
    background: 0 0;
    margin: 0 20px 20px;
    padding: 0;
}

.layout-dashboard .ant-layout-content {
    padding: 0;
    margin: 0 20px;
}

.mr-10 {
    margin-right: 10px !important;
}

.layout-dashboard .header-control .header-search input.ant-input {
    height: auto;
}

small.redtext {
    color: red;
}

.full-width path {
    fill: transparent;
}

.bar-chart line.apexcharts-xaxis-tick {
    stroke: transparent;
}

.layout-dashboard .ant-card {
    border-radius: 12px;
    box-shadow: 0 20px 27px rgb(0 0 0/5%);
}

.width-100 {
    width: 100%;
}

.p-20 {
    padding: "0px 20px 0px 0px";
}

.ant-btn>svg {
    vertical-align: middle;
}

.ant-radio-button-wrapper {
    color: #595959;
}

.ant-btn-link {
    box-shadow: none;
}

.text-right {
    text-align: right;
}

.ant-timeline-item-head {
    width: 15px;
    height: 15px;
    border-width: 3px;
}

.ant-list-box table tr:hover td {
    background: #fafafa;
}

.uploadfile.shadow-none .ant-upload.ant-upload-select.ant-upload-select-text .ant-btn.ant-full-box {
    box-shadow: none;
}

.ant-btn-dashed {
    color: rgba(0, 0, 0, .65);
}

button.ant-btn.ant-btn-dashed.ant-full-box svg {
    width: 16px;
    height: 16px;
}

.ant-btn-dashed:active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9;
}

@media (min-width:992px) {
    .layout-dashboard .ant-layout-footer {
        margin: 0 0 20px 20px;
    }

    .layout-dashboard .ant-layout-sider.sider-primary {
        margin: 20px 0 0 20px;
        padding: 13px 20px;
        height: calc(100vh - 20px);
    }

    .layout-dashboard .ant-layout {
        width: auto;
        flex-shrink: 1;
        margin-left: 270px;
    }

    .layout-dashboard .header-control .sidebar-toggler {
        display: none;
    }
}

@media (min-width:768px) {
    .layout-dashboard .ant-layout-header {
        margin: 10px 20px;
    }

    .layout-dashboard .header-control {
        margin-top: 0;
    }

    .layout-dashboard .header-control .header-search {
        margin: 0 7px;
    }

    .layout-dashboard .header-control .btn-sign-in span {
        display: inline;
    }
}

/*! CSS Used from: Embedded */
.apexcharts-canvas {
    position: relative;
    user-select: none;
}

.apexcharts-canvas ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
}

.apexcharts-canvas ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.apexcharts-inner {
    position: relative;
}

.apexcharts-text tspan {
    font-family: inherit;
}

.apexcharts-tooltip {
    border-radius: 5px;
    box-shadow: 2px 2px 6px -4px #999;
    cursor: default;
    font-size: 14px;
    left: 62px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    z-index: 12;
    transition: 0.15s ease all;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid #e3e3e3;
    background: rgba(255, 255, 255, 0.96);
}

.apexcharts-tooltip * {
    font-family: inherit;
}

.apexcharts-tooltip-title {
    padding: 6px;
    font-size: 15px;
    margin-bottom: 4px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #ECEFF1;
    border-bottom: 1px solid #ddd;
}

.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-z-value {
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
}

.apexcharts-tooltip-text-y-label:empty,
.apexcharts-tooltip-text-y-value:empty,
.apexcharts-tooltip-text-goals-label:empty,
.apexcharts-tooltip-text-goals-value:empty,
.apexcharts-tooltip-text-z-value:empty {
    display: none;
}

.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-z-value {
    font-weight: 600;
}

.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
    padding: 6px 0 5px;
}

.apexcharts-tooltip-goals-group,
.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
    display: flex;
}

.apexcharts-tooltip-text-goals-label:not(:empty),
.apexcharts-tooltip-text-goals-value:not(:empty) {
    margin-top: -6px;
}

.apexcharts-tooltip-marker {
    width: 12px;
    height: 12px;
    position: relative;
    top: 0px;
    margin-right: 10px;
    border-radius: 50%;
}

.apexcharts-tooltip-series-group {
    padding: 0 10px;
    display: none;
    text-align: left;
    justify-content: left;
    align-items: center;
}

.apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
    opacity: 1;
}

.apexcharts-tooltip-series-group.apexcharts-active,
.apexcharts-tooltip-series-group:last-child {
    padding-bottom: 4px;
}

.apexcharts-tooltip-y-group {
    padding: 6px 0 5px;
}

.apexcharts-xaxistooltip {
    opacity: 0;
    padding: 9px 10px;
    pointer-events: none;
    color: #373d3f;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    background: #ECEFF1;
    border: 1px solid #90A4AE;
    transition: 0.15s ease all;
}

.apexcharts-xaxistooltip:after,
.apexcharts-xaxistooltip:before {
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.apexcharts-xaxistooltip:after {
    border-color: rgba(236, 239, 241, 0);
    border-width: 6px;
    margin-left: -6px;
}

.apexcharts-xaxistooltip:before {
    border-color: rgba(144, 164, 174, 0);
    border-width: 7px;
    margin-left: -7px;
}

.apexcharts-xaxistooltip-bottom:after,
.apexcharts-xaxistooltip-bottom:before {
    bottom: 100%;
}

.apexcharts-xaxistooltip-bottom:after {
    border-bottom-color: #ECEFF1;
}

.apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: #90A4AE;
}

.apexcharts-yaxistooltip {
    opacity: 0;
    padding: 4px 10px;
    pointer-events: none;
    color: #373d3f;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    background: #ECEFF1;
    border: 1px solid #90A4AE;
}

.apexcharts-yaxistooltip:after,
.apexcharts-yaxistooltip:before {
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.apexcharts-yaxistooltip:after {
    border-color: rgba(236, 239, 241, 0);
    border-width: 6px;
    margin-top: -6px;
}

.apexcharts-yaxistooltip:before {
    border-color: rgba(144, 164, 174, 0);
    border-width: 7px;
    margin-top: -7px;
}

.apexcharts-yaxistooltip-left:after,
.apexcharts-yaxistooltip-left:before {
    left: 100%;
}

.apexcharts-yaxistooltip-left:after {
    border-left-color: #ECEFF1;
}

.apexcharts-yaxistooltip-left:before {
    border-left-color: #90A4AE;
}

.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs {
    pointer-events: none;
    opacity: 0;
    transition: 0.15s ease all;
}

.apexcharts-ycrosshairs-hidden {
    opacity: 0;
}

.apexcharts-selection-rect {
    cursor: move;
}

.apexcharts-datalabels {
    cursor: default;
    pointer-events: none;
}

.apexcharts-gridline,
.apexcharts-area-series .apexcharts-area,
.apexcharts-zoom-rect,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events {
    pointer-events: none;
}

.apexcharts-marker {
    transition: 0.15s ease all;
}

.resize-triggers {
    animation: 1ms resizeanim;
    visibility: hidden;
    opacity: 0;
}

.resize-triggers,
.resize-triggers>div,
.contract-trigger:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.resize-triggers>div {
    background: #eee;
    overflow: auto;
}

.contract-trigger:before {
    width: 200%;
    height: 200%;
}

/*! CSS Used keyframes */
@-webkit-keyframes loadingCircle {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loadingCircle {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loadingCircle {
    to {
        transform: rotate(1turn);
    }
}

@keyframes loadingCircle {
    to {
        transform: rotate(1turn);
    }
}

@-webkit-keyframes ant-progress-active {
    0% {
        transform: translateX(-100%) scaleX(0);
        opacity: .1;
    }

    20% {
        transform: translateX(-100%) scaleX(0);
        opacity: .5;
    }

    to {
        transform: translateX(0) scaleX(1);
        opacity: 0;
    }
}

@keyframes ant-progress-active {
    0% {
        transform: translateX(-100%) scaleX(0);
        opacity: .1;
    }

    20% {
        transform: translateX(-100%) scaleX(0);
        opacity: .5;
    }

    to {
        transform: translateX(0) scaleX(1);
        opacity: 0;
    }
}