.slick-arrow .slick-next{
    color:white !important;
}
.slick-arrow  {
    color:white !important;

}
.slick-prev{
    color:white !important;

}
.slick-disabled{
    color:white !important;

}