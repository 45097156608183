/* .demo{

    color: white;
    border: 3px solid blue;
    margin: 5px;
    background-color: black;
    width: 200px;
    
}
.demo1{
    background: url("https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk2MC1uaW5nLTMwLmpwZw.jpg");
    
} */
/* .Columncard{
    column-count: calc(3);
}

.card{
    
    border-radius: 10%;
   
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.3s;
    animation: ease-in;
    

}

.card:hover{
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}

.Cardtitle{
    
    margin-bottom: 00.5rem;
    
}




.card-img-top{
overflow: hidden;
height: 150px;

}

.card-text{
    margin: 1rem;
    margin-top: 0.5rem;

}

h3,p{
    margin: 0;
    padding: 0;

}
.btn-primary{
    padding: 0px;
    display: flex;
    justify-content: center;
    margin:auto;

} */

/* 
*{margin:0;outline:none;padding:0;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;}
[class^=mqfisrp-]:before{font-family:fonticonssrp;}
[class^=mqfisrp-]:before{display:inline-block;speak:none;font-style:normal;font-weight:400;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.mqfisrp-location-nofill:before{content:"\E801";}
.mqfisrp-file-info:before{content:"\E806";}
.mqfisrp-skill:before{content:"\E807";}
.mqfisrp-time:before{content:"\E80A";}
.mqfisrp-bookmark:before{content:"\E833";}
::-webkit-scrollbar-corner{background:rgba(0,0,0,.5);}
*{scrollbar-width:thin;scrollbar-color:var(--scroll-bar-color) var(--scroll-bar-bg-color);}
::-webkit-scrollbar{width:12px;height:12px;}
::-webkit-scrollbar-track{background:var(--scroll-bar-bg-color);}
::-webkit-scrollbar-thumb{background-color:var(--scroll-bar-color);border-radius:20px;border:3px solid var(--scroll-bar-bg-color);}
.cardContainer{width: 250px;;background:#fff;border:1px solid #f4f4f5;box-shadow:0 4px 8px -2px rgba(0,0,0,.08),0 2px 2px -2px rgba(0,0,0,.15);border-radius:16px;position:relative;padding:12px;cursor:pointer;}
.cardContainer.activeCard{background:rgba(70, 84, 231, 0.4);border:1px solid #06000a;}
@media only screen and (min-width:768px){
.cardContainer{width: 50%;}
}
.cardContainer .cardHead{display:flex;cursor:pointer;gap:10px;padding-top:16px;}
.cardContainer .cardHead .infoSectionFullWidth{width:100%!important;}
@media only screen and (min-width:768px){
.cardContainer .cardHead .infoSectionFullWidth{width:70%!important;}
}
.cardContainer .cardHead .infoSection{font-size:14px;display:flex;flex-direction:column;justify-content:center;width:70%;word-break:break-word;}
.cardContainer .cardHead .infoSection>div{display:flex;align-items:center;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
.cardContainer .cardHead .infoSection .jobTitle{font-weight:600;color:#1d1934;margin-bottom:5px;line-height:22px;}
.cardContainer .cardHead .infoSection .companyName{color:#777585;display:flex;gap:5px;align-items:center;}
.cardContainer .srpCardHeader{padding-top:0!important;}
.cardContainer .cardBody{font-size:12px;color:#777585;margin-top:12px;}
.cardContainer .cardBody .bodyRow{margin-bottom:6px;height:18px;display:flex;align-items:center;}
.cardContainer .cardBody .bodyRow .iconContainer{display:flex;align-items:center;justify-content:center;max-width:16px;min-width:16px;height:16px;}
.cardContainer .cardBody .bodyRow .details{margin-left:6px;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;line-height:25px;}
.cardContainer .cardBody .bodyRow .skillDetails{display:flex;white-space:nowrap;overflow:hidden;margin-left:6px;}
.cardContainer .cardBody .bodyRow .skillDetails .skill{height:20px;margin-right:4px;display:flex;align-items:center;background:#f4f4f5;border-radius:16px;padding:1px 6px;}
.cardContainer .cardFooter{display:flex;justify-content:space-between;margin-top:12px;}
.cardContainer .cardFooter .menuContainer{display:flex;align-items:center;display:none;}
.cardContainer .cardFooter .menuContainer .circle{width:5px;height:5px;background:gray;border-radius:2px;margin-left:2px;}
.cardContainer .cardFooter .placeAtLeft{display:flex;}
.cardContainer .cardFooter .placeAtLeft .jobAddedTime{display:flex;align-items:center;font-size:12px;}
.cardContainer .cardFooter .placeAtLeft .jobAddedTime i{color:#d2d1d6;}
.cardContainer .cardFooter .placeAtLeft .jobAddedTime .timeText{margin-left:6px;color:#371ce0;}
.cardContainer .cardFooter .rightIconSec{display:flex;gap:10px;}
.cardContainer .cardFooter .rightIconSec i{color:#6e00be;cursor:pointer;}
.cardContainer .bigFontSize{font-size:14px;}
.srpContainer .srpContent .srpCardContainer .srpResultCardContainer{margin:16px 12px;display:flex;justify-content:center;align-items:center;}
@media only screen and (min-width:768px){
.srpContainer .srpContent .srpCardContainer .srpResultCardContainer{margin:16px 16px 16px 0;}
}
@media only screen and (max-width:1165px) and (min-width:768px){
.srpContainer .srpContent .srpCardContainer .srpResultCardContainer{margin-left:16px;}
}
/*! CSS Used from: https://media.monsterindia.com/trex/public/theme_3/dist/css/app.css?v=20221121165416 */
/* *{margin:0;outline:none;padding:0;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;}
::-webkit-scrollbar-corner{background:rgba(0,0,0,.5);}
*{scrollbar-width:thin;scrollbar-color:var(--scroll-bar-color) var(--scroll-bar-bg-color);}
::-webkit-scrollbar{width:8px;height:12px;}
::-webkit-scrollbar-track{background:var(--scroll-bar-bg-color);}
::-webkit-scrollbar-thumb{background-color:var(--scroll-bar-color);border-radius:20px;border:3px solid var(--scroll-bar-bg-color);}
/*! CSS Used from: https://media.monsterindia.com/trex/public/default/dist/css/app-hf-service.css?v=20221121165416 */
/* *{margin:0;outline:none;padding:0;font-weight:400;list-style:none;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;}
p{line-height:24px;}
i{font-style:normal;}
*{text-rendering:optimizeLegibility;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
/*! CSS Used fontfaces */
/* @font-face{font-family:fonticonssrp;src:url(https://media.monsterindia.com/trex/search/public/fonts/fonticonssrp.eot?001);src:url(https://media.monsterindia.com/trex/search/public/fonts/fonticonssrp.eot?001#iefix) format("embedded-opentype");src:url(https://media.monsterindia.com/trex/search/public/fonts/fonticonssrp.woff?001) format("woff"),url(https://media.monsterindia.com/trex/search/public/fonts/fonticonssrp.ttf?001) format("truetype"),url(https://media.monsterindia.com/trex/search/public/fonts/fonticonssrp.svg?001#fonticonssrp) format("svg");font-weight:400;font-style:normal;font-display:swap;}  */

/*! CSS Used from: Embedded */
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline-width: 0px;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  box-sizing: content-box;
  height: 0px;
  overflow: visible;
}
button {
  font: inherit;
  margin: 0px;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
button {
  appearance: button;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
* {
  box-sizing: inherit;
}
::before {
  box-sizing: inherit;
}
::after {
  box-sizing: inherit;
}
img {
  max-width: 100%;
  margin: 0px;
  padding: 0px;
}
h3 {
  margin: 0px 0px 1.45rem;
  padding: 0px;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizelegibility;
  font-size: 1.38316rem;
  line-height: 1.1;
}
p {
  margin: 0px 0px 1.45rem;
  padding: 0px;
}
noscript {
  margin: 0px 0px 1.45rem;
  padding: 0px;
}
hr {
  margin: 0px 0px calc(1.45rem - 1px);
  padding: 0px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  height: 1px;
}
.dicxXJ {
  font-size: 0.875rem;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: all;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(197, 37, 37);
  font-family: "Fira Sans", sans-serif;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(10, 10, 10);
  width: auto;
  padding: 8px;
  height: 32px;
  font-weight: 500;
  border-radius: 5px;
}
.dicxXJ:hover {
  box-shadow: rgba(11, 207, 11, 0.12) 0px 4px 6px -4px,
    rgba(24, 39, 75, 0.08) 0px 8px 8px -4px;
  background: transparent;
  color: rgb(33, 11, 238);
  border-radius: 5px;
}
.dicxXJ:disabled {
  background: rgb(150, 200, 241);
  border: none;
}
.cfYQjs {
  font-size: 0.875rem;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: all;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-family: "Fira Sans", sans-serif;
  width: auto;
  padding: 0px 8px;
  border-radius: 2px;
  font-weight: 500;
  color: rgb(43, 183, 146);
  height: fit-content;
  border: none;
  background: inherit;
  min-width: auto;
}
.cfYQjs > * + * {
  margin-left: 4px;
}
.cfYQjs:hover {
  box-shadow: rgba(24, 39, 75, 0.12) 0px 4px 6px -4px,
    rgba(24, 39, 75, 0.08) 0px 8px 8px -4px;
  background: transparent;
  color: rgb(43, 183, 146);
}
.cfYQjs:hover {
  box-shadow: none;
  color: rgb(68, 68, 68);
}
.cfYQjs:disabled {
  background: rgb(218, 224, 229);
  border: none;
}
.fQVZBI {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 450px;
  padding: 8px;
  border-bottom: 1px solid rgb(218, 224, 229);
  border-top-color: rgb(218, 224, 229);
  border-right-color: rgb(218, 224, 229);
  border-left-color: rgb(218, 224, 229);
  background-color: rgb(255, 255, 255);
  font-family: "Fira Sans", sans-serif;
  cursor: pointer;
}
@media (min-width: 768px) {
  .fQVZBI {
    border: 2px solid rgb(14, 15, 15);
    border-radius: 25px;
    margin: 5px;
    background-color: whitesmoke;
  }
}
.cOaAiC {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.bErjuq {
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: max-content;
  gap: 32px;
  align-items: flex-end;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.kMSQpi {
  font-size: 1rem;
  color: rgb(68, 68, 68);
  margin-bottom: 0px;
}
@media (min-width: 640px) {
  .kMSQpi {
    font-size: 18px;
  }
}
.jWtSuf {
  margin-bottom: 0px;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(68, 68, 68);
  line-height: 1;
}
@media (min-width: 640px) {
  .jWtSuf {
    font-size: 1.125rem;
  }
}
.jWtSuf a {
  text-decoration: none;
  color: rgb(68, 68, 68);
}
.oixmW {
  margin: 4px 0px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 14px;
  color: rgb(107, 124, 143);
}
.hdJXNf {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  margin: 10px 0px;
}
.gQfvfz {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 3px;
  line-height: 1;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: rgb(0, 0, 0);
}
.gQfvfz > * + * {
  margin-left: 4px;
}
.iPdyPl {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.iPdyPl > * + * {
  margin-left: 10px;
}
.cFjaBK {
  display: flex;
}
.cFjaBK > * + * {
  margin-left: 8px;
}
.dOKGCm {
  padding: 0px;
  color: black;
}
.BrkUH {
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  gap: 0px;
  margin: auto;
}
@media (min-width: 768px) {
  .BrkUH {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 0.75rem;
  }
}
@media (min-width: 768px) {
  .khjqUu {
    display: none;
  }
}
.jylA-dX {
  display: none;
}
@media (min-width: 768px) {
  .jylA-dX {
    display: block;
  }
}
.kwKUZu {
  display: block;
  position: relative;
  height: 240px;
}
.jXJJOe {
  background: rgb(245, 247, 251);
  border-radius: 4px;
  height: 170px;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.jXJJOe .iconTitleContainer {
  display: flex;
  flex-direction: row;
  color: rgb(49, 68, 90);
  flex: 1 1 0%;
}
.jXJJOe .iconContainer {
  display: grid;
  place-items: center;
  flex: 1 1 0%;
}
.jXJJOe .textContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 3 1 0%;
}
.jXJJOe .textContainer .titleText {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.jXJJOe .textContainer .subtitleText {
  font-size: 14px;
  line-height: 17px;
  padding-right: 12px;
}
.jXJJOe .toggleContainer {
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
}
.jXJJOe .allowJobAlertsContainer {
  border: 2px solid rgb(43, 183, 146);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: rgb(43, 183, 146);
  padding: 2px 15px;
  margin: 5px 20px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
}
.jXJJOe .allowJobAlertsContainer:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(43, 183, 146);
  transition: all 0.3s ease 0s;
}
.jXJJOe .hrContainer {
  height: 1px;
  display: grid;
  place-items: center;
}
.jXJJOe .hrContainer hr {
  width: 90%;
}
* {
  -webkit-tap-highlight-color: transparent;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v16/va9E4kDNxMZdWfMOD5Vvl4jLazX3dA.woff2)
    format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
}
@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnZKveRhf6Xl7Glw.woff2)
    format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
}
@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnSKzeRhf6Xl7Glw.woff2)
    format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
}
@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/firasans/v16/va9B4kDNxMZdWfMOD5VnMK7eRhf6Xl7Glw.woff2)
    format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
}
@font-face {
  font-family: "Fira Sans";
  font-display: fallback;
}

.cards:hover{
  transition: all 0.5s ease-in;
  transform: scale(1.08);
}

/*! CSS Used from: Embedded */
button {
  font: inherit;
  margin: 0px;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
button,
html [type="button"] {
  appearance: button;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
* {
  box-sizing: inherit;
}
::before {
  box-sizing: inherit;
}
::after {
  box-sizing: inherit;
}
ul {
  margin: 0px 0px 1.45rem 1.45rem;
  padding: 0px;
  list-style-position: outside;
  list-style-image: none;
}
li {
  margin-bottom: calc(0.725rem);
}
ul li {
  padding-left: 0px;
}
li :last-child {
  margin-bottom: 0px;
}
.bSukqJ {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}
.dHEpOh {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.jTAuNQ {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.jTAuNQ > * + * {
  margin-left: 8px;
}
.fpafrg {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  background: rgb(245, 247, 251);
  color: rgb(83, 101, 121);
}
.fpafrg:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.fpafrg:disabled {
  opacity: 0.7;
  cursor: default;
}
.fgoKRI {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  background: rgb(49, 68, 90);
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.fgoKRI:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.fgoKRI:disabled {
  opacity: 0.7;
  cursor: default;
}
* {
  -webkit-tap-highlight-color: transparent;
}

.bodyContainer {
  /* background-color: rgb(160, 205, 241);
  border-radius: 15px;
  margin: 10px;
  padding: 20px; */
}
.ant-modal {
  /* border-radius: 20px; */
  /* Only border-radius here */
}
/* .ant-modal-content {
  border-radius: 20px;
  background: rgba(197, 235, 235, 1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
} */

.modalStyle {
  /* border-radius: 20px; */
}

.newStyle {
  /* border-radius: 20px; */
}

/* applied style to its root properties, but no change */
.ant-modal-content {
  /* border-radius: 20px; */
}
.modalStyle .ant-modal-header {
  /* border-radius: 20px 20px 20px 20px; */
}
@tailwind base;
@tailwind components;
@tailwind utilities;
